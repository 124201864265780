<template>
  <div style="background-color: #13255d !important"  class="app-container">
    <ServicePlaceRegisterForm @Saved="SavedEvent"></ServicePlaceRegisterForm>
  </div>
</template>
<script>
import ServicePlaceRegisterForm from "@/components/ServicesPlaces/ServicePlaceRegisterForm";
export default {
  name: "ServicePlaceRegister",
  props:{
    add:false,
  },
  components: {ServicePlaceRegisterForm},
  mounted() {

  },
  methods:{
    SavedEvent(item){
      if(this.add) this.$router.push({name:"ServicePlaceEdit" , params:{id:item.id}});
    }
  }
}
</script>

<style scoped>

</style>