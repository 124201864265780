<template>
  <div class="container--fluid" >
    <v-container fluid >
      <v-btn
          class="primary float-left"
          v-if="$store.getters['authModule/HasPermission']('AddUser') && !addUserBtn.isActive"
          :disabled="addUserBtn.disabled"
          :loading="addUserBtn.loading"

          @click="AddUser"
      >
        {{$t('AddUser')}}
      </v-btn>
    </v-container >
    <v-container class="mt-8" fluid>
      <UsersGrid  :service-place-id="ServicePlaceId" v-if="!addUserBtn.isActive"></UsersGrid>
      <AddUserFrom :rank-string="'ServiceSupplier'"  @userSaved="userSaved" :service-place="{id:ServicePlaceId}"  v-else></AddUserFrom>
    </v-container>


  </div>
</template>

<script>
import UsersGrid from "@/components/Users/UsersGrid";
import AddUserFrom from "@/components/Users/AddUserFrom";
export default {
  name: "ServicePlaceUsersGrid",
  props:{
    ServicePlaceId:0
  },
  components:{
    UsersGrid,
    AddUserFrom
  },
  data(){
    return {
      addUserBtn :{
        loading : false ,
        disabled : false,
        isActive : false
      },
    }
  },methods:{
    userSaved(item){
      this.addUserBtn.isActive = false
    },
    AddUser(){
      this.addUserBtn.isActive = true;
    }
  }
}
</script>

<style scoped>

</style>