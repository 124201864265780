<template>
  <v-container fluid>
    <v-container v-if="loading" class="text-center">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-container>
    <v-row v-else>
      <PointsPackageDialog v-if="dialogStat" :countryId="countryId" :dialog="dialogStat" @edited="closeDialog" :item="this.selectedItem"></PointsPackageDialog>
      <PointPackage @Selected="SelectItem" v-for="points_package in GetAll" :key="'package_'+points_package.id" :pointsPackage="points_package" ></PointPackage>
    </v-row>
  </v-container>
</template>

<script>
import PointPackage from "@/components/PointsPackages/PointPackage";
import PointsPackageDialog from "./PointsPackageDialog.vue";
export default {
  name: "PointsPackagesList",
  components: { PointPackage, PointsPackageDialog },
  props:{
    dialog:Boolean,
    countryId:Number
  },
  data(){
    return {
      loading : false,
      dialogStat:false,
      selectedItem : 0
    }
  },
  computed:{
    GetAll(){
      return this.$store.getters['pointsPackageModule/GetAllPointsPackages'];
    },
  },
  mounted() {
    this.loading =true;
    this.$store.dispatch('pointsPackageModule/GetAllPointsPackages').then(res=>{
      this.loading = false;
    })
  },
  watch:{
    dialog(val){
      this.dialogStat = val
    }
  },
  methods:{
    closeDialog(){
      console.log('close')
      this.dialogStat=false,
      this.selectedItem = 0;
      this.$emit('CloseDialog');
    },
    SelectItem(item){
      this.dialogStat=true;
      this.selectedItem = item;
    }
  }
}
</script>

<style scoped>

</style>