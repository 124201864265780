import RankRolesService from "../Services/RankRolesService";
export const rankRolesModule  = {
    namespaced : true,
    state : {
        ranks : [],
        roles : [],
        rankRoles : []
    },
    actions : {
        GetRankRolesGrid({commit}){
            return RankRolesService.GetRankRolesGrid().then(res=>{
                commit("CommitRankRolesGrid" , res);
                return Promise.resolve("done");
            }).catch(err=>{
                return Promise.reject("fail");
            });


        },
        GetAllRanks({commit}){
            return RankRolesService.GetRanks().then(res=>{
                commit("CommitRanks" , res);
                return Promise.resolve("done");
            }).catch(err=>{
                return Promise.reject("fail");
            });
        },
        SaveRankRoles({commit} , RankRoles){
            return RankRolesService.SaveRankRoles(RankRoles).then(res=>{
                commit("SaveRankRoles" , RankRoles);
                return Promise.resolve("Done");
            });
        }
        ,GetAllRolesByUser({commit} , userId){
            return RankRolesService.GetAllRolesByUser(userId).then(res=>{
                commit("SetRoles" , res);
                return Promise.resolve("done");
            }).catch(err=>{
                return Promise.reject("fail");
            });
        },
        SaveUserRoles({commit} , payload){
            return RankRolesService.SaveUserRoles(payload).then(res=>{
                return Promise.resolve("Done");
            });
        },
        SetDefaultRanks({commit} , ranks){
            commit("CommitRanks" , ranks);
        }
    }
    ,mutations:{
        CommitRankRolesGrid(state , res){
            state.rankRoles = res.rankRoles;
            state.roles = res.roles;
        },
        CommitRanks(state , res){
            state.ranks = res;
        },
        SaveRankRoles(state, res){
            state.rankRoles = res;
        },
        SetRoles(state , res){
            state.roles = res;
        }
    },
    getters : {
        GetAllRanks:state=>{
            return state.ranks
        }
        , GetAllRoles : state =>{
            return state.roles
        },
        GetRankRoles: state=>{
            return state.rankRoles.map(el=>{
                return {rank : el.rank , role : el.role}
            })
        }
        ,IsRoleInRank :(state)=>( rankId , roleId)=>{
          console.log(state.rankRoles.some(s=>s.rank === rankId && s.role ===roleId));
            return state.rankRoles.some(s=>s.rank === rankId && s.role ===roleId);
        },
        GetRankIdByString:(state)=>(rank)=>{
            return state.ranks.filter(s=>{return rank === s.value})[0]?.id??null
        },
    }

}