<template>
  <div>
    <v-container v-if="loading" class="text-center">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-container>
    <v-row class="grid" v-if="!loading">
      <v-col
          cols="12"
          md="6"
      >
        <v-toolbar-title>{{$t("ServicePlaceServices")}}</v-toolbar-title>
      </v-col>
      <v-col
          cols="12" md="6"
      >
        <v-btn
            class="red float-left pa-2 ma-2"
            small

            :loading="btn.loading"
            :disabled="btn.disabled"
            @click="BackToServicePlaces"
        >
          <v-icon>

          </v-icon>
          {{$t("Back")}}
        </v-btn>
        <v-btn
            class="primary float-left pa-2 ma-2"
            small
            :loading="btn.loading"
            :disabled="btn.disabled"
            @click="SaveServicePlaceServices"
        >
          <v-icon>

          </v-icon>
          {{$t("Save")}}
        </v-btn>
      </v-col>
      <div class="text-center">
        <v-chip
            class="ma-4"
            color="indigo"
            outlined
            v-for="item in GetAllServicesPlaceServices" :key="'Service_'+item.id"
        >
          <v-checkbox
              color="primary"
              :value="item.id"
              v-model="Services"
              :label="item.name"
          ></v-checkbox>

        </v-chip>
      </div>

    </v-row>
  </div>
</template>

<script>
import AppCircularLoader from "@/components/General/AppCircularLoader";
export default {
  name: "ServicePlaceServicesList",
  components:{
    AppCircularLoader
  },
  props:{
    ServicePlaceId:0
  },
  data(){
    return {
      loading:false,
      btn : {
        loading :false,
        disabled:false,
      },
      Services:[]
    }
  },
  computed:{
    GetAllServicesPlaceServices(){
      return this.$store.getters['servicesPlacesModule/GetServicePlaceServices'];
    }
  },
  created() {
    this.initialize();
  },
  methods:{
    initialize(){
      this.loading = true;
      this.$store.dispatch("servicesPlacesModule/GetServicePlaceServices" , this.ServicePlaceId).then(res=>{
        this.Services = res.filter(s=>{return s.inServicePlace === true }).map(s=>{return s.id});
        if(this.Services.length>0){
          this.$emit('OnServicesResult' , true);
        }
        this.loading = false;
      });
    },
    SaveServicePlaceServices(){

    },
    BackToServicePlaces(){
      this.$router.back();
    }
  }
}
</script>

<style scoped>

</style>