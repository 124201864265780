<template>
  <v-autocomplete
      :items="GetAll"
      :label="title"
      @change="ChangeDropDown"
      :outlined="!disabled"
      :prepend-icon="icon"
      dense
      :disabled="disabled"
      :error-messages="errorMessages"
      :loading="loading"
      v-model="selected"
      :rules="validate===true?[() => selected.id !== 0|| $t('errors.requiredField')]:[]"
  >

  </v-autocomplete>
</template>

<script>
export default {
  name: "AppDropDown" ,
  props: {
    action : "",
    getter : "",
    dataId : 0,
    title : "",
    item : { id : 0},
    filter:"",
    validate:false,
    disabled:false,
    icon:""
  },
  data(){
    return {
      selected : { id :0},
      errorMessages:"",
      loading:false
    }
  },
  computed:{
    GetAll(){
      return this.$store.getters[this.getter]?.map((s , index)=>{
        return {
          text:s.name,
          value: {
            id : s.id
          }
        }
      });
    }
  },
  mounted() {
    if(this.item){
      this.selected = this.item;
    }
    this.initialize();
  },
  watch:{
    dataId(val){
      console.log(val);
      this.initialize()
    },item(val){
      debugger;
      if(val)
        this.selected = this.item;
    }
    ,filter(val){
      this.filterDropDown();
    }
  },
  methods:{
    ChangeDropDown(item){
      console.log(item);
      this.$emit('Selected' , item);
    },
    initialize(){
      if(this.action !== ""){
        this.loading = true;
        let method = this.id !== 0 ?this.$store.dispatch(this.action ,this.dataId ) : this.$store.dispatch(this.action);
        method.then(res=>{
          this.loading = false;
          this.filterDropDown();
        });

      }
    },
    filterDropDown(){
      if(this.filter !== ""){
        let it  = this.GetAll.filter(s=>{return s.text.includes(this.filter)})[0];
        if(it !== null && it!==undefined){
          this.selected = {id:it.value.id};
          this.ChangeDropDown(this.selected);
        }
      }
    }
  }
}
</script>

<style scoped>

</style>