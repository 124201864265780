<template>

  <div class="container--fluid ma-2 pa-2">
    <v-card flat ref="form">
      <v-card-title v-if="!item">

        {{$t('AddUser') + ' : ' }}
        <v-chip>
          <div class="text-h6">{{ rank !== null && rank!==undefined ? rank.name : rankString}}</div>


        </v-chip>
      </v-card-title>
      <v-card-title v-else>

        {{$t('Edit') + ' : ' }}
        <v-chip>
          <div class="text-h6">{{ form.UserName}}</div>

        </v-chip>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
              cols="12"


              md="6"
          >
            <v-text-field
                ref="name"
                v-model="form.UserName"
                :rules="[() => !!form.UserName || $t('errors.requiredField')]"
                :error-messages="errorMessages"
                :label="$t('UserName')"
                outlined
                autocomplete="name"
                placeholder=""
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"


              md="6"
          >
            <v-text-field
                ref="email"
                v-model="form.Email"
                :rules="[() => !!form.Email || $t('errors.requiredField')]"
                :error-messages="errorMessages"
                :label="$t('Email')"
                autocomplete="username"
                outlined
                placeholder=""
                required
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="8"
          >
            <PhoneInput :phone="form.Phone"
                        @Changed="ChangePhone"
                        :item="item ? {code : item.country?item.country.phoneCode:form.PhoneCode, img : item.country.imagePath , id : item.country.id  } : {code:form.PhoneCode , id:0, img : ''}"
            >
            </PhoneInput>

          </v-col>

          <v-col
              cols="12"
              md="4"
          >
            <v-text-field
                ref="password"
                v-if="!item||changePassword"
                v-model="form.Password"
                autocomplete="name"
                :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.min , rules.required , rules.symbol , rules.upper , rules.lower ,rules.number]"
                :error-messages="errorMessages"
                :label="$t('Password')"
                value=""
                outlined
                :type="showPassword ? 'text' : 'password'"
                placeholder=""
                @click:append="showPassword = !showPassword"
                required
            ></v-text-field>
            <v-checkbox v-model="changePassword" label="changePassword" v-else>

            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="mt-12"></v-divider>
      <v-card-actions>
        <v-btn @click="()=>{$emit('userSaved')}" text>
          {{$t('Cancel')}}
        </v-btn>
        <v-spacer></v-spacer>
        <v-slide-x-reverse-transition>
          <v-tooltip
              v-if="formHasErrors"
              left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  class="my-0"
                  v-bind="attrs"
                  @click="resetForm"
                  v-on="on"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh form</span>
          </v-tooltip>
        </v-slide-x-reverse-transition>
        <v-btn
            color="primary"
            :loading="btn.loading"
            :disabled="btn.disabled"
            @click="SubmitUser"
        >
          {{$t('Save')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>


</template>

<script>
import {rules as Rules} from "@/Helpers/ValidationRules";
import PhoneInput from "@/components/General/PhoneInput";

export default {
  name: "AddUserFrom",
  components: {PhoneInput},
  props:{
    rank : Object ,
    rankString:"",
    ServicePlaceId: null,
    BranchId: null,
    item : Object
  },
  data (){
    return{
      changePassword: false,
      showPassword : false,
      form:{
        Id : 0,
        Email : '',
        Password : '',
        UserName : '' ,
        PhoneCode : '',
        Phone : '',
        Rank : this.rank? this.rank.id : null,
        RankString:this.rankString !== "" && this.rankString && this.rankString!==""? this.rankString : "",
        SerivesPlaceId : this.ServicePlaceId ? this.ServicePlaceId:null,
        BranchId : this.BranchId ? this.BranchId:null
      },
      btn : {
        loading : false,
        disabled: false
      },
      ConfirmPassword:'',
      errorMessages : '',
      formHasErrors: false,
      rules: Rules
    }
  }
  ,mounted() {
    if(this.item){
      this.form.UserName = this.item.name
      this.form.Email = this.item.email
      this.form.Phone = this.item.phone
      this.form.Id = this.item.id;
    }

  }
  ,watch:{
    ServicePlaceId(val){
      this.form.SerivesPlaceId = val ?  val : null;
    },
    rank(val){
      this.form.Rank = val.id;
    }
  },
  methods:{
    SubmitUser(){
      this.formHasErrors = false
      this.btn.loading = true
      if(this.rank!==undefined &&  this.rank !== null)
        this.form.Rank = this.rank.id
      this.$store.dispatch('usersModule/SaveUser' , this.form).then(res=>{
        this.btn.loading = false;
        this.btn.disabled = true;
        console.log(res);
        this.$emit('userSaved' , res);
        this.$toastr.s(this.$i18n.t("SuccessSaveMessage"));
      });
    },
    resetForm () {
      this.errorMessages = []
      this.formHasErrors = false

      Object.keys(this.form).forEach(f => {
        this.$refs[f].reset()
      })
    },
    ChangePhone(item){
      this.form.Phone = item.phone;
      this.form.PhoneCode= item.phoneCode
    }
  }
}
</script>

<style scoped>

</style>