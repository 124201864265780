<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">

        <v-avatar
          size="40px"
          v-bind="attrs"
          v-on="on"
          class="avatar-btn"
        >
          <v-img :src="require('../assets/Guest.png')"></v-img>
        </v-avatar>

    </template>
    <v-list>

      <!-- Profile -->
      <v-list-item link @click="CallClientAreaDialog('login')">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('Login')}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Email -->


      <!-- Chat -->


      <v-divider class="my-2"></v-divider>

      <!-- Settings -->
      <v-list-item link @click="CallClientAreaDialog('register')">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('Register')}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Pricing -->


      <!-- FAQ -->


      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <LangChanger/>


      <!-- Logout -->

    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import LangChanger from "@/Layout/LangChanger";
import AppHelper from "@/Helpers/AppHelper";

export default {
  components: {LangChanger},
  setup() {

    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
  methods:{
    Logout(){
      console.log(localStorage.getItem('token'))
      this.$store.dispatch('authModule/logout').then(()=>{
        this.$router.push({name:'SignIn'});
      })
    },
    CallClientAreaDialog(type){
      this.$emit('OnClientArea' , type);
    },
    LangChanger(){
      AppHelper.ChangeLanguage();
    }
  },
  computed:{
    GetLoginUser(){
      return this.$store.getters['authModule/GetLoginUser'];
    },
    GetFirstRole(){
      return this.$store.getters['authModule/GetFirstRole'];
    }
  }
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
