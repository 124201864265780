export const eg_cities = [
    {"number":"1","governmentNumber":"1","NameAr":"15 مايو","NameEn":"15 May"},
    {"number":"2","governmentNumber":"1","NameAr":"الازبكية","NameEn":"Al Azbakeyah"},
    {"number":"3","governmentNumber":"1","NameAr":"البساتين","NameEn":"Al Basatin"},
    {"number":"4","governmentNumber":"1","NameAr":"التبين","NameEn":"Tebin"},
    {"number":"5","governmentNumber":"1","NameAr":"الخليفة","NameEn":"El-Khalifa"},
    {"number":"6","governmentNumber":"1","NameAr":"الدراسة","NameEn":"El darrasa"},
    {"number":"7","governmentNumber":"1","NameAr":"الدرب الاحمر","NameEn":"Aldarb Alahmar"},
    {"number":"8","governmentNumber":"1","NameAr":"الزاوية الحمراء","NameEn":"Zawya al-Hamra"},
    {"number":"9","governmentNumber":"1","NameAr":"الزيتون","NameEn":"El-Zaytoun"},
    {"number":"10","governmentNumber":"1","NameAr":"الساحل","NameEn":"Sahel"},
    {"number":"11","governmentNumber":"1","NameAr":"السلام","NameEn":"El Salam"},
    {"number":"12","governmentNumber":"1","NameAr":"السيدة زينب","NameEn":"Sayeda Zeinab"},
    {"number":"13","governmentNumber":"1","NameAr":"الشرابية","NameEn":"El Sharabeya"},
    {"number":"14","governmentNumber":"1","NameAr":"مدينة الشروق","NameEn":"Shorouk"},
    {"number":"15","governmentNumber":"1","NameAr":"الظاهر","NameEn":"El Daher"},
    {"number":"16","governmentNumber":"1","NameAr":"العتبة","NameEn":"Ataba"},
    {"number":"17","governmentNumber":"1","NameAr":"القاهرة الجديدة","NameEn":"New Cairo"},
    {"number":"18","governmentNumber":"1","NameAr":"المرج","NameEn":"El Marg"},
    {"number":"19","governmentNumber":"1","NameAr":"عزبة النخل","NameEn":"Ezbet el Nakhl"},
    {"number":"20","governmentNumber":"1","NameAr":"المطرية","NameEn":"Matareya"},
    {"number":"21","governmentNumber":"1","NameAr":"المعادى","NameEn":"Maadi"},
    {"number":"22","governmentNumber":"1","NameAr":"المعصرة","NameEn":"Maasara"},
    {"number":"23","governmentNumber":"1","NameAr":"المقطم","NameEn":"Mokattam"},
    {"number":"24","governmentNumber":"1","NameAr":"المنيل","NameEn":"Manyal"},
    {"number":"25","governmentNumber":"1","NameAr":"الموسكى","NameEn":"Mosky"},
    {"number":"26","governmentNumber":"1","NameAr":"النزهة","NameEn":"Nozha"},
    {"number":"27","governmentNumber":"1","NameAr":"الوايلى","NameEn":"Waily"},
    {"number":"28","governmentNumber":"1","NameAr":"باب الشعرية","NameEn":"Bab al-Shereia"},
    {"number":"29","governmentNumber":"1","NameAr":"بولاق","NameEn":"Bolaq"},
    {"number":"30","governmentNumber":"1","NameAr":"جاردن سيتى","NameEn":"Garden City"},
    {"number":"31","governmentNumber":"1","NameAr":"حدائق القبة","NameEn":"Hadayek El-Kobba"},
    {"number":"32","governmentNumber":"1","NameAr":"حلوان","NameEn":"Helwan"},
    {"number":"33","governmentNumber":"1","NameAr":"دار السلام","NameEn":"Dar Al Salam"},
    {"number":"34","governmentNumber":"1","NameAr":"شبرا","NameEn":"Shubra"},
    {"number":"35","governmentNumber":"1","NameAr":"طره","NameEn":"Tura"},
    {"number":"36","governmentNumber":"1","NameAr":"عابدين","NameEn":"Abdeen"},
    {"number":"37","governmentNumber":"1","NameAr":"عباسية","NameEn":"Abaseya"},
    {"number":"38","governmentNumber":"1","NameAr":"عين شمس","NameEn":"Ain Shams"},
    {"number":"39","governmentNumber":"1","NameAr":"مدينة نصر","NameEn":"Nasr City"},
    {"number":"40","governmentNumber":"1","NameAr":"مصر الجديدة","NameEn":"New Heliopolis"},
    {"number":"41","governmentNumber":"1","NameAr":"مصر القديمة","NameEn":"Masr Al Qadima"},
    {"number":"42","governmentNumber":"1","NameAr":"منشية ناصر","NameEn":"Mansheya Nasir"},
    {"number":"43","governmentNumber":"1","NameAr":"مدينة بدر","NameEn":"Badr City"},
    {"number":"44","governmentNumber":"1","NameAr":"مدينة العبور","NameEn":"Obour City"},
    {"number":"45","governmentNumber":"1","NameAr":"وسط البلد","NameEn":"Cairo Downtown"},
    {"number":"46","governmentNumber":"1","NameAr":"الزمالك","NameEn":"Zamalek"},
    {"number":"47","governmentNumber":"1","NameAr":"قصر النيل","NameEn":"Kasr El Nile"},
    {"number":"48","governmentNumber":"1","NameAr":"الرحاب","NameEn":"Rehab"},
    {"number":"49","governmentNumber":"1","NameAr":"القطامية","NameEn":"Katameya"},
    {"number":"50","governmentNumber":"1","NameAr":"مدينتي","NameEn":"Madinty"},
    {"number":"51","governmentNumber":"1","NameAr":"روض الفرج","NameEn":"Rod Alfarag"},
    {"number":"52","governmentNumber":"1","NameAr":"شيراتون","NameEn":"Sheraton"},
    {"number":"53","governmentNumber":"1","NameAr":"الجمالية","NameEn":"El-Gamaleya"},
    {"number":"54","governmentNumber":"1","NameAr":"العاشر من رمضان","NameEn":"10th of Ramadan City"},
    {"number":"55","governmentNumber":"1","NameAr":"الحلمية","NameEn":"Helmeyat Alzaytoun"},
    {"number":"56","governmentNumber":"1","NameAr":"النزهة الجديدة","NameEn":"New Nozha"},
    {"number":"57","governmentNumber":"1","NameAr":"العاصمة الإدارية","NameEn":"Capital New"},
    {"number":"58","governmentNumber":"2","NameAr":"الجيزة","NameEn":"Giza"},
    {"number":"59","governmentNumber":"2","NameAr":"السادس من أكتوبر","NameEn":"Sixth of October"},
    {"number":"60","governmentNumber":"2","NameAr":"الشيخ زايد","NameEn":"Cheikh Zayed"},
    {"number":"61","governmentNumber":"2","NameAr":"الحوامدية","NameEn":"Hawamdiyah"},
    {"number":"62","governmentNumber":"2","NameAr":"البدرشين","NameEn":"Al Badrasheen"},
    {"number":"63","governmentNumber":"2","NameAr":"الصف","NameEn":"Saf"},
    {"number":"64","governmentNumber":"2","NameAr":"أطفيح","NameEn":"Atfih"},
    {"number":"65","governmentNumber":"2","NameAr":"العياط","NameEn":"Al Ayat"},
    {"number":"66","governmentNumber":"2","NameAr":"الباويطي","NameEn":"Al-Bawaiti"},
    {"number":"67","governmentNumber":"2","NameAr":"منشأة القناطر","NameEn":"ManshiyetAl Qanater"},
    {"number":"68","governmentNumber":"2","NameAr":"أوسيم","NameEn":"Oaseem"},
    {"number":"69","governmentNumber":"2","NameAr":"كرداسة","NameEn":"Kerdasa"},
    {"number":"70","governmentNumber":"2","NameAr":"أبو النمرس","NameEn":"Abu Nomros"},
    {"number":"71","governmentNumber":"2","NameAr":"كفر غطاطي","NameEn":"Kafr Ghati"},
    {"number":"72","governmentNumber":"2","NameAr":"منشأة البكاري","NameEn":"Manshiyet Al Bakari"},
    {"number":"73","governmentNumber":"2","NameAr":"الدقى","NameEn":"Dokki"},
    {"number":"74","governmentNumber":"2","NameAr":"العجوزة","NameEn":"Agouza"},
    {"number":"75","governmentNumber":"2","NameAr":"الهرم","NameEn":"Haram"},
    {"number":"76","governmentNumber":"2","NameAr":"الوراق","NameEn":"Warraq"},
    {"number":"77","governmentNumber":"2","NameAr":"امبابة","NameEn":"Imbaba"},
    {"number":"78","governmentNumber":"2","NameAr":"بولاق الدكرور","NameEn":"Boulaq Dakrour"},
    {"number":"79","governmentNumber":"2","NameAr":"الواحات البحرية","NameEn":"Al Wahat Al Baharia"},
    {"number":"80","governmentNumber":"2","NameAr":"العمرانية","NameEn":"Omraneya"},
    {"number":"81","governmentNumber":"2","NameAr":"المنيب","NameEn":"Moneeb"},
    {"number":"82","governmentNumber":"2","NameAr":"بين السرايات","NameEn":"Bin Alsarayat"},
    {"number":"83","governmentNumber":"2","NameAr":"الكيت كات","NameEn":"Kit Kat"},
    {"number":"84","governmentNumber":"2","NameAr":"المهندسين","NameEn":"Mohandessin"},
    {"number":"85","governmentNumber":"2","NameAr":"فيصل","NameEn":"Faisal"},
    {"number":"86","governmentNumber":"2","NameAr":"أبو رواش","NameEn":"Abu Rawash"},
    {"number":"87","governmentNumber":"2","NameAr":"حدائق الأهرام","NameEn":"Hadayek Alahram"},
    {"number":"88","governmentNumber":"2","NameAr":"الحرانية","NameEn":"Haraneya"},
    {"number":"89","governmentNumber":"2","NameAr":"حدائق اكتوبر","NameEn":"Hadayek October"},
    {"number":"90","governmentNumber":"2","NameAr":"صفط اللبن","NameEn":"Saft Allaban"},
    {"number":"91","governmentNumber":"2","NameAr":"القرية الذكية","NameEn":"Smart Village"},
    {"number":"92","governmentNumber":"2","NameAr":"ارض اللواء","NameEn":"Ard Ellwaa"},
    {"number":"93","governmentNumber":"3","NameAr":"ابو قير","NameEn":"Abu Qir"},
    {"number":"94","governmentNumber":"3","NameAr":"الابراهيمية","NameEn":"Al Ibrahimeyah"},
    {"number":"95","governmentNumber":"3","NameAr":"الأزاريطة","NameEn":"Azarita"},
    {"number":"96","governmentNumber":"3","NameAr":"الانفوشى","NameEn":"Anfoushi"},
    {"number":"97","governmentNumber":"3","NameAr":"الدخيلة","NameEn":"Dekheila"},
    {"number":"98","governmentNumber":"3","NameAr":"السيوف","NameEn":"El Soyof"},
    {"number":"99","governmentNumber":"3","NameAr":"العامرية","NameEn":"Ameria"},
    {"number":"100","governmentNumber":"3","NameAr":"اللبان","NameEn":"El Labban"},
    {"number":"101","governmentNumber":"3","NameAr":"المفروزة","NameEn":"Al Mafrouza"},
    {"number":"102","governmentNumber":"3","NameAr":"المنتزه","NameEn":"El Montaza"},
    {"number":"103","governmentNumber":"3","NameAr":"المنشية","NameEn":"Mansheya"},
    {"number":"104","governmentNumber":"3","NameAr":"الناصرية","NameEn":"Naseria"},
    {"number":"105","governmentNumber":"3","NameAr":"امبروزو","NameEn":"Ambrozo"},
    {"number":"106","governmentNumber":"3","NameAr":"باب شرق","NameEn":"Bab Sharq"},
    {"number":"107","governmentNumber":"3","NameAr":"برج العرب","NameEn":"Bourj Alarab"},
    {"number":"108","governmentNumber":"3","NameAr":"ستانلى","NameEn":"Stanley"},
    {"number":"109","governmentNumber":"3","NameAr":"سموحة","NameEn":"Smouha"},
    {"number":"110","governmentNumber":"3","NameAr":"سيدى بشر","NameEn":"Sidi Bishr"},
    {"number":"111","governmentNumber":"3","NameAr":"شدس","NameEn":"Shads"},
    {"number":"112","governmentNumber":"3","NameAr":"غيط العنب","NameEn":"Gheet Alenab"},
    {"number":"113","governmentNumber":"3","NameAr":"فلمينج","NameEn":"Fleming"},
    {"number":"114","governmentNumber":"3","NameAr":"فيكتوريا","NameEn":"Victoria"},
    {"number":"115","governmentNumber":"3","NameAr":"كامب شيزار","NameEn":"Camp Shizar"},
    {"number":"116","governmentNumber":"3","NameAr":"كرموز","NameEn":"Karmooz"},
    {"number":"117","governmentNumber":"3","NameAr":"محطة الرمل","NameEn":"Mahta Alraml"},
    {"number":"118","governmentNumber":"3","NameAr":"مينا البصل","NameEn":"Mina El-Basal"},
    {"number":"119","governmentNumber":"3","NameAr":"العصافرة","NameEn":"Asafra"},
    {"number":"120","governmentNumber":"3","NameAr":"العجمي","NameEn":"Agamy"},
    {"number":"121","governmentNumber":"3","NameAr":"بكوس","NameEn":"Bakos"},
    {"number":"122","governmentNumber":"3","NameAr":"بولكلي","NameEn":"Boulkly"},
    {"number":"123","governmentNumber":"3","NameAr":"كليوباترا","NameEn":"Cleopatra"},
    {"number":"124","governmentNumber":"3","NameAr":"جليم","NameEn":"Glim"},
    {"number":"125","governmentNumber":"3","NameAr":"المعمورة","NameEn":"Al Mamurah"},
    {"number":"126","governmentNumber":"3","NameAr":"المندرة","NameEn":"Al Mandara"},
    {"number":"127","governmentNumber":"3","NameAr":"محرم بك","NameEn":"Moharam Bek"},
    {"number":"128","governmentNumber":"3","NameAr":"الشاطبي","NameEn":"Elshatby"},
    {"number":"129","governmentNumber":"3","NameAr":"سيدي جابر","NameEn":"Sidi Gaber"},
    {"number":"130","governmentNumber":"3","NameAr":"الساحل الشمالي","NameEn":"North Coast\/sahel"},
    {"number":"131","governmentNumber":"3","NameAr":"الحضرة","NameEn":"Alhadra"},
    {"number":"132","governmentNumber":"3","NameAr":"العطارين","NameEn":"Alattarin"},
    {"number":"133","governmentNumber":"3","NameAr":"سيدي كرير","NameEn":"Sidi Kerir"},
    {"number":"134","governmentNumber":"3","NameAr":"الجمرك","NameEn":"Elgomrok"},
    {"number":"135","governmentNumber":"3","NameAr":"المكس","NameEn":"Al Max"},
    {"number":"136","governmentNumber":"3","NameAr":"مارينا","NameEn":"Marina"},
    {"number":"137","governmentNumber":"4","NameAr":"المنصورة","NameEn":"Mansoura"},
    {"number":"138","governmentNumber":"4","NameAr":"طلخا","NameEn":"Talkha"},
    {"number":"139","governmentNumber":"4","NameAr":"ميت غمر","NameEn":"Mitt Ghamr"},
    {"number":"140","governmentNumber":"4","NameAr":"دكرنس","NameEn":"Dekernes"},
    {"number":"141","governmentNumber":"4","NameAr":"أجا","NameEn":"Aga"},
    {"number":"142","governmentNumber":"4","NameAr":"منية النصر","NameEn":"Menia El Nasr"},
    {"number":"143","governmentNumber":"4","NameAr":"السنبلاوين","NameEn":"Sinbillawin"},
    {"number":"144","governmentNumber":"4","NameAr":"الكردي","NameEn":"El Kurdi"},
    {"number":"145","governmentNumber":"4","NameAr":"بني عبيد","NameEn":"Bani Ubaid"},
    {"number":"146","governmentNumber":"4","NameAr":"المنزلة","NameEn":"Al Manzala"},
    {"number":"147","governmentNumber":"4","NameAr":"تمي الأمديد","NameEn":"tami al'amdid"},
    {"number":"148","governmentNumber":"4","NameAr":"الجمالية","NameEn":"aljamalia"},
    {"number":"149","governmentNumber":"4","NameAr":"شربين","NameEn":"Sherbin"},
    {"number":"150","governmentNumber":"4","NameAr":"المطرية","NameEn":"Mataria"},
    {"number":"151","governmentNumber":"4","NameAr":"بلقاس","NameEn":"Belqas"},
    {"number":"152","governmentNumber":"4","NameAr":"ميت سلسيل","NameEn":"Meet Salsil"},
    {"number":"153","governmentNumber":"4","NameAr":"جمصة","NameEn":"Gamasa"},
    {"number":"154","governmentNumber":"4","NameAr":"محلة دمنة","NameEn":"Mahalat Damana"},
    {"number":"155","governmentNumber":"4","NameAr":"نبروه","NameEn":"Nabroh"},
    {"number":"156","governmentNumber":"5","NameAr":"الغردقة","NameEn":"Hurghada"},
    {"number":"157","governmentNumber":"5","NameAr":"رأس غارب","NameEn":"Ras Ghareb"},
    {"number":"158","governmentNumber":"5","NameAr":"سفاجا","NameEn":"Safaga"},
    {"number":"159","governmentNumber":"5","NameAr":"القصير","NameEn":"El Qusiar"},
    {"number":"160","governmentNumber":"5","NameAr":"مرسى علم","NameEn":"Marsa Alam"},
    {"number":"161","governmentNumber":"5","NameAr":"الشلاتين","NameEn":"Shalatin"},
    {"number":"162","governmentNumber":"5","NameAr":"حلايب","NameEn":"Halaib"},
    {"number":"163","governmentNumber":"5","NameAr":"الدهار","NameEn":"Aldahar"},
    {"number":"164","governmentNumber":"6","NameAr":"دمنهور","NameEn":"Damanhour"},
    {"number":"165","governmentNumber":"6","NameAr":"كفر الدوار","NameEn":"Kafr El Dawar"},
    {"number":"166","governmentNumber":"6","NameAr":"رشيد","NameEn":"Rashid"},
    {"number":"167","governmentNumber":"6","NameAr":"إدكو","NameEn":"Edco"},
    {"number":"168","governmentNumber":"6","NameAr":"أبو المطامير","NameEn":"Abu al-Matamir"},
    {"number":"169","governmentNumber":"6","NameAr":"أبو حمص","NameEn":"Abu Homs"},
    {"number":"170","governmentNumber":"6","NameAr":"الدلنجات","NameEn":"Delengat"},
    {"number":"171","governmentNumber":"6","NameAr":"المحمودية","NameEn":"Mahmoudiyah"},
    {"number":"172","governmentNumber":"6","NameAr":"الرحمانية","NameEn":"Rahmaniyah"},
    {"number":"173","governmentNumber":"6","NameAr":"إيتاي البارود","NameEn":"Itai Baroud"},
    {"number":"174","governmentNumber":"6","NameAr":"حوش عيسى","NameEn":"Housh Eissa"},
    {"number":"175","governmentNumber":"6","NameAr":"شبراخيت","NameEn":"Shubrakhit"},
    {"number":"176","governmentNumber":"6","NameAr":"كوم حمادة","NameEn":"Kom Hamada"},
    {"number":"177","governmentNumber":"6","NameAr":"بدر","NameEn":"Badr"},
    {"number":"178","governmentNumber":"6","NameAr":"وادي النطرون","NameEn":"Wadi Natrun"},
    {"number":"179","governmentNumber":"6","NameAr":"النوبارية الجديدة","NameEn":"New Nubaria"},
    {"number":"180","governmentNumber":"6","NameAr":"النوبارية","NameEn":"Alnoubareya"},
    {"number":"181","governmentNumber":"7","NameAr":"الفيوم","NameEn":"Fayoum"},
    {"number":"182","governmentNumber":"7","NameAr":"الفيوم الجديدة","NameEn":"Fayoum El Gedida"},
    {"number":"183","governmentNumber":"7","NameAr":"طامية","NameEn":"Tamiya"},
    {"number":"184","governmentNumber":"7","NameAr":"سنورس","NameEn":"Snores"},
    {"number":"185","governmentNumber":"7","NameAr":"إطسا","NameEn":"Etsa"},
    {"number":"186","governmentNumber":"7","NameAr":"إبشواي","NameEn":"Epschway"},
    {"number":"187","governmentNumber":"7","NameAr":"يوسف الصديق","NameEn":"Yusuf El Sediaq"},
    {"number":"188","governmentNumber":"7","NameAr":"الحادقة","NameEn":"Hadqa"},
    {"number":"189","governmentNumber":"7","NameAr":"اطسا","NameEn":"Atsa"},
    {"number":"190","governmentNumber":"7","NameAr":"الجامعة","NameEn":"Algamaa"},
    {"number":"191","governmentNumber":"7","NameAr":"السيالة","NameEn":"Sayala"},
    {"number":"192","governmentNumber":"8","NameAr":"طنطا","NameEn":"Tanta"},
    {"number":"193","governmentNumber":"8","NameAr":"المحلة الكبرى","NameEn":"Al Mahalla Al Kobra"},
    {"number":"194","governmentNumber":"8","NameAr":"كفر الزيات","NameEn":"Kafr El Zayat"},
    {"number":"195","governmentNumber":"8","NameAr":"زفتى","NameEn":"Zefta"},
    {"number":"196","governmentNumber":"8","NameAr":"السنطة","NameEn":"El Santa"},
    {"number":"197","governmentNumber":"8","NameAr":"قطور","NameEn":"Qutour"},
    {"number":"198","governmentNumber":"8","NameAr":"بسيون","NameEn":"Basion"},
    {"number":"199","governmentNumber":"8","NameAr":"سمنود","NameEn":"Samannoud"},
    {"number":"200","governmentNumber":"9","NameAr":"الإسماعيلية","NameEn":"Ismailia"},
    {"number":"201","governmentNumber":"9","NameAr":"فايد","NameEn":"Fayed"},
    {"number":"202","governmentNumber":"9","NameAr":"القنطرة شرق","NameEn":"Qantara Sharq"},
    {"number":"203","governmentNumber":"9","NameAr":"القنطرة غرب","NameEn":"Qantara Gharb"},
    {"number":"204","governmentNumber":"9","NameAr":"التل الكبير","NameEn":"El Tal El Kabier"},
    {"number":"205","governmentNumber":"9","NameAr":"أبو صوير","NameEn":"Abu Sawir"},
    {"number":"206","governmentNumber":"9","NameAr":"القصاصين الجديدة","NameEn":"Kasasien El Gedida"},
    {"number":"207","governmentNumber":"9","NameAr":"نفيشة","NameEn":"Nefesha"},
    {"number":"208","governmentNumber":"9","NameAr":"الشيخ زايد","NameEn":"Sheikh Zayed"},
    {"number":"209","governmentNumber":"10","NameAr":"شبين الكوم","NameEn":"Shbeen El Koom"},
    {"number":"210","governmentNumber":"10","NameAr":"مدينة السادات","NameEn":"Sadat City"},
    {"number":"211","governmentNumber":"10","NameAr":"منوف","NameEn":"Menouf"},
    {"number":"212","governmentNumber":"10","NameAr":"سرس الليان","NameEn":"Sars El-Layan"},
    {"number":"213","governmentNumber":"10","NameAr":"أشمون","NameEn":"Ashmon"},
    {"number":"214","governmentNumber":"10","NameAr":"الباجور","NameEn":"Al Bagor"},
    {"number":"215","governmentNumber":"10","NameAr":"قويسنا","NameEn":"Quesna"},
    {"number":"216","governmentNumber":"10","NameAr":"بركة السبع","NameEn":"Berkat El Saba"},
    {"number":"217","governmentNumber":"10","NameAr":"تلا","NameEn":"Tala"},
    {"number":"218","governmentNumber":"10","NameAr":"الشهداء","NameEn":"Al Shohada"},
    {"number":"219","governmentNumber":"11","NameAr":"المنيا","NameEn":"Minya"},
    {"number":"220","governmentNumber":"11","NameAr":"المنيا الجديدة","NameEn":"Minya El Gedida"},
    {"number":"221","governmentNumber":"11","NameAr":"العدوة","NameEn":"El Adwa"},
    {"number":"222","governmentNumber":"11","NameAr":"مغاغة","NameEn":"Magagha"},
    {"number":"223","governmentNumber":"11","NameAr":"بني مزار","NameEn":"Bani Mazar"},
    {"number":"224","governmentNumber":"11","NameAr":"مطاي","NameEn":"Mattay"},
    {"number":"225","governmentNumber":"11","NameAr":"سمالوط","NameEn":"Samalut"},
    {"number":"226","governmentNumber":"11","NameAr":"المدينة الفكرية","NameEn":"Madinat El Fekria"},
    {"number":"227","governmentNumber":"11","NameAr":"ملوي","NameEn":"Meloy"},
    {"number":"228","governmentNumber":"11","NameAr":"دير مواس","NameEn":"Deir Mawas"},
    {"number":"229","governmentNumber":"11","NameAr":"ابو قرقاص","NameEn":"Abu Qurqas"},
    {"number":"230","governmentNumber":"11","NameAr":"ارض سلطان","NameEn":"Ard Sultan"},
    {"number":"231","governmentNumber":"12","NameAr":"بنها","NameEn":"Banha"},
    {"number":"232","governmentNumber":"12","NameAr":"قليوب","NameEn":"Qalyub"},
    {"number":"233","governmentNumber":"12","NameAr":"شبرا الخيمة","NameEn":"Shubra Al Khaimah"},
    {"number":"234","governmentNumber":"12","NameAr":"القناطر الخيرية","NameEn":"Al Qanater Charity"},
    {"number":"235","governmentNumber":"12","NameAr":"الخانكة","NameEn":"Khanka"},
    {"number":"236","governmentNumber":"12","NameAr":"كفر شكر","NameEn":"Kafr Shukr"},
    {"number":"237","governmentNumber":"12","NameAr":"طوخ","NameEn":"Tukh"},
    {"number":"238","governmentNumber":"12","NameAr":"قها","NameEn":"Qaha"},
    {"number":"239","governmentNumber":"12","NameAr":"العبور","NameEn":"Obour"},
    {"number":"240","governmentNumber":"12","NameAr":"الخصوص","NameEn":"Khosous"},
    {"number":"241","governmentNumber":"12","NameAr":"شبين القناطر","NameEn":"Shibin Al Qanater"},
    {"number":"242","governmentNumber":"12","NameAr":"مسطرد","NameEn":"Mostorod"},
    {"number":"243","governmentNumber":"13","NameAr":"الخارجة","NameEn":"El Kharga"},
    {"number":"244","governmentNumber":"13","NameAr":"باريس","NameEn":"Paris"},
    {"number":"245","governmentNumber":"13","NameAr":"موط","NameEn":"Mout"},
    {"number":"246","governmentNumber":"13","NameAr":"الفرافرة","NameEn":"Farafra"},
    {"number":"247","governmentNumber":"13","NameAr":"بلاط","NameEn":"Balat"},
    {"number":"248","governmentNumber":"13","NameAr":"الداخلة","NameEn":"Dakhla"},
    {"number":"249","governmentNumber":"14","NameAr":"السويس","NameEn":"Suez"},
    {"number":"250","governmentNumber":"14","NameAr":"الجناين","NameEn":"Alganayen"},
    {"number":"251","governmentNumber":"14","NameAr":"عتاقة","NameEn":"Ataqah"},
    {"number":"252","governmentNumber":"14","NameAr":"العين السخنة","NameEn":"Ain Sokhna"},
    {"number":"253","governmentNumber":"14","NameAr":"فيصل","NameEn":"Faysal"},
    {"number":"254","governmentNumber":"15","NameAr":"أسوان","NameEn":"Aswan"},
    {"number":"255","governmentNumber":"15","NameAr":"أسوان الجديدة","NameEn":"Aswan El Gedida"},
    {"number":"256","governmentNumber":"15","NameAr":"دراو","NameEn":"Drau"},
    {"number":"257","governmentNumber":"15","NameAr":"كوم أمبو","NameEn":"Kom Ombo"},
    {"number":"258","governmentNumber":"15","NameAr":"نصر النوبة","NameEn":"Nasr Al Nuba"},
    {"number":"259","governmentNumber":"15","NameAr":"كلابشة","NameEn":"Kalabsha"},
    {"number":"260","governmentNumber":"15","NameAr":"إدفو","NameEn":"Edfu"},
    {"number":"261","governmentNumber":"15","NameAr":"الرديسية","NameEn":"Al-Radisiyah"},
    {"number":"262","governmentNumber":"15","NameAr":"البصيلية","NameEn":"Al Basilia"},
    {"number":"263","governmentNumber":"15","NameAr":"السباعية","NameEn":"Al Sibaeia"},
    {"number":"264","governmentNumber":"15","NameAr":"ابوسمبل السياحية","NameEn":"Abo Simbl Al Siyahia"},
    {"number":"265","governmentNumber":"15","NameAr":"مرسى علم","NameEn":"Marsa Alam"},
    {"number":"266","governmentNumber":"16","NameAr":"أسيوط","NameEn":"Assiut"},
    {"number":"267","governmentNumber":"16","NameAr":"أسيوط الجديدة","NameEn":"Assiut El Gedida"},
    {"number":"268","governmentNumber":"16","NameAr":"ديروط","NameEn":"Dayrout"},
    {"number":"269","governmentNumber":"16","NameAr":"منفلوط","NameEn":"Manfalut"},
    {"number":"270","governmentNumber":"16","NameAr":"القوصية","NameEn":"Qusiya"},
    {"number":"271","governmentNumber":"16","NameAr":"أبنوب","NameEn":"Abnoub"},
    {"number":"272","governmentNumber":"16","NameAr":"أبو تيج","NameEn":"Abu Tig"},
    {"number":"273","governmentNumber":"16","NameAr":"الغنايم","NameEn":"El Ghanaim"},
    {"number":"274","governmentNumber":"16","NameAr":"ساحل سليم","NameEn":"Sahel Selim"},
    {"number":"275","governmentNumber":"16","NameAr":"البداري","NameEn":"El Badari"},
    {"number":"276","governmentNumber":"16","NameAr":"صدفا","NameEn":"Sidfa"},
    {"number":"277","governmentNumber":"17","NameAr":"بني سويف","NameEn":"Bani Sweif"},
    {"number":"278","governmentNumber":"17","NameAr":"بني سويف الجديدة","NameEn":"Beni Suef El Gedida"},
    {"number":"279","governmentNumber":"17","NameAr":"الواسطى","NameEn":"Al Wasta"},
    {"number":"280","governmentNumber":"17","NameAr":"ناصر","NameEn":"Naser"},
    {"number":"281","governmentNumber":"17","NameAr":"إهناسيا","NameEn":"Ehnasia"},
    {"number":"282","governmentNumber":"17","NameAr":"ببا","NameEn":"beba"},
    {"number":"283","governmentNumber":"17","NameAr":"الفشن","NameEn":"Fashn"},
    {"number":"284","governmentNumber":"17","NameAr":"سمسطا","NameEn":"Somasta"},
    {"number":"285","governmentNumber":"17","NameAr":"الاباصيرى","NameEn":"Alabbaseri"},
    {"number":"286","governmentNumber":"17","NameAr":"مقبل","NameEn":"Mokbel"},
    {"number":"287","governmentNumber":"18","NameAr":"بورسعيد","NameEn":"PorSaid"},
    {"number":"288","governmentNumber":"18","NameAr":"بورفؤاد","NameEn":"Port Fouad"},
    {"number":"289","governmentNumber":"18","NameAr":"العرب","NameEn":"Alarab"},
    {"number":"290","governmentNumber":"18","NameAr":"حى الزهور","NameEn":"Zohour"},
    {"number":"291","governmentNumber":"18","NameAr":"حى الشرق","NameEn":"Alsharq"},
    {"number":"292","governmentNumber":"18","NameAr":"حى الضواحى","NameEn":"Aldawahi"},
    {"number":"293","governmentNumber":"18","NameAr":"حى المناخ","NameEn":"Almanakh"},
    {"number":"294","governmentNumber":"18","NameAr":"حى مبارك","NameEn":"Mubarak"},
    {"number":"295","governmentNumber":"19","NameAr":"دمياط","NameEn":"Damietta"},
    {"number":"296","governmentNumber":"19","NameAr":"دمياط الجديدة","NameEn":"New Damietta"},
    {"number":"297","governmentNumber":"19","NameAr":"رأس البر","NameEn":"Ras El Bar"},
    {"number":"298","governmentNumber":"19","NameAr":"فارسكور","NameEn":"Faraskour"},
    {"number":"299","governmentNumber":"19","NameAr":"الزرقا","NameEn":"Zarqa"},
    {"number":"300","governmentNumber":"19","NameAr":"السرو","NameEn":"alsaru"},
    {"number":"301","governmentNumber":"19","NameAr":"الروضة","NameEn":"alruwda"},
    {"number":"302","governmentNumber":"19","NameAr":"كفر البطيخ","NameEn":"Kafr El-Batikh"},
    {"number":"303","governmentNumber":"19","NameAr":"عزبة البرج","NameEn":"Azbet Al Burg"},
    {"number":"304","governmentNumber":"19","NameAr":"ميت أبو غالب","NameEn":"Meet Abou Ghalib"},
    {"number":"305","governmentNumber":"19","NameAr":"كفر سعد","NameEn":"Kafr Saad"},
    {"number":"306","governmentNumber":"20","NameAr":"الزقازيق","NameEn":"Zagazig"},
    {"number":"307","governmentNumber":"20","NameAr":"العاشر من رمضان","NameEn":"Al Ashr Men Ramadan"},
    {"number":"308","governmentNumber":"20","NameAr":"منيا القمح","NameEn":"Minya Al Qamh"},
    {"number":"309","governmentNumber":"20","NameAr":"بلبيس","NameEn":"Belbeis"},
    {"number":"310","governmentNumber":"20","NameAr":"مشتول السوق","NameEn":"Mashtoul El Souq"},
    {"number":"311","governmentNumber":"20","NameAr":"القنايات","NameEn":"Qenaiat"},
    {"number":"312","governmentNumber":"20","NameAr":"أبو حماد","NameEn":"Abu Hammad"},
    {"number":"313","governmentNumber":"20","NameAr":"القرين","NameEn":"El Qurain"},
    {"number":"314","governmentNumber":"20","NameAr":"ههيا","NameEn":"Hehia"},
    {"number":"315","governmentNumber":"20","NameAr":"أبو كبير","NameEn":"Abu Kabir"},
    {"number":"316","governmentNumber":"20","NameAr":"فاقوس","NameEn":"Faccus"},
    {"number":"317","governmentNumber":"20","NameAr":"الصالحية الجديدة","NameEn":"El Salihia El Gedida"},
    {"number":"318","governmentNumber":"20","NameAr":"الإبراهيمية","NameEn":"Al Ibrahimiyah"},
    {"number":"319","governmentNumber":"20","NameAr":"ديرب نجم","NameEn":"Deirb Negm"},
    {"number":"320","governmentNumber":"20","NameAr":"كفر صقر","NameEn":"Kafr Saqr"},
    {"number":"321","governmentNumber":"20","NameAr":"أولاد صقر","NameEn":"Awlad Saqr"},
    {"number":"322","governmentNumber":"20","NameAr":"الحسينية","NameEn":"Husseiniya"},
    {"number":"323","governmentNumber":"20","NameAr":"صان الحجر القبلية","NameEn":"san alhajar alqablia"},
    {"number":"324","governmentNumber":"20","NameAr":"منشأة أبو عمر","NameEn":"Manshayat Abu Omar"},
    {"number":"325","governmentNumber":"21","NameAr":"الطور","NameEn":"Al Toor"},
    {"number":"326","governmentNumber":"21","NameAr":"شرم الشيخ","NameEn":"Sharm El-Shaikh"},
    {"number":"327","governmentNumber":"21","NameAr":"دهب","NameEn":"Dahab"},
    {"number":"328","governmentNumber":"21","NameAr":"نويبع","NameEn":"Nuweiba"},
    {"number":"329","governmentNumber":"21","NameAr":"طابا","NameEn":"Taba"},
    {"number":"330","governmentNumber":"21","NameAr":"سانت كاترين","NameEn":"Saint Catherine"},
    {"number":"331","governmentNumber":"21","NameAr":"أبو رديس","NameEn":"Abu Redis"},
    {"number":"332","governmentNumber":"21","NameAr":"أبو زنيمة","NameEn":"Abu Zenaima"},
    {"number":"333","governmentNumber":"21","NameAr":"رأس سدر","NameEn":"Ras Sidr"},
    {"number":"334","governmentNumber":"22","NameAr":"كفر الشيخ","NameEn":"Kafr El Sheikh"},
    {"number":"335","governmentNumber":"22","NameAr":"وسط البلد كفر الشيخ","NameEn":"Kafr El Sheikh Downtown"},
    {"number":"336","governmentNumber":"22","NameAr":"دسوق","NameEn":"Desouq"},
    {"number":"337","governmentNumber":"22","NameAr":"فوه","NameEn":"Fooh"},
    {"number":"338","governmentNumber":"22","NameAr":"مطوبس","NameEn":"Metobas"},
    {"number":"339","governmentNumber":"22","NameAr":"برج البرلس","NameEn":"Burg Al Burullus"},
    {"number":"340","governmentNumber":"22","NameAr":"بلطيم","NameEn":"Baltim"},
    {"number":"341","governmentNumber":"22","NameAr":"مصيف بلطيم","NameEn":"Masief Baltim"},
    {"number":"342","governmentNumber":"22","NameAr":"الحامول","NameEn":"Hamol"},
    {"number":"343","governmentNumber":"22","NameAr":"بيلا","NameEn":"Bella"},
    {"number":"344","governmentNumber":"22","NameAr":"الرياض","NameEn":"Riyadh"},
    {"number":"345","governmentNumber":"22","NameAr":"سيدي سالم","NameEn":"Sidi Salm"},
    {"number":"346","governmentNumber":"22","NameAr":"قلين","NameEn":"Qellen"},
    {"number":"347","governmentNumber":"22","NameAr":"سيدي غازي","NameEn":"Sidi Ghazi"},
    {"number":"348","governmentNumber":"23","NameAr":"مرسى مطروح","NameEn":"Marsa Matrouh"},
    {"number":"349","governmentNumber":"23","NameAr":"الحمام","NameEn":"El Hamam"},
    {"number":"350","governmentNumber":"23","NameAr":"العلمين","NameEn":"Alamein"},
    {"number":"351","governmentNumber":"23","NameAr":"الضبعة","NameEn":"Dabaa"},
    {"number":"352","governmentNumber":"23","NameAr":"النجيلة","NameEn":"Al-Nagila"},
    {"number":"353","governmentNumber":"23","NameAr":"سيدي براني","NameEn":"Sidi Brani"},
    {"number":"354","governmentNumber":"23","NameAr":"السلوم","NameEn":"Salloum"},
    {"number":"355","governmentNumber":"23","NameAr":"سيوة","NameEn":"Siwa"},
    {"number":"356","governmentNumber":"23","NameAr":"مارينا","NameEn":"Marina"},
    {"number":"357","governmentNumber":"23","NameAr":"الساحل الشمالى","NameEn":"North Coast"},
    {"number":"358","governmentNumber":"24","NameAr":"الأقصر","NameEn":"Luxor"},
    {"number":"359","governmentNumber":"24","NameAr":"الأقصر الجديدة","NameEn":"New Luxor"},
    {"number":"360","governmentNumber":"24","NameAr":"إسنا","NameEn":"Esna"},
    {"number":"361","governmentNumber":"24","NameAr":"طيبة الجديدة","NameEn":"New Tiba"},
    {"number":"362","governmentNumber":"24","NameAr":"الزينية","NameEn":"Al ziynia"},
    {"number":"363","governmentNumber":"24","NameAr":"البياضية","NameEn":"Al Bayadieh"},
    {"number":"364","governmentNumber":"24","NameAr":"القرنة","NameEn":"Al Qarna"},
    {"number":"365","governmentNumber":"24","NameAr":"أرمنت","NameEn":"Armant"},
    {"number":"366","governmentNumber":"24","NameAr":"الطود","NameEn":"Al Tud"},
    {"number":"367","governmentNumber":"25","NameAr":"قنا","NameEn":"Qena"},
    {"number":"368","governmentNumber":"25","NameAr":"قنا الجديدة","NameEn":"New Qena"},
    {"number":"369","governmentNumber":"25","NameAr":"ابو طشت","NameEn":"Abu Tesht"},
    {"number":"370","governmentNumber":"25","NameAr":"نجع حمادي","NameEn":"Nag Hammadi"},
    {"number":"371","governmentNumber":"25","NameAr":"دشنا","NameEn":"Deshna"},
    {"number":"372","governmentNumber":"25","NameAr":"الوقف","NameEn":"Alwaqf"},
    {"number":"373","governmentNumber":"25","NameAr":"قفط","NameEn":"Qaft"},
    {"number":"374","governmentNumber":"25","NameAr":"نقادة","NameEn":"Naqada"},
    {"number":"375","governmentNumber":"25","NameAr":"فرشوط","NameEn":"Farshout"},
    {"number":"376","governmentNumber":"25","NameAr":"قوص","NameEn":"Quos"},
    {"number":"377","governmentNumber":"26","NameAr":"العريش","NameEn":"Arish"},
    {"number":"378","governmentNumber":"26","NameAr":"الشيخ زويد","NameEn":"Sheikh Zowaid"},
    {"number":"379","governmentNumber":"26","NameAr":"نخل","NameEn":"Nakhl"},
    {"number":"380","governmentNumber":"26","NameAr":"رفح","NameEn":"Rafah"},
    {"number":"381","governmentNumber":"26","NameAr":"بئر العبد","NameEn":"Bir al-Abed"},
    {"number":"382","governmentNumber":"26","NameAr":"الحسنة","NameEn":"Al Hasana"},
    {"number":"383","governmentNumber":"27","NameAr":"سوهاج","NameEn":"Sohag"},
    {"number":"384","governmentNumber":"27","NameAr":"سوهاج الجديدة","NameEn":"Sohag El Gedida"},
    {"number":"385","governmentNumber":"27","NameAr":"أخميم","NameEn":"Akhmeem"},
    {"number":"386","governmentNumber":"27","NameAr":"أخميم الجديدة","NameEn":"Akhmim El Gedida"},
    {"number":"387","governmentNumber":"27","NameAr":"البلينا","NameEn":"Albalina"},
    {"number":"388","governmentNumber":"27","NameAr":"المراغة","NameEn":"El Maragha"},
    {"number":"389","governmentNumber":"27","NameAr":"المنشأة","NameEn":"almunsha'a"},
    {"number":"390","governmentNumber":"27","NameAr":"دار السلام","NameEn":"Dar AISalaam"},
    {"number":"391","governmentNumber":"27","NameAr":"جرجا","NameEn":"Gerga"},
    {"number":"392","governmentNumber":"27","NameAr":"جهينة الغربية","NameEn":"Jahina Al Gharbia"},
    {"number":"393","governmentNumber":"27","NameAr":"ساقلته","NameEn":"Saqilatuh"},
    {"number":"394","governmentNumber":"27","NameAr":"طما","NameEn":"Tama"},
    {"number":"395","governmentNumber":"27","NameAr":"طهطا","NameEn":"Tahta"},
    {"number":"396","governmentNumber":"27","NameAr":"الكوثر","NameEn":"Alkawthar"}
]