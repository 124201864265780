<template>
  <div >
  <v-card class="pa-2">

    <v-container class="text-center">
      <h4>{{$t('UserData')}}</h4>

    </v-container>
      <v-container class="text-center">
        <v-row>
          <v-col cols="12" md="1">
            <span>
                <img style="max-height:40px ;max-width:40px" :src="User.country && User.country.imagePath!==''?APP_URL+User.country.imagePath: require('@/assets/no-image.png')">
              </span>
          </v-col>
          <v-col
              cols="12"
              md="3"
              class="pa-0 ma-0"
          >

            <div>
              <p class="ph">{{$t('UserName')}}</p>
              <p class="pb">{{User.name}}</p>
            </div>
          </v-col>
          <v-col
              cols="12"
              md="4"
              class="pa-0 ma-0"
          >
            <div>
              <p class="ph">{{$t('Phone')}}</p>
              <p class="pb">{{User.phone}}</p>
            </div>
          </v-col>
          <v-col
              cols="12"
              md="4"
              class="pa-0 ma-0"
          >
            <div>
              <p class="ph">{{$t('Rank')}}</p>
              <p class="pb">{{User.rank}}</p>
            </div>
          </v-col>


        </v-row>
      </v-container>

  </v-card>
</div>
</template>

<script>
import {APP_URL} from "@/Helpers/Config";
export default {
  name: "UserHeader",
  props: {
    userId :Number,
    User: Object
  },
  data(){
    return {
      Current : {}
      ,APP_URL
    }
  },
  mounted() {
   if(this.User){
     this.Current=this.User;
   }
  },
  watch : {
    User(val){
      this.Current = val;
    },
    userId (val) {
    }
  }

  , methods:{}
}
</script>

<style scoped>
.ph{
  color: #989696;
  font-size: 1rem;
}.pb{
   color: #340202;
   font-size: 1.1rem;
 }

</style>