import Vuex  from 'vuex'
import Vue from "vue";
Vue.use(Vuex);
import {authModule} from './auth.module'
import {rankRolesModule} from "./rankroles.module";
import {usersModule} from "@/store/users.module";
import {servicesPlacesModule} from '@/store/servicesPlaces.module'
import {categoriesModule} from '@/store/categories.module'
import {regionsModule} from '@/store/regions.module'
import {userWalletModule} from '@/store/userWallet.module'
import {reportsModule} from "@/store/reports.module";
import {pointsPackageModule} from "@/store/pointsPackage.module";
import {notificationModule} from "@/store/notification.module";
import {offersModule} from "@/store/offers.module";
import {pageSectionModule} from "@/store/pageSection.module";
import {salesClaimModule} from "@/store/salesClaim.module";
import {chatModule} from "@/store/chat.module";
import {userComplaintModule} from "@/store/userComplaint.module";
import {ordersModule} from "@/store/orders.module";

export default new Vuex.Store ({
  modules: {
    authModule,
    rankRolesModule,
    usersModule,
    servicesPlacesModule,
    categoriesModule,
    regionsModule,
    userWalletModule,
    reportsModule,
    pointsPackageModule,
    notificationModule,
    offersModule,
    pageSectionModule,
    salesClaimModule,
    chatModule,
    userComplaintModule,
    ordersModule
  }
})
