<template>
  <v-dialog
      v-model="activeStat"
      max-width="1000px"
  >
    <v-card>
      <v-card-title>
        {{item ? $t('Edit'):$t("Add")}}
      </v-card-title>

      <v-card-text>
      <v-form v-model="valid" ref="branch_form">
        <ServicePlaceBranchForm @Changed="OnResult" :item="item"></ServicePlaceBranchForm>
      </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="SaveBranch" class="primary ma-1">
          {{$t('Save')}}
        </v-btn>
        <v-btn @click="close" class="red ma-1" text>
          {{$t('Cancel')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ServicePlaceBranchForm from "@/components/ServicesPlaces/ServicePlaceBranchForm";
export default {
  name: "ServicePlaceBranchDialog",
  components: {ServicePlaceBranchForm},
  props:{
    dialog:Boolean,
    item:Object,
    servicePlaceId:Number,
  },data(){
    return {
      activeStat : this.dialog,
      valid : false,
      itemData : null,
      btn:{
        loading : false,
        disable : false
      }
    }
  }
  ,methods:{

    close(){
      this.activeStat = false;
      this.$emit('edited')
    },
    OnResult(res){
        this.itemData = res;
    },
    SaveBranch(){
      this.$refs.branch_form.validate();
      if(this.valid && this.itemData){
        if(this.itemData.lat===0 && this.itemData.lng===0 ){
          this.$toastr.e(this.$i18n.t('errors.LocationRequired'));
          return false;
        }
        if(!this.servicePlaceId)return;

        this.itemData.ServicePlaceId = this.servicePlaceId;
        this.btn.loading = true;
        this.btn.disable = false;
        this.$store.dispatch('servicesPlacesModule/SaveServicePlaceLocation', this.itemData).then(res=>{
          this.btn.loading = false;
          this.close();
        })
      }
    }
  },watch:{
    dialog (val) {
      this.activeStat = val;
    },
    activeStat(val){
      debugger;
      if(!val){
        this.close();
      }
    }
  },
}
</script>

<style scoped>

</style>