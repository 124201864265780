<template>
  <div>

    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      dark
      src="@/assets/img/bgDrawer.jpg"
    >
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
<!--            <img src="@/assets/img/logo.png" alt="Logo" />-->
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title">Calango</v-list-item-title>
            <v-list-item-subtitle>WEB</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item
          v-for="([icon, text, link], i) in items"
          :key="i"
          link
          @click="$vuetify.goTo(link)"
        >
          <v-list-item-icon class="justify-center">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">{{text}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>


      <v-app-bar height="100px" class="white appbar pa-0" dense fixed flat app flex wrap>
        <v-container  class="container--fluid pa-0">
          <v-slide-y-transition leave-absolute >
            <app-top-header :hide-sys-bar="hideSysBar"></app-top-header>
          </v-slide-y-transition >
          <v-fab-transition >
            <div>
              <v-spacer class="mt-5"></v-spacer >
              <v-row class="pa-5 pb-7">
                <v-toolbar-title>
<!--                  <v-img src="@/assets/img/logo-png.png" max-width="100px" />-->
                </v-toolbar-title>
                <v-spacer />
                <v-app-bar-nav-icon
                    @click.stop="drawer = !drawer"
                    class="mr-4"
                    v-if="isXs"
                />
                <div v-else>
                  <v-btn text @click="$vuetify.goTo('#hero')">
                    <span class="mr-2">Home</span>
                  </v-btn>
                  <v-btn text @click="$vuetify.goTo('#features')">
                    <span class="mr-2">Sobre</span>
                  </v-btn>
                  <v-btn text @click="$vuetify.goTo('#download')">
                    <span class="mr-2">Download</span>
                  </v-btn>
                  <v-btn text @click="$vuetify.goTo('#pricing')">
                    <span class="mr-2">Preços</span>
                  </v-btn>
                  <v-btn text @click="$vuetify.goTo('#contact')">
                    <span class="mr-2">Contate-nos</span>
                  </v-btn>
                </div>
              </v-row>
            </div>
          </v-fab-transition>


        </v-container>
    </v-app-bar>

  </div>
</template>

<style>
</style>

<script>
import AppTopHeader from "@/components/AppTopHeader";
export default {
  components: {AppTopHeader},
  data: () => ({
    drawer: null,
    isXs: false,
    hideSysBar:true,
    items: [
      ["mdi-home-outline", "Home", "#hero"],
      ["mdi-information-outline", "Sobre", "#features"],
      ["mdi-download-box-outline", "Download", "#download"],
      ["mdi-currency-usd", "Preços", "#pricing"],
      ["mdi-email-outline", "Contatos", "#contact"],
    ],
  }),
  props: {
    color: String,
    flat: Boolean,
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
      this.$emit('Resized' , this.isXs);
    },
    handleScroll(event) {
      let pos = window.top.scrollY;
      if(pos>120){
        this.hideSysBar = false;
      }
      if(pos<100){
        this.hideSysBar = true;
      }

    }
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    window.addEventListener('scroll', this.handleScroll);

  },
};
</script>
