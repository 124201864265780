<template>
<v-container fluid class="dashboard-container" >
  <v-row>
    <v-col cols="12" md="4">
      <AppDropDown  :validate="true" :item="this.selected" :title="$t('SelectGovernment')" :data-id="countryId" :action="'regionsModule/GetGovernmentsByCountryId'"  :getter="'regionsModule/GetAllGovernment'"  @Selected="GovernmentSelected" ></AppDropDown>
    </v-col>
  </v-row>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <CitiesGrid v-if="selected"  :selected="this.selected"></CitiesGrid>
    </v-col>
  </v-row>

</v-container>
</template>

<script>
import AppDropDown from "@/components/General/AppDropDown";
import CitiesGrid from "@/components/Regions/Cities/CitiesGrid";
export default {
  name: "Cities",
  components: {CitiesGrid, AppDropDown},
  data(){
    return {
      countryId : 0,
      governmentId:0,
      selected : null
    }
  },
  computed : {
    GetCountry(){
      let item =  this.$store.getters["regionsModule/GetAllCountries"].find(s=>{return s.id === this.CountryId});
      if(item){
        return { id:item.id};
      }else{
        return {id:0}
      }


    }
  },
  created() {
    this.countryId = parseInt(this.$route.params.countryId);
    this.governmentId = parseInt(this.$route.params.governmentId);
    this.selected = {
      id : this.governmentId
    }
  },
  methods:{
    GovernmentSelected(item){
      this.selected=item;
      this.$router.replace({name:'CitiesManagement' , params:{governmentId : item.id , countryId : this.countryId}});
    }
  }
}
</script>

<style scoped>

</style>