import store from '../store/index'

export default function roleMiddleware({ to , next, router }) {
    debugger;
    let valid = true;
    to.meta.permissions.forEach(s=>{
       if(!store.getters['authModule/HasPermission'](s)) valid=false;
    });
    if(Array.isArray(to.meta.roles) && !store.getters['authModule/HasRole'](to.meta.roles)) valid= false;
    if(valid)
        return next();
    else
        return router.back();
}