<template>
  <div class="auth-wrapper auth-v1 container--fluid" >
    <div class="auth-inner"  style="width: 40rem">
      <v-card class="auth-card" elevation="6" style="border:3px solid #9197b2">
        <!-- logo -->
        <v-card-title v-if="showHeader" class="d-flex align-center justify-center py-7">
          <p style="color:#518dff" class="text-2xl font-weight-semibold text--primary mb-2">
            {{$t("WelcomeTo")}}
          </p>

          <router-link
              to="/"
              class="d-flex align-center"
          >

            <v-img
                :src="require('../assets/logo.png')"
                max-height="70px"
                max-width="300px"
                alt="logo"
                contain
                class="me-3 "
            ></v-img>
          </router-link>
        </v-card-title>
        <!-- title -->
        <v-card-text>


          <p class="mb-2  font-size-regular" style="color: #fd0c0c;width: 100%" v-if="IsLoginRequired">
            {{$t("PleaseLoginForSubscribe")}}
            <v-btn class="success custom-float-right font-size-regular" @click="()=>{$emit('GoToRegister' , IsLoginRequired)}">
              {{$t('DontHaveAccount')}}
            </v-btn>
          </p>
          <p style="width: 100%" class="mb-2 font-size-regular" v-else>
            {{$t("PleaseLoginText")}}
            <v-btn class="success custom-float-right font-size-regular" @click="()=>{$emit('GoToRegister' , IsLoginRequired)}">
              {{$t('DontHaveAccount')}}
            </v-btn>

          </p>

        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
                v-model="form.email"
                filled
                rounded
                :label='$t("Email")'
                placeholder="any@example.com"
                hide-details
                class="mb-3"
            ></v-text-field>

            <v-text-field
                v-model="form.password"
                filled
                rounded

                :type="isPasswordVisible ? 'text' : 'password'"
                :label="$t('Password')"
                placeholder="············"
                hide-details
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                  :label="$t('RememberMe')"
                  hide-details
                  class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <a
                  href="javascript:void(0)"
                  class="mt-1"
              >
                {{$t("ForgetPassword")}}
              </a>
            </div>

            <v-btn

                color="primary"
                class="mt-6"
                :loading="loading"
                :disabled="disabled"
                @click="login"
            >
              {{$t("Login")}}
            </v-btn>
            <v-btn
                style="color: white!important;"
                class="red mt-6 ml-4 mr-4 text--white"
                @click="$emit('Close')"
            >
              {{$t("Cancel")}}
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->


        <!-- divider -->



      </v-card>
    </div>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  name:"LoginSection",
  props:{
    showHeader:true
  },
  computed:{
    IsLoginRequired(){
      return this.$store.getters['pointsPackageModule/IsRequireLogin'];
    }
  },
  data() {
    return {
      isPasswordVisible:false,

      form:{
        email:'',
        password:''
      },
      AppName : this.$t('AppName'),
      loading :false,
      disabled:false,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods:{
    login(){
      this.loading = true;
      this.disabled = true;
      this.$store.dispatch('authModule/login',this.form).then(()=>{
        this.loading = false;
        this.disabled=false;
        let user = this.$store.getters["authModule/GetLoginUser"];
        if(this.showHeader){
          if(user.rankBaseName==="SalesConsultant"){
            this.$router.push({name:"SalesHome"});
          }else{
            this.$router.push({name:"Home"});
          }

        }else{
          this.$emit('Close');
          if(this.$route.name !== 'Home'){
            this.$router.push({name:'Home'});
          }
        }
      }).catch((errors)=>{

        this.loading = false
        this.disabled = false;
        this.$toastr.e(errors);
      });
    }
  }

}
</script>

<style lang="scss">
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}
</style>
