import i18n from "@/plugins/i18n/i18n";

export default [


    {
        icon: 'mdi-account-multiple-plus',
        title: i18n.t("UsersManagement") ,
        to: {name : "ModeratorUsers"},
        permission  : "UsersControl",
        roles  : ['Moderator'],
    },

    {
        icon: 'mdi-chart-bar-stacked',
        title: i18n.t("ProfitClaims") ,
        to: {name : "Admin.ProfitClaims"},
        permission  : "ProfitClaimsControl",
        roles: ['SuperAdmin']
    },
];