import OrdersService from "@/Services/OrdersService";
export const ordersModule = {
    namespaced : true,
    state:{

    }
    , actions:{
        GetPendingOrders({commit} , payload){
            return OrdersService.GetAllPendingByServicePlace(payload.ServicePlaceId).then(res=>{
                return Promise.resolve(res.data);
            })
        }
        //GetCurrentOrders
        ,
        GetCurrentOrders({commit} , payload){
            return OrdersService.GetCurrentOrders(payload).then(res=>{
                return Promise.resolve(res.data);
            })
        },
        //GetAllPendingUserOrders
        GetAllPendingUserOrders({commit} ){
            return OrdersService.GetAllPendingUserOrders().then(res=>{
                return Promise.resolve(res);
            })
        },

        //GetAllDoneUserOrders
        GetAllDoneUserOrders({commit} ){
            return OrdersService.GetAllDoneUserOrders().then(res=>{
                return Promise.resolve(res);
            })
        }
    }
    ,mutations: {

    },getters:{
    }

}