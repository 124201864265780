import i18n from "@/plugins/i18n/i18n";

export default [
    {
        icon: 'mdi-account-cog',
        title: i18n.t("RolesManagement") ,
        to: {name : "RolesManagement"},
        permission  : "RolesControl",
        roles:['SuperAdmin']
    },
    {
        icon: 'mdi-account-multiple-plus',
        title: i18n.t("UsersManagement") ,
        to: {name : "UsersManagement"},
        permission  : "UsersControl",
        roles:['SuperAdmin']
    },

    {
        icon: 'mdi-account-multiple-plus',
        title: i18n.t("AttachUserToManger") ,
        to: {name : "Admin.AttachUserToManger"},
        permission  : "AttachUsers",
        roles: ['SuperAdmin']
    },

    {
        icon: 'mdi-apps',
        title: i18n.t("CategoriesManagement") ,
        to: {name : "CategoriesManagement"},
        permission  : "CategoryControl",
        roles:['SuperAdmin']
    }
    ,
    {
        icon: 'mdi-map-marker-multiple' ,
        title: i18n.t("CountriesManagement") ,
        to: {name : "CountriesManagement"},
        permission  : "RegionControl",
        roles:['SuperAdmin']
    },

    {
        icon: 'mdi-office-building-cog' ,
        title: i18n.t("ServicePlaceManagement") ,
        to: {name : "ServicePlaceManagement"},
        permission  : "ServicesPlaceControl",
        roles:['SuperAdmin']

    },

    {
        icon: 'mdi-gift-open' ,
        title: i18n.t("PointsPackages") ,
        to: {name : "PointsPackagesManagement"},
        permission  : "PointsPackageControl",
        roles:['SuperAdmin']
    },
    {
        icon: 'mdi-chart-arc',
        title: i18n.t("ProfitSlices") ,
        to: {name : "AdminProfitSlices"},
        permission  : "ProfitSlicesControl",
    },

    {
        icon: 'mdi-file-chart-outline' ,
        title: i18n.t("Reports") ,
        to: {name : "AdminReports"},
        roles  :['SuperAdmin'],
    },

    {
        icon: 'mdi-page-previous' ,
        title: i18n.t("PagesControl") ,
        to: {name : "Admin.PagesControl"},
        permission  : "PageSectionControl",
    },
];