<template>
  <v-list-item @click="LangChanger">
    <v-list-item-icon class="me-2">
      <v-icon size="22">
        mdi-web
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{$t('lang')}}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import AppHelper from "@/Helpers/AppHelper";

export default {
  name: "LangChanger",
  methods:{
    LangChanger(){
      AppHelper.ChangeLanguage();
    }
  }
}
</script>

<style scoped>

</style>