<template>
  <div>
    <v-card-text>
      <p class="mb-2 text-md-h5 black--text text-lg font-weight-500">
        {{$t("EditServicePlace")}}
      </p>
    </v-card-text>
    <v-container v-if="loading" class="text-center">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-container>
      <v-form ref="form_add" v-if="!loading" v-model="form_valid">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="form.NameAr"
                      outlined
                      dense
                      :error-messages="errorMessages"

                      :label="$t('ServicePlace.NameAr')"
                      :rules="[rules.required]"
                  >

                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="form.NameEn"
                      outlined
                      dense
                      :error-messages="errorMessages"
                      :label="$t('ServicePlace.NameEn')"
                      :rules="[rules.required]"
                  >

                  </v-text-field>
                </v-col>


                <v-col md="12">
                  <v-text-field
                      v-model="phone"
                      outlined

                      dense
                      :placeholder="form.Phones.join(' , ')"
                      v-on:keyup.enter="changePhone"
                      :label="$t('ServicePlacePhones')"
                      append-icon="mdi-delete"
                      @click:append="DeletePhone"
                  >

                  </v-text-field>
                  <label v-if="form.Phones.length>0">{{this.form.Phones.join(' , ')}}</label>
                </v-col>
                <v-col cols="12" md="6">
                  <AppDropDown :title="$t('SelectCategory')" :item="{id:form.CatId}" :validate="true" :getter="'categoriesModule/GetAllCategories'" :action="''" @Selected="CategorySelected"></AppDropDown>
                </v-col>
                <v-col md="6">
                  <v-text-field
                      v-model="form.TaxAccountNumber"
                      outlined
                      dense
                      :label="$t('ServicePlace.TaxAccountNumber')"
                  >

                  </v-text-field>
                </v-col>
              </v-row>

            </v-col>
            <v-col cols="12" md="6">

              <ul class="row header-ul overflow-auto" style=" max-height: 500px" >
                <li class="col-4">
                  <v-img  class="cursor-pointer" @click="fileTrigger('file_input1')" :src="IdentityPath ===''? require('@/assets/no-image.png') : IdentityPath">
                    <label style="background-color: white" class="pa-1">{{$t('IdentityImage')}} </label>

                  </v-img>

                  <input type="file" v-show="false" ref="file_input1"  @change="change_identity_file"/>


                </li>
                <li class="col-4" style="">
                  <v-img  class="cursor-pointer" @click="fileTrigger('file_input2')" :src="CommerialPath ===''? require('@/assets/no-image.png') : CommerialPath">
                    <label style="background-color: white" class="pa-1"> {{$t('CommerialImage')}} </label>

                  </v-img>
                  <input type="file" v-show="false" ref="file_input2"  @change="change_commerial_file"/>


                </li>
<!--                <li class="col-4" >-->
<!--                  <v-img class="cursor-pointer" @click="fileTrigger('file_input3')" :src="require('@/assets/no-image.png')">-->
<!--                    <label> {{$t('PlaceImagesNote')}}</label>-->

<!--                    <input  type="file" multiple v-show="false" ref="file_input3"  @change="change_file"/>-->

<!--                  </v-img>-->

<!--                  <label style="color:green" v-if="ImagesPaths.length>0">Count : {{ImagesPaths.length}} </label>-->

<!--                </li>-->
<!--                <li class="col-4 rounded"  v-for="(filePath , index) in ImagesPaths"   :key="'file_'+index" :style="`max-width: 100% ; ${MainImage === index?'background : rgb(206 249 206)':''}` " >-->
<!--                  <label style="color: black"  v-if="MainImage !== index">{{$t("SelectAsMainImage")}}</label>-->
<!--                  <label style="color: black" v-else>{{$t("MainImage")}}</label>-->
<!--                  <v-icon @click="delete_Image(index)" style="color: red ; cursor: pointer" class="custom-text-left">mdi-delete</v-icon>-->
<!--                  <img class="rounded" style="max-height: 250px  ;max-width: 100% ;cursor: pointer" :src="filePath.url" @click="SetMainImage(index)">-->
<!--                </li>-->
              </ul>

            </v-col>

          </v-row>
        </v-card-text>

      </v-form>
      <v-card-actions v-if="!loading">
        <v-btn class="primary mt-2 pa-2"  small  @click="SaveForm">
          {{$t('Save')}}
        </v-btn>
      </v-card-actions>
  </div>
</template>

<script>
import {rules} from "@/Helpers/ValidationRules";
import CategoriesDropDown from "@/components/Categories/CategoriesDropDown";
import AppDropDown from "@/components/General/AppDropDown";
import {APP_URL} from "@/Helpers/Config";
export default {
  name: "AddServicePlaceForm",
  components: { AppDropDown,CategoriesDropDown},
  props:{
    item : 0
  }
  ,data(){
    return {
      loading:false,
      errorMessages:'',
      phone:'',
      form_valid:false,
      MainImage : -1,
      ImagesPaths:[],
      CommerialPath: "",
      IdentityPath: "",
      form:{
        Images:[],
        CommerialImagePath:'',
        OldImages:[],
        IdentityPath:'',
        Phones:[],
        NameAr : "",
        NameEn : "",
        CatId : 0 ,
        Id : 0 ,
        Image:null,
        Lat : 0,
        Lng :0
      },
      rules : rules,
      btn : {
        loading : false,
        disable :true
      }
    }
  }
  ,created() {
    if(this.item){
      this.initialize();
    }
  },
  computed:{

  },
  methods:{

    delete_Image(index){
      let ImagePath = this.ImagesPaths[index];
      if(ImagePath.isOld){
        this.form.OldImages.push(this.form.ImagesPaths[ImagePath.oldIndex]);
        this.form.ImagesPaths.splice(ImagePath.oldIndex ,1);
      }
      else
      {
        this.UnShiftImages(index);
        this.form.Images.splice(index ,1);
      }
      this.ImagesPaths.splice(index ,1);
      if(this.ImagesPaths.length === 0){
        this.MainImage = -1;
      }
    },
    initialize(){
      if(this.item === 0){
        return;
      }
      this.loading=true;
      this.$store.dispatch('servicesPlacesModule/GetServicePlaceFullData' , this.item).then(servicePlace=>{
        console.log(servicePlace);
        this.form.NameAr = servicePlace.nameAr;
        this.form.NameEn = servicePlace.nameEn;

        this.form.ImagesPaths = servicePlace.imagesPaths;
        this.form.IdentityPath = servicePlace.identityPath;
        this.form.CommerialImagePath = servicePlace.commerialImagePath;
        this.form.Phones = servicePlace.phone?.split(',')??[]
        this.form.Id = servicePlace.id;
        this.form.CatId = servicePlace.catId;
        this.form.ImagePath = servicePlace.imagePath;
        this.IdentityPath = APP_URL+this.form.IdentityPath;
        this.CommerialPath =APP_URL+this.form.CommerialImagePath;
        this.MainImage = 0;
        this.form.TaxAccountNumber = servicePlace.taxAccountNumber;
        this.loading = false;
        this.Images = [];
        if(servicePlace.imagePath !== "" && servicePlace.imagePath!== null)this.form.ImagesPaths.push(this.form.ImagePath);
        this.form.ImagesPaths.forEach((s , index)=>{
          this.ImagesPaths.push({
            isOld:true,
            fileIndex:0,
            url:APP_URL+s,
            oldIndex:  index
          });
        });
      });
    },
    CategorySelected(obj){
      this.form.CatId = obj.id
    },
    changePhone()
    {
      if(this.phone.length>5){
        this.form.Phones.push(this.phone);
        this.phone='';
      }
    },
    DeletePhone(){
      this.form.Phones=[];
    },
    SaveForm(){
      this.btn.loading = true;
      this.btn.disable = true;
      this.$store.dispatch('servicesPlacesModule/RegisterServicePlace' , this.form).then(response=>{
        if(this.form.Id){
          this.$toastr.s(this.$i18n.t('SuccessSaveMessage'));

        }else{
          this.$toastr.s(this.$i18n.t('RegisterRequestSent'));

        }
        this.btn.loading = false;
      }).catch(err=>{
        this.$toastr.e(err);
      });
    },
    change_identity_file(event){
      let files = event.target.files;
      if(files.length === 0)return;
      //for delete
      if(this.form.IdentityPath!== ''){
        this.form.OldImages.push(this.form.IdentityPath);
        this.form.IdentityPath = '';
      }
      this.IdentityPath = URL.createObjectURL(files[0])
      this.form.Identity = files[0];

    },
    change_commerial_file(event){
      let files = event.target.files;
      if(files.length === 0)return;
      //for delete
      if(this.form.CommerialImagePath!== ''){
        this.form.OldImages.push(this.form.CommerialImagePath);
        this.form.CommerialImagePath = '';
      }
      this.CommerialPath = URL.createObjectURL(files[0])
      this.form.CommerialImage = files[0];
    },
    change_file(event){
      let files = event.target.files;
      let valid = false;
      console.log(files);
      Array.from(files).forEach((file)=>{

        debugger;
        if(this.ImagesPaths.length === 6 ) valid = true;
        if(valid) return;
        let ImagePath = {
          isOld : false,
          url : URL.createObjectURL(file),
          fileIndex : 0,
          oldIndex : 0

        };
        this.ShiftImages();
        this.ImagesPaths.unshift(ImagePath);

        this.form.Images.unshift(file);

        if(this.MainImage!== -1){
          this.SetMainImage(1);
        }
      })

      if(valid) return this.$toastr.e(this.$i18n.t('errors.Images-length'));
    },
    SetMainImage(Index){
      debugger;
      if(this.MainImage === Index && Index === 0)return;
      let ImagePath = this.ImagesPaths[Index];
      let isValid = false;
      if(ImagePath.isOld === false){
        isValid= true;
        let file = this.form.Images[ImagePath.fileIndex];
        this.form.Images.splice(ImagePath.fileIndex , 1);
        this.form.Images.unshift(file);

      }else{
        this.form.ImagePath = this.form.ImagesPaths[ImagePath.oldIndex];
      }
      this.ImagesPaths.splice(Index , 1);
      if(isValid)this.ShiftImages();
      this.MainImage = 0;
      ImagePath.fileIndex=0;
      this.ImagesPaths.unshift(ImagePath);

    },
    fileTrigger(ref){
      let elem = this.$refs[ref];
      elem.click();
    },
    ShiftImages(){
      this.ImagesPaths.forEach(s=>{
        if(s.isOld===false){
          s.fileIndex++;
        }
      });
    },
    UnShiftImages(indexp){
      this.ImagesPaths.forEach((s , index)=>{
        if(s.isOld===false && index>=indexp){
          s.fileIndex--;
        }
      });
    },
    ValidateForm(){
    this.$refs.form_add.validate();
    if(this.form_valid===false){

      return false;
    }
    if(this.form.Phones.length===0){
      this.$toastr.e(this.$i18n.t('errors.PhoneMin'));
      return false;
    }
    if(this.form.Identity===null && this.form.IdentityPath === ''){
      this.$toastr.e(this.$i18n.t('errors.IdentityImageError'));
      return false;
    }
    if(this.form.CommerialImage===null && this.form.CommerialImagePath ===''){
      this.$toastr.e(this.$i18n.t('errors.CommerialImageError'));
      return false;
    }
    if(this.form.lat===0 && this.form.lng===0 ){
      this.$toastr.e(this.$i18n.t('errors.LocationRequired'));
      return false;
    }
    if(this.ImagesPaths.length===0){
      this.$toastr.e(this.$i18n.t('errors.PlaceImagesError'));
      return false;
    }
    if(this.MainImage===-1){
      this.$toastr.e(this.$i18n.t('errors.MainImageError'));
      return false;
    }
    return true;
  }
  }

}
</script>

<style scoped>

</style>