<template>
  <div style="background-color: #13255d !important"  class="app-container">
    <ServicePlaceAddStepper @Saved="SavedEvent"></ServicePlaceAddStepper>
  </div>
</template>
<script>
import ServicePlaceAddStepper from "@/components/ServicesPlaces/ServicePlaceAddStepper";
export default {
  name: "AdminServicePlaceAdd",
  props:{
    add:false,
  },
  components: {ServicePlaceAddStepper},
  mounted() {

  },
  methods:{
    SavedEvent(item){
      if(this.add) this.$router.push({name:"ServicePlaceEdit" , params:{id:item.id}});
    }
  }
}
</script>

<style scoped>

</style>