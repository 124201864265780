<template>
  <v-dialog
      v-model="activeStat"
      max-width="1000px"
  >

    <v-card>
      <v-card-title>
        {{$t('CategoryServices')}}
      </v-card-title>

      <v-card-text>

        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12"  md="5">
                <v-text-field :label="$t('NameAr')" v-model="form.nameAr" outlined dense>

                </v-text-field>
              </v-col>
              <v-col cols="12"  md="5">
                <v-text-field :label="$t('NameAr')" v-model="form.nameEn" outlined dense>
                </v-text-field>
              </v-col>
              <v-col cols="12"  md="2">
               <v-btn class="primary" small @click="SaveService" :loading="btn.loading"> {{$t('Add')}}</v-btn>
              </v-col>
            </v-row>

          </v-col>
          <v-col cols="12" v-if="loading">
            <v-container  class="text-center">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </v-container>
          </v-col>
          <v-col cols="12" v-if="!loading">
            <v-card>
              <v-card-title>
                <h4>الخدمات</h4>
              </v-card-title>
              <v-card-text class="text-center">
                <p v-if="GetCategoryServices.length === 0">
                  {{$t('NoData')}}
                </p>
                <v-chip
                    v-for="service in GetCategoryServices"

                    class="ma-2"
                    close
                    :key="'service_'+service.id"
                    color="grey"
                    label
                    outlined
                    @click:close="deleteService(service)"
                >
                  <v-checkbox v-model="service.isActive" @click="Activate(service)">

                  </v-checkbox>
                  {{service.name}}
                </v-chip>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<script>
export default {
  name: "CategoryServices",
  props:{
    CategoryId : null,
    dialog : false,
  }
  ,
  data(){
   return {
     activeStat : this.dialog,
     form : {
       nameAr:"",
       id:0,
       nameEn:"",
       categoryId:0,
       isActive: true,
     },
     loading : false,
     btn : {loading : false , disable : false}
   }
  },watch:{
    dialog (val) {
      this.activeStat = val;
    },
    activeStat(val){
      this.$emit('Close' );
    },CategoryId(val){
      if(val){
        this.initialize();
      }
    }
  },
  computed:{
    GetCategoryServices(){
      return this.$store.getters['categoriesModule/GetCategoryServices']
    }
  }
  ,mounted(){
    if(this.CategoryId){
      this.initialize();
    }
  }, methods:{
    Close(){
      this.$emit('Close')
    },
    deleteService(service){
      this.$store.dispatch('categoriesModule/deleteCategoryService' , service.id).then(res=>{
        this.initialize();
      })
    },
    SaveService(val = false){
      if(this.form.nameAr === '' || this.form.nameEn ==='')return this.$toastr.e(this.$i18n.t('InvalidData'));
      if(!val)this.btn.loading = true;
      this.$store.dispatch('categoriesModule/saveCategoryService' , this.form).then(res=>{
        this.btn.loading = false;

        this.initialize();
        this.form.nameAr = '';
        this.form.nameEn = '';
        this.form.id= 0;
        this.form.isActive= true;
      })
    },
    Activate(service){
      this.form.nameAr = service.nameAr;
      this.form.nameEn = service.nameEn;
      this.form.id = service.id;
      this.form.isActive = service.isActive;
      this.SaveService(true);
    },
    initialize(){
      if(!this.CategoryId)return;
      this.form.categoryId = this.CategoryId;
      this.loading = true;
       this.$store.dispatch('categoriesModule/GetCategoryServices' , this.CategoryId).then(res=>{
         this.loading = false
       });
    }
  }
}
</script>

<style scoped>

</style>