<template>
  <div class="v-card elevation-3">

    <GmapAutocomplete v-if="mode!== 'preview'"  class="introInput rounded elevation-3 pa-4 ma-2" @place_changed="place_changed">
    </GmapAutocomplete>
    <v-btn
        v-if="mode!== 'preview'"
        class="primary pa-2 ma-1"
        @click="newCoordinates"
        :loading="loading"
        :disabled="loading"

    >
      الموقع الحالي
    </v-btn>
    <GmapMap
        :center="center"
        :zoom="18"
        class="pa-2"

        style="width: 100% ; height:100%; min-height: 400px"
    >



      <GmapMarker
          :key="index"
          v-for="(mar, index) in markers"
          :position="mar.position"
          :draggable="true"


          @dragend="dragMarker"
      />
    </GmapMap>

  </div>

</template>

<script>

import AppHelper from "@/Helpers/AppHelper";

export default  {
  name: "AppMap",
  props: {
    coords:Object,
    mode:''
  },
  data(){
    return {
      coordinates:{lat:30.077 , lng :31.342},
      address : "",
      center:{lat:30.077 , lng :31.342},
      slotProps: {},
      search:"",
      loading:false,
      markers:[
        {
          position:{lat:30.077 , lng :31.342}
        }
      ]
    }
  },
  mounted() {
    if((Object.keys(this.coords).length === 0 || (this.coords.lat===0 && this.coords.lng === 0)) && this.mode!=='preview'){
      this.$getLocation({}).then(coordinates=>{
        this.markers[0].position = coordinates;
        this.coordinates= coordinates;
        this.center = coordinates;
        this.GetAddress();
      })
    }
    else{
      this.coordinates = this.coords;

      this.center = this.coords;
      this.markers[0].position =this.coords;
    }

  }
  ,methods:{
    place_changed(place){
      if(this.mode ==='preview') return;

      if (place) {
        const marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),

        };
        this.markers[0].position=marker;
        this.coordinates = marker;
        this.center = marker;
        this.GetAddress();

      }
    },

    newCoordinates(){
      if(this.mode ==='preview') return;

      this.loading= true;
      this.$getLocation({}).then(coordinates=>{
        setTimeout(()=>{this.loading=false} , 1000);
        this.markers[0].position = coordinates;
        this.coordinates= coordinates;
        this.center= coordinates;
        this.GetAddress();
      })
    },
    dragMarker(location) {
      if(this.mode ==='preview') return;
      setTimeout(()=>{
        this.coordinates = {
          lat: location.latLng.lat(),
          lng: location.latLng.lng(),
        };
        this.GetAddress();

      } , 200)
    },
    GetAddress(){
      let Address_ar = '';
      let Address_en = '';
      let Country = '';
      let Government = '';
      let Region = '';

      AppHelper.GetCurrentLocationFromCoords(this.coordinates , 'ar').then(res1=>{
        let com_ar = this.GetAddressComponents(res1);
        Address_ar = this.GetAddressString(com_ar);
        let lang = AppHelper.GetLang();
        if(lang === 'ar'){
          Country = this.GetCountry(com_ar);
          Government = this.GetGovernment(com_ar);
          Region = this.GetRegion(com_ar);
        }

        AppHelper.GetCurrentLocationFromCoords(this.coordinates , 'en').then(res=>{
          let com_en = this.GetAddressComponents(res);
          Address_en = this.GetAddressString(com_en);
          if(lang === 'en'){
            Country = this.GetCountry(com_en);
            Government = this.GetGovernment(com_en);
            Region = this.GetRegion(com_en);

          }
          this.$emit('result' ,
              {coords:this.coordinates ,
                   address_ar:Address_ar
                ,country: Country,
                government : Government,
                region : Region
            , address_en : Address_en});
        });
      });

    },
    GetAddressComponents(res ){
      debugger;
      let adCom = null;
      adCom = res.data.results[0];
      let address_com = adCom? adCom.address_components : [];
      if(address_com.length>0){
        address_com = address_com.filter(s=>{return !(s.types.includes('plus_code')
            || s.types.includes('postal_code') || s.types.includes('route'))});
      }
      return address_com;
    }
    ,GetAddressString(address){

      return address.map(s=>{return s.long_name}).join(' , ');
    }
    ,GetCountry(address) {
      return address.find(s => s.types.includes('country'));
    },
    GetGovernment(address) {
      let component = address.find(s=>s.types.includes('administrative_area_level_1'));
      if(!component) return '';
      return component.long_name.replace('Governorate' , '').replace('محافظة' , '');
    },
    GetRegion(address){
      let component = address.find(s=>s.types.includes('administrative_area_level_2'));
      if(!component) return '';
      if(component.long_name.includes('الظهير الصحراوى'))return '';
      return component.long_name.replace('Governorate ' , '')
          .replace('محافظة ' , '')
          .replace('قسم ' , '')
          .replace( 'مركز ' , ''  )
          .replace( ' Desert' , ''  )
    }

  }
}
</script>

<style scoped>
.introInput {
  padding: 10px;
  width: 60%;
  border : none;

}
.introInput:focus-visible{
  content: none;
}
</style>