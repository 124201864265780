<template>
<v-container class="container--fluid  dashboard-container">

  <div  class="table-header row pa-4" >
    <v-col
        md="6"
        sm="6"
    >
      <v-toolbar-title>{{$t("RolesGrid")}}</v-toolbar-title>
    </v-col>
    <v-col
        sm="4" md="4" offset="2"
    >
      <v-btn
          class="primary float-left pa-2"
          small
          :loading="saveBtn.loading"
          :disabled="saveBtn.disabled"
          @click="SaveRankRoles"
      >
        <v-icon>

        </v-icon>
        {{$t("Save")}}
      </v-btn>
    </v-col>



  </div>


 <RankRolesGrid  @rankRoleChanged="RankRoleChanged"  :RRoles="RanksRoles" :loading="loading"/>
</v-container>
</template>

<script>
import RankRolesGrid from "../../../components/Roles/RankRolesGrid";
export default {
  name: "Roles",
  components: {RankRolesGrid},
  data(){
    return {
      loading : true,
      saveBtn : {
        loading : false,
        disabled : false
      },
      RanksRoles : []
    }
  },
  mounted() {
    this.$store.dispatch("rankRolesModule/GetRankRolesGrid").then(res=>{
      this.loading = false;
        this.RanksRoles = this.$store.getters["rankRolesModule/GetRankRoles"]
    });
  },
  methods:{
    SaveRankRoles(){
      this.saveBtn.loading = true;
      this.saveBtn.disabled = true;
      this.$store.dispatch("rankRolesModule/SaveRankRoles" , this.RanksRoles).then(res=>{
        this.saveBtn.loading = false;
        this.saveBtn.disabled = false;
        this.$toastr.s(this.$i18n.t("SuccessSaveMessage"));
      });
    },
    RankRoleChanged(rankRoles){
      this.RanksRoles = rankRoles;
    }
  }
}
</script>

<style scoped>
</style>