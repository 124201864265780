<template>
  <div>
    <v-container v-if="loading" class="text-center">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-container>
      <v-pagination
          v-model="CurrentPage"
          :length="TotalPages"
          :total-visible="totalVisible"
          class="header-ul"
          dir="rtl"
      >
      </v-pagination>
  </div>
</template>

<script>
export default {
  name: "AppPagination",
  props:{
    store : String,
    collection : String,
    action : String,
    data : Object,
    items:Object,
    totalVisible:{
      type:Number,
      default : 10
    },
  },
  data(){
    return {
      dataObj : {Page:1},
      loading : false,
      itemsObj:null
    }
  },
  created() {
    if(this.data){
      this.dataObj = this.data;
    }
    if(this.items){
      this.itemsObj = this.items;
    }
  },
  watch:{
    items(val){
      this.itemsObj =val;
    },
    CurrentPage(newVal){
      if(this.dataObj.Page === newVal)return;
      this.PaginatePage(newVal);
    }
  },
  computed:{
    CurrentPage : {
      set(value){

        if(this.itemsObj){
          this.itemsObj.currentPage=value;
        }else{
          this.$store.commit(this.store+'/'+"setCurrentPage" , value);
        }
      },
      get(){
        if(this.items) return this.itemsObj.currentPage;
        return this.$store.getters[this.store+'/'+this.collection].currentPage;
      }
    },
    TotalPages : {
      get(){
        if(this.items)  return this.itemsObj.totalPages;
        return this.$store.getters[this.store+'/'+this.collection].totalPages;
      }
    }
  }
  ,methods :{
    PaginatePage(value){
      if(this.data !== null && this.data !== undefined){
        this.dataObj = this.data;
      }
      this.dataObj.Page = value;
      this.loading = true;
      this.$store.dispatch(this.store+'/'+this.action , this.dataObj).then(res=>{
        this.loading = false;
        if(this.itemsObj){
          this.itemsObj = res;
        }
        this.$emit('dataBound' , res);
      });
    }
  }
}
</script>

<style scoped>

</style>