<template>
  <div>
    <v-container>
      <v-btn
          :color="!IsApproved?'white' : 'primary'"
          class="ma-2"
          large
          @click="SetIsApprove(true)"
      >
        {{ $t('AllServicePlaces') }}
      </v-btn>
      <v-btn
          :color="IsApproved?'white' : 'primary'"
          class="ma-2"
          large
          @click="SetIsApprove(false)"
      >
        
        {{ $t('RegisteredRequests') }}
        <v-badge
            v-if="GetUnApprovedCount !== 0"
            class="ma-2"
            color="red"
            :content="GetUnApprovedCount"
        >
        </v-badge>
      </v-btn>
    </v-container>
    <v-data-table
        :headers="headers"
        :items="GetAll"
        class="elevation-3"
        :loading="loading"
        :no-data-text="$t('NoData')"
        :no-results-text="$t('NoResult')"
        :loading-text="$t('PleaseWait')"
        hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>{{$t('ServicesPlaces')}}</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-col
              md="2"
              cols="12"
          >
            <v-text-field
                v-model="search.name"
                append-icon="mdi-magnify"
                :label="$t('EnterName')"
                outlined
                dense
                hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="8" >
            <v-btn link :to="{name:'ServicePlaceAdd'}" class="primary float-left pa-2 ma-2" small>
              {{$t('AddServicePlace')}}
            </v-btn>
            <v-btn @click="initialize" class="warning float-left pa-2 ma-2" small>
              {{$t('Search')}}
            </v-btn>
          </v-col>
          <EditServicePlaceDialog @edited="itemEdited" :item="editedItem" v-if="dialog" :dialog="dialog" :edited-index="editedIndex"></EditServicePlaceDialog>
          <ConfirmationDialog @Confirmed="ConfirmationAction" :active-stat="confirmationDialog" :title="$t('ApproveConfirmationText')" ></ConfirmationDialog>
        </v-toolbar>
      </template>
      <template v-slot:item.imagePath="{ item }">
        <v-img
            :src="item.imagePath === null || item.imagePath ==='' ? require('@/assets/no-image.png') : APP_URL+item.imagePath "
            max-width="80px"

            class="ma-2 rounded"

        >

        </v-img>
      </template>
      <template v-slot:item.isApproved="{ item }">
        <v-chip  v-if="item.isApproved" color="success">
          {{$t('Approved')}}
        </v-chip>
        <v-btn class="ml-2 mr-2" depressed rounded  @click="ApprovePlace(item)" :loading="item.approveLoading" :disabled="item.approveDisabled" v-else color="warning">
          {{$t('NotApproved')}}

        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu offset-y v-if="menu_items.some(s=>s.show === true)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                small

                v-bind="attrs"
                v-on="on"
            >
              <v-icon>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                v-for="(m_item, index) in menu_items"
                :key="'action_'+index"
                v-if="m_item.show"
                @click="m_item.Click(item)"
            >
              <v-list-item-icon class=""><v-icon small>{{m_item.icon}}</v-icon></v-list-item-icon>

              <v-list-item-title>{{ m_item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      </template>
      <template v-slot:no-data>
        <v-btn
            v-if="selected"
            color="primary"
            @click="initialize"
        >
          {{ $t("tryAgain") }}
        </v-btn>
      </template>
      <template v-slot:footer>
        <AppPagination :data="{Page:1 , IsApproved : IsApproved }" :store="'servicesPlacesModule'" :action="'GetAll'" :collection="'GetAll'"></AppPagination>
      </template>

    </v-data-table>
  </div>


</template>


<script>
import AppPagination from "@/components/General/AppPagination";
import {APP_URL} from "@/Helpers/ConfigSandbox";
import EditServicePlaceDialog from "@/components/ServicesPlaces/EditServicePlaceDialog";
import ConfirmationDialog from "@/components/General/ConfirmationDialog";
export default {
  name: 'ServicesPlaces',
  components: {ConfirmationDialog, EditServicePlaceDialog, AppPagination},
  props:{
    selected : Object
  },
  data: () => ({
    search:{
      name: ''
    },
    IsApproved : true,
    dialog: false,
    confirmationDialog : false,
    dialogDelete: false,
    items: [],
    approveLoading:false,
    approveDisable : false,
    editedIndex: -1,
    editedItem: {
      number: 0 ,
      ratio:0.0,
      id:0
    },
    defaultItem: {
      number: 0 ,
      ratio:0.0,
      id:0
    },
    loading:false,
    APP_URL
  }),
  computed: {

    headers(){
      return  [
        { text: this.$i18n.t("NameAr"), value: 'nameAr' , align:'start'},
        { text: this.$i18n.t("NameEn"), value: 'nameEn' , align:'start'},
        {text: this.$i18n.t('Category') , value: 'categoryName'},
        { text: this.$i18n.t("Status"), value: 'isApproved' , align:'start'},

        { text: '', value: 'actions', sortable: false },
      ]
    },
    GetUnApprovedCount() {
      return this.$store.getters['servicesPlacesModule/GetUnApprovedCount'];
    },
    GetAll(){
      return this.$store.getters['servicesPlacesModule/GetAll'].data;
    },
    HasPermission(per){
      return this.$store.getters['authModule/HasPermission'](per);
    },
    menu_items(){ return [
      {title : this.$i18n.t('Edit') , Click : (item)=>{this.editItem(item)} , icon : "mdi-pencil", show : this.$store.getters['authModule/HasPermission']('EditServicePlace')},
      {title : this.$i18n.t('ServicePlaceServices') , Click : (item)=>{this.GoToServicePlaceServices(item)} , icon : "mdi-format-list-bulleted ", show : this.$store.getters['authModule/HasPermission']('EditServicePlace')},
      {title : this.$i18n.t('Users') , Click : (item)=>{this.GoToServicePlaceUsers(item)} , icon : "mdi-account-multiple", show : this.$store.getters['authModule/HasPermission']('EditServicePlace')},
      {title : this.$i18n.t('Delete') , Click : (item)=>{this.deleteItem(item)} ,icon:"mdi-delete",  show : this.$store.getters['authModule/HasPermission']('DeleteServicePlace')},
    ]}
  },
  created() {
    this.initialize();
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    selected(val){
      if(val!==null){
        this.initialize()

      }
    }
  },

  methods: {
    ConfirmationAction(isConfirmed){
      this.confirmationDialog = false;
      if(isConfirmed){
        this.editedItem.approveLoading = true;
        this.editedItem.approveDisabled = true;
        this.$store.dispatch('servicesPlacesModule/ApproveServicePlace' , {id : this.editedItem.id , status : true}).then(res=>{
          this.editedItem.approveLoading = false;
          this.editedItem.approveDisabled = false;
          this.editedItem.isApproved = true;

        });
      }else{
        this.editedItem = false;

      }
    },
    ApprovePlace(item){
      this.editedItem = item;
      this.confirmationDialog = true;
    },
    initialize () {
      this.loading = true
      this.$store.dispatch('servicesPlacesModule/GetAll' , {
        Page : 1,
        Name: this.search.name
        ,IsApproved : this.IsApproved
      }).then(()=>{
        this.loading= false;
      });
      this.$store.dispatch('servicesPlacesModule/GetUnApprovedServicePlacesCount' ).then(()=>{
        this.loading= false;
      });
    },
    GetUnApproved () {
      this.loading = true
      this.$store.dispatch('servicesPlacesModule/GetAll' , {
        Page : 1
      }).then(()=>{
        this.loading= false;
      });
    },
    editItem (item) {
      this.$router.push({name : "ServicePlaceEdit" , params:{id : item.id}});
    },
    deleteItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.$store.dispatch('usersModule/deleteUser' , this.items[this.editedIndex].id);
      this.items.splice(this.editedIndex, 1)
      this.closeDelete()
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    itemEdited(obj){
      this.editedItem = obj.item;
      this.editedIndex = obj.editedIndex;
      this.dialog = false;
    },
    selectItem(item){
      this.$emit("itemSelected" , item);
    }
    ,SetIsApprove(isApproved){
      this.IsApproved = isApproved;
      this.initialize();
    },
    GoToServicePlaceServices(item){
      this.$router.push({name:'ServicePlaceServices' , params:{id:item.id}});
    },
    GoToServicePlaceUsers(item){
      this.$router.push({name:'ServicePlaceUsers' , params:{id:item.id}});

    }

  },
}
</script>
