<template>
  <section class="pb-8" id="contact">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col class="ma-auto justify-content-center" cols="12" sm="5">
              <h1 class="text-h3 font-weight-light display-1"><v-icon color="white" x-large>mdi-face-agent</v-icon>
                {{$t('Contact')}}</h1>
              <h3 style="color: white!important;" class="font-weight-light mt-3">
                {{$t('Texts.contactUsText')}}
              </h3>

              <h3 style="color: white!important;"  class="font-weight-light mt-3">
                {{ $t('Phone') }} : +xx (xx) xxxxx-xxxx
              </h3>
              <h3 style="color: white!important;"  class="font-weight-light">
                {{ $t('Email') }} : support@tarfih-4u.com
              </h3>
            </v-col>
            <v-col   class="elevation-0" cols="12" sm="7">
              <v-form class="pa-5 contact_form"  ref="contact_form"  v-model="valid" >
                <v-row>
                  <v-col cols="12" sm="12" lg="6" md="6" class="pa-1">

                    <v-text-field
                        v-model="form.senderName"
                        :label="$t('Name')"
                        variant="solo"
                        :rules="[rules.required]"
                        solo
                        :error-messages="error_messages"
                        flat
                        rounded
                        background-color="#e1e1e1ba"
                        hide-details="auto"
                    ></v-text-field>

                  </v-col>
                  <v-col cols="12" sm="12" lg="6" md="6" class="pa-1">
                    <v-text-field
                        v-model="form.senderEmail"
                        :label="$t('Email')"
                        required
                        :rules="[rules.required]"
                        solo
                        :error-messages="error_messages"
                        flat
                        rounded
                        background-color="#e1e1e1ba"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" lg="12" md="12" class="pa-0">
                    <v-textarea
                        v-model="form.report"
                        :label="$t('Message')"
                        flat
                        solo
                        :error-messages="error_messages"
                        :rules="[rules.required]"
                        rounded
                        background-color="#e1e1e1ba"
                        required
                        rows="3"
                    />

                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12" class="pa-0 ma-0 custom-text-left">
                    <v-btn
                        x-large
                        dark
                        block
                        rounded
                        elevation="0"
                        :loading="contact_btn.loading"
                        :disabled="contact_btn.disabled"
                        class="mt-3 opacity-btn"
                        style="background-color: #000000a6; "
                        @click="submitContact"
                    >
                      {{$t('Send')}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>


  </section>
</template>
<script>
import {rules} from '@/Helpers/ValidationRules'
export default {
  data: () => ({
    form : {
      senderName : '',
      senderEmail : '',
      report : '',
    },
    error_messages : '',
    rules,
    valid:false,
    contact_btn : {
      loading : false,
      disabled : false
    }
  }),
  methods: {
    submitContact() {
      this.$refs.contact_form.validate();
      if(this.valid){
        this.contact_btn.disabled = true;
        this.contact_btn.loading = true;
        this.$store.dispatch('userComplaintModule/SendGuestComplaint' , this.form).then(res=>{
          this.contact_btn.disabled = false;
          this.contact_btn.loading = false;
          this.form = {
            senderName : '',
            senderEmail : '',
            report : '',
          };
          this.$refs.contact_form.reset();
          this.valid= false;
          this.$toastr.s("تم ارسال رسالتك بنجاح");
        });
      }
    }
  }
};
</script>
<style>
#contact {
  background-color: #62c78c;

  color : white;
}
.opacity-btn{
  background-color:  #000000a6;
}
.opacity-btn:hover{
  background-color: black !important;
}

.contact_form .v-messages__message{
  color : #f7cfcf!important;
}
</style>
