<template>
  <div>
    <v-menu
        offset-y
        max-height="600"
        nudge-bottom="13"
        class="not-list"
        ref="menu_not"
        :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">

        <v-btn
            icon
            v-if="GetLoginStatus"
            v-bind="attrs"
            v-on="on"
            text
            @click="GetNotClicked"
        >

          <v-icon >
            mdi-bell-outline

          </v-icon>
          <v-badge
              v-if="GetNotificationCount>0"
              color="red"
              class=""
              :content="GetNotificationCount"
          >
          </v-badge>
        </v-btn>

      </template>
      <v-list>

        <!-- Profile -->

        <v-list-item v-for="(not , index) in GetNotifications" :key="`not_${not.id}${index}`">

          <v-list-item-content>
            <v-alert
                text
                @click="HandleClick(not)"
                class="ma-0"
                :color="colors[not.notificationTypeString]?colors[not.notificationTypeString]:'info'"
                close-icon="mdi-edit"
            >
              <h3>
                {{not.title}}
              </h3>
              <div>
                {{not.content}}
              </div>

            </v-alert>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!loading && GetNotifications.length===0" >
          <v-list-item-content>
            <v-container  class="text-center">
              <h3>{{$t('NoData')}}</h3>
            </v-container>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="loading">
          <v-list-item-content>
            <v-container v-if="loading" class="text-center">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </v-container>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="LoadMore" class="end_nots" v-if="HasNext && GetNotifications.length > 0">
          load more
        </v-list-item>

      </v-list>
    </v-menu>

  </div>
</template>

<script>
import {getToken , getMessaging , onMessage} from "firebase/messaging";
import messaging from "../plugins/firebase";
import {PUBLIC_VABID_KEY} from "../Helpers/Config";

export default {
  name: "Notifications",
  data(){
    return{
      loading:false,
      colors : {NewOrderUserDiscountApproval:'info' ,NewOfferReservation :'primary' , OrderPayed:'red' , NewRegisterationRequest:'green'}
    }
  }
  ,computed:{
    GetLoginStatus(){
      return this.$store.getters['authModule/IsLoggedIn'];
    },
    GetNotificationCount() {
      return this.$store.getters['notificationModule/getNotificationCount'];
    }
    ,
    GetNotifications() {
      return this.$store.getters['notificationModule/getNotifications'];
    },
    GetPage(){
      return this.$store.getters['notificationModule/GetPage'];
    },
    HasNext(){
      return this.$store.getters['notificationModule/HasNext'];
    }
  },
  mounted() {
    this.$store.dispatch('notificationModule/GetNotificationCount');
    this.Activate();
  },
  /*
  * */
  methods:{
    GetNotificationsData() {
      this.loading = true;
      this.$store.dispatch('notificationModule/GetNotifications' ,this.GetPage+1 ).then(e=>{
        this.loading = false;
        this.$store.dispatch('notificationModule/UpdateSeen');
        // setTimeout(()=>{
        //   let element = this.$refs.menu_not;
        //   console.log(element);
        //   element.onscroll = ()=>{};
        //   element.addEventListener('scroll' , this.handleScroll);
        // }, 100)
      });
    }
    ,LoadMore(e){
          this.GetNotificationsData();
    },
    GetNotClicked(){
      this.$store.dispatch("notificationModule/ResetNots");
      this.GetNotificationsData();
    },
    async Activate(){
      let token = await getToken(messaging , {
        vapidKey:PUBLIC_VABID_KEY
      })
      if(token){
        localStorage.setItem('fcm_token' , token);
        await this.$store.dispatch('notificationModule/subscribeUser');
        this.StartMessaging();
      }
      
      
    },
    StartMessaging(){
      onMessage(messaging , (message)=>{
          console.log(message);
        if(message.data.title === 'NewMessage'){
          let new_message = JSON.parse(message.data.body);
          this.$store.dispatch('chatModule/SetNewMessage' ,new_message);
        }
      });
    },
    HandleClick(not){
      if(not.webRedirect !== ''){
        if(not.dataId){
          this.$router.push({name : not.webRedirect , params:{
            dataId:not.dataId
            }});
        }else{
          this.$router.push({name:not.webRedirect})
        }
      }
    }
  }
}
</script>

<style scoped>
.not-list{
  min-width: 500px;
  max-width: 600px;
}
@media screen and (max-width:768px) {
  .not-list{
    min-width: auto;
    max-width: 100%;
  }
}
</style>