export const eg_govs = [
    {"number":"1","nameAr":"القاهرة","nameEn":"Cairo"},
    {"number":"2","nameAr":"الجيزة","nameEn":"Giza"},
    {"number":"3","nameAr":"الأسكندرية","nameEn":"Alexandria"},
    {"number":"4","nameAr":"الدقهلية","nameEn":"Dakahlia"},
    {"number":"5","nameAr":"البحر الأحمر","nameEn":"Red Sea"},
    {"number":"6","nameAr":"البحيرة","nameEn":"Beheira"},
    {"number":"7","nameAr":"الفيوم","nameEn":"Fayoum"},
    {"number":"8","nameAr":"الغربية","nameEn":"Gharbiya"},
    {"number":"9","nameAr":"الإسماعلية","nameEn":"Ismailia"},
    {"number":"10","nameAr":"المنوفية","nameEn":"Menofia"},
    {"number":"11","nameAr":"المنيا","nameEn":"Minya"},
    {"number":"12","nameAr":"القليوبية","nameEn":"Qaliubiya"},
    {"number":"13","nameAr":"الوادي الجديد","nameEn":"New Valley"},
    {"number":"14","nameAr":"السويس","nameEn":"Suez"},
    {"number":"15","nameAr":"اسوان","nameEn":"Aswan"},
    {"number":"16","nameAr":"اسيوط","nameEn":"Assiut"},
    {"number":"17","nameAr":"بني سويف","nameEn":"Beni Suef"},
    {"number":"18","nameAr":"بورسعيد","nameEn":"Port Said"},
    {"number":"19","nameAr":"دمياط","nameEn":"Damietta"},
    {"number":"20","nameAr":"الشرقية","nameEn":"Sharkia"},
    {"number":"21","nameAr":"جنوب سيناء","nameEn":"South Sinai"},
    {"number":"22","nameAr":"كفر الشيخ","nameEn":"Kafr Al sheikh"},
    {"number":"23","nameAr":"مطروح","nameEn":"Matrouh"},
    {"number":"24","nameAr":"الأقصر","nameEn":"Luxor"},
    {"number":"25","nameAr":"قنا","nameEn":"Qena"},
    {"number":"26","nameAr":"شمال سيناء","nameEn":"North Sinai"},
    {"number":"27","nameAr":"سوهاج","nameEn":"Sohag"}
]