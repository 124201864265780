<template>
  <v-dialog
      v-model="activeStat"
      max-width="800px"
  >

    <v-card>
      <v-card-title>
        {{ item === null ? $t('AddGovernments') : $t('EditGovernments') }}
      </v-card-title>

      <v-card-text>
        <EditGovernmentForm  :country-id="this.countryId" :item="item" @edited="OnResult">

        </EditGovernmentForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EditGovernmentForm from "@/components/Regions/Governments/EditGovernmentForm";
export default {
  name: "EditGovernmentDialog",
  components: {EditGovernmentForm},
  props:{
    dialog:Boolean,
    item:Object,
    countryId : 0,
    editedIndex : Number
    },data(){
      return {
        activeStat : this.dialog
      }
  }
  ,methods:{
    save(){
      this.$emit('edited' , {item : this.item , index : this.editedIndex})
    }
    ,close(){
      this.activeStat = false;
      this.editedIndex = -1;
      this.$emit('edited' , {item : null , index : this.editedIndex})
    },
    OnResult(res){
      console.log(res)
      this.$emit('edited' , {item : res , index : this.editedIndex})
    }
  },watch:{
    dialog (val) {
      this.activeStat = val;
    },
    activeStat(val){
      this.$emit('edited' , {item : null , index : this.editedIndex})
    }
  },
}
</script>

<style scoped>

</style>