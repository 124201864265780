<template>
  <v-menu
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
  >
      <template v-slot:activator="{ on, attrs }">
        <v-btn   v-bind="attrs"
                 v-on="on" class="px-0" style="min-width: 0" text large>
          <span class="mx-2 avatar-name">
                      {{GetLoginUser.name}}

          </span>
          <v-avatar
              size="30px"

              style="background: #dedede"
              class="avatar-btn"
          >
            <v-img :src="require('../assets/MaleUserAvatar.png')"></v-img>
          </v-avatar>
        </v-btn>




    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
            bottom
            color="success"
            overlap
            offset-x="12"
            offset-y="12"
            class="ms-4"
            dot
        >
          <v-avatar size="40px">
            <v-img :src="require('../assets/MaleUserAvatar.png' )"></v-img>
          </v-avatar>
        </v-badge>
        <div
            class="d-inline-flex flex-column justify-center ms-3"
            style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{GetLoginUser.name}}
          </span>
          <small class="text--disabled text-capitalize">{{ GetLoginUser.rank}}</small>

        </div>
      </div>
      <v-divider class="my-2"></v-divider>
      <!-- Logout -->
      <v-list-item @click="GoToUserProfile">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{icons.mdiAccountOutline}}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('Profile')}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>


      <v-divider class="my-2"></v-divider>
      <LangChanger/>
      <v-divider class="my-2"></v-divider>
      <!-- Logout -->
      <v-list-item @click="Logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('Logout')}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import LangChanger from "@/Layout/LangChanger";

export default {
  components: {LangChanger},
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
  methods:{
    Logout(){
      this.$store.dispatch('authModule/logout').then(res=>{
        window.location.reload();
      })
    },
    GoToUserProfile(){
      this.$router.push({name:'UserProfile'})
    }
  },
  computed:{
    GetLoginUser(){
      return this.$store.getters['authModule/GetLoginUser'];
    },
    GetFirstRole(){
      return this.$store.getters['authModule/GetFirstRole'];
    }
  }
}
</script>

<style scoped>
.user-profile-menu-content .v-list-item{
  min-height: 2.5rem !important;
}
@media screen and (max-width: 900px) {
  .avatar-name{
    display: none;
  }
}

</style>
