<template>
  <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-3"
      :loading="loading"
      :no-data-text="$t('NoData')"
      :no-results-text="$t('NoResult')"
      :loading-text="$t('PleaseWait')"
      :footer-props="footer"
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>{{$t('Countries')}}</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-col
            md="2"
            cols="12"
        >
          <v-text-field
              v-model="search.name"
              append-icon="mdi-magnify"
              :label="$t('EnterName')"
              outlined
              @change="searchFilter"
              dense
              hide-details
          ></v-text-field>

        </v-col>



        <EditCountryDialog @edited="itemEdited" :item="editedItem" v-if="dialog" :dialog="dialog" :edited-index="editedIndex"></EditCountryDialog>
        <DeleteDialog @result="deleteItemConfirm" :action="'regionsModule/deleteCountry'" :item="editedItem" v-if="dialogDelete" :dialog="dialogDelete"></DeleteDialog>
      </v-toolbar>
    </template>

    <template v-slot:item.isActive="{ item }">
      <v-switch
          v-model="item.isActive"
          :value="item.isActive"
          color="success"
          class="mt-0"
          @change="ActivateCountry(item)"
          hide-details
      ></v-switch>
    </template>
    <template v-slot:item.imagePath="{ item }">
      <v-img
          v-if="item.imagePath !== '' && item.imagePath !== null && item.imagePath!==undefined"
          :src="APP_URL+item.imagePath"
          max-width="40px"

          class="ma-2 rounded"

      >

      </v-img>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-menu offset-y v-if="menu_items.some(s=>s.show === true)">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              small

              v-bind="attrs"
              v-on="on"
          >
            <v-icon>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-for="(m_item, index) in menu_items"
              :key="'action_'+index"
              v-if="m_item.show"
              @click="m_item.Click(item)"
          >
            <v-list-item-icon class=""><v-icon small>{{m_item.icon}}</v-icon></v-list-item-icon>

            <v-list-item-title>{{ m_item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </template>
    <template v-slot:no-data>
      <v-btn
          v-if="selected"
          color="primary"
          @click="initialize"
      >
        {{ $t("tryAgain") }}
      </v-btn>
    </template>


  </v-data-table>

</template>


<script>
import AppPagination from "@/components/General/AppPagination";
import {APP_URL} from "@/Helpers/Config";
import EditCountryDialog from "@/components/Regions/Countries/EditCountryDialog";
import DeleteDialog from "@/components/General/DeleteDialog";
export default {
  name: 'CountriesGrid',
  components: {DeleteDialog, EditCountryDialog, AppPagination},
  props:{
    selected : Object
  },
  data: () => ({
    search:{
      name: ''
    },
    ActivateLoading:false,
    dialog: false,

    dialogDelete: false,
    items: [],
    editedIndex: -1,
    editedItem: {

    },

    loading:false,
    APP_URL
  }),
  computed: {
    headers(){
      return  [
        { text: this.$i18n.t("Image"), value: 'imagePath' },
        { text: this.$i18n.t("NameAr"), value: 'nameAr' },
        { text: this.$i18n.t("NameEn"), value: 'nameEn' },
        { text: this.$i18n.t("PhoneCode"), value: 'phoneCode' },
        { text: this.$i18n.t("Status"), value: 'isActive' },
        { text: '', value: 'actions', sortable: false },
      ]
    },
    footer(){
      let lang = localStorage.getItem('lang');
      if(lang === 'ar'){
        return {
          showFirstLastPage: false,
          'page-text':'{0}-{1} من {2}',
          'items-per-page-text':'العدد في الصفحة',

        }
      }else{
        return {
          showFirstLastPage: false,
          'page-text':'{0}-{1} from {2}',
          'items-per-page-text':'page number',

        }
      }
    },
    GetAll(){
      return this.$store.getters['regionsModule/GetAllCountries'];
    },
    HasPermission(per){
      return this.$store.getters['authModule/HasPermission'](per);
    },
    menu_items(){ return [
      {title : this.$i18n.t('Governments') , Click : (item)=>{this.GoToCities(item)} , icon : "mdi-eye", show : true},
      {title : this.$i18n.t('Edit') , Click : (item)=>{this.editItem(item)} ,icon:"mdi-pencil",  show : this.$store.getters['authModule/HasPermission']('RegionControl')},
      {title : this.$i18n.t('Delete') , Click : (item)=>{this.deleteItem(item)} ,icon:"mdi-delete",  show : this.$store.getters['authModule/HasPermission']('RegionControl')},
    ]}
  },
  created() {
    this.initialize();
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },

    GetAll(val){
     console.log(val);
    }
  },

  methods: {
    initialize () {
      this.loading = true;
      this.$store.dispatch('regionsModule/GetAllCountries').then(res=>{
        this.loading = false;
        this.items = this.GetAll;
      });
    },
    editItem (item) {

      this.editedIndex = item  ? this.items.indexOf(item) : -1
      this.editedItem =item ?  Object.assign({}, item):  null;
      this.dialog = true
    },
    deleteItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = item
      this.dialogDelete = true
    },
    deleteItemConfirm (item) {
      console.log(item);
      if(item.activeStat)
        this.items.splice(this.editedIndex, 1);
      this.closeDelete()
    },
    close () {
      this.dialogDelete = false
      this.editedIndex = -1;
    },
    closeDelete () {
      this.dialogDelete = false
    },
    itemEdited(obj){
      console.log(obj);
      this.editedItem = obj.item;
      this.editedIndex = obj.index;
      this.items = [];
      setTimeout(()=>{
        this.searchFilter()
      } , 10);
      this.editedIndex = -1;
      this.dialog = false;
    },

    GoToCities(item){
      this.$router.push({name: 'GovernmentsManagement' , params:{CountryId: item.id}})
    },
    searchFilter(){
      this.items = this.GetAll;
      if(this.search.name !== ''){
        this.items = this.items.filter((value) =>{
          return value.nameAr.includes(this.search.name) || value.nameEn.includes(this.search.name)
        })
      }
    },
    ActivateCountry(item){
      let message = "";
      if(item.currencyId === null || item.currencyId === 0){
        message+= this.$i18n.t('errors.CurrencyNotFound');
        message+= '<br>';
      }
      if(item.phoneCode === "" || item.phoneCode === null || item.phoneCode === undefined){
        message+= this.$i18n.t('errors.PhoneCodeNotFound');
        message+= '<br>';
      }
      if(item.imagePath === "" || item.imagePath === null || item.imagePath === undefined){
        message+= this.$i18n.t('errors.ImageNotFound');
        message+= '<br>';
      }
      if(message!==""){
        this.$toastr.e(message);
        setTimeout(()=>{
          item.isActive = false;
          this.items[this.items.findIndex(it=>it.id === item.id )] = item;
        } , 10)
        this.editItem(item);
      }else{
        this.$store.dispatch('regionsModule/ActivateCountry' ,{
          CountryId : item.id,
          Status : item.isActive
        }).then((res)=>{
          setTimeout(()=>{
            this.searchFilter();
          },10);
          this.$toastr.s(this.$i18n.t("SuccessSaveMessage"));
        });
      }
    }

  },
}
</script>
