<template>
<div>
  <div id="fawry-payments" >

  </div>
  <v-container v-if="loading" class="text-center">
  <v-progress-circular
      indeterminate
      color="primary"
  ></v-progress-circular>
</v-container>

  <v-container v-if="!loading &&GetAllByCountry.length===0" class="text-center pa-5 pb-6">
    <h3 >{{$t('NoPricingPlans')}}</h3>
  </v-container>
  <div v-else class="wrapper pricing-table">

    <PricingPlanSingle v-if="GetAllByCountry.length>0" @Selected="SubscribeTo" v-for="points_package in GetAllByCountry" :points-package="points_package" :key="'plan_'+points_package.id"></PricingPlanSingle>

  </div>
  <v-container v-if="single_loading" class="text-center">
    <v-progress-linear
        indeterminate
        color="purple"
    ></v-progress-linear>
  </v-container>
</div>

</template>

<script>
import PricingPlanSingle from "@/components/Pricing/PricingPlanSingle";
import FawryCheckout from "@/Helpers/Fawry/FawryProduction";
import FawryPaymentHelper from "@/Helpers/Fawry/FawryPaymentHelper";
import moment from "moment";
export default {
  name: "PricingPlansList",
  components: {PricingPlanSingle},
  props:{
    type:String
  },
  data (){
    return {
      loading:false,
      single_loading : false
    }
  },
  computed: {
    GetLoginStatus(){
      return this.$store.getters['authModule/IsLoggedIn'];
    },
    GetAllByCountry(){
      return this.$store.getters['pointsPackageModule/GetAllPointsPackages'];
    },
    IsRequireLoginDone(){
      return this.$store.getters['pointsPackageModule/IsRequireLoginDone'];
    },
    GetSubscribeStat(){
      return this.$store.getters['pointsPackageModule/GetSubscribeStat'];
    },
    GetUser(){
      return this.$store.getters['authModule/GetLoginUser'];
    },
    size() {
      const size = {md: "large", xl: "x-large"}[
          this.$vuetify.breakpoint.name
          ];
      return size ? {[size]: true} : {};
    },

  },
  created() {
    this.initialize();
  },
  watch:{
    IsRequireLoginDone(val){
      if(val){
        setTimeout(()=>{
          this.onlinePayment(this.GetSubscribeStat.item)
        } , 300)
      }
    }
  },
  methods:{
    SubscribeTo(item){
      if(this.type==='online'){
        this.onlinePayment(item);
      }else{
        this.$emit('Selected' , item);
      }
    },
    onlinePayment(item){
      if(!this.GetLoginStatus){
        this.$store.dispatch('pointsPackageModule/OpenLoginDialog' ,
            {loginFirst : true, packageId:item.id , item:item });
      }else{
        //this.$router.push({name:'FawryPay' , params:{item :item}});

          let chargeRequest = FawryPaymentHelper.GetChargeRequest(item , this.GetUser);
          console.log(chargeRequest.merchantRefNum);
          FawryCheckout.checkout(chargeRequest);
          this.$store.dispatch('pointsPackageModule/CompletePayment');

      }
    },


    initialize(){
      this.loading = true;
      return this.$store.dispatch('pointsPackageModule/GetAllPointsPackagesByCountryId').then(res=>{
        this.loading = false;
      }).catch(err=>{
        this.loading = false;
      });
    }
  }
}
</script>

<style>
.responsive-iframe {
  /* position: absolute; */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* height: auto;*/
  min-height: 900px;
  overflow-x: hidden
}


.fawry-display-topright {
  position: absolute;
  left: 0;
  top: 0;
  color: #000;
  z-index: 500;
}

.fawry-button:hover {
  color: #000 !important;
  background-color: #ccc !important;
}


.fawry-container {
  height: 100%;
  min-height: 600px
}

.side-page {
  position: fixed;
  top: 0px;
  right: 0;
  height: 100%;
  background: #fff;
  width: 552px;
  box-shadow: -3px 1px 21px #515151;
  z-index: 800;
}

@media only screen and (max-width: 375px) {
  .side-page {
    width: 100% !important;
  }
}

@media only screen and (max-width: 420px) {
  .side-page {
    width: 100% !important;
  }
}


.fawry-modal {
  z-index: 800;
  display: none;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.fawry-btn,
.fawry-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  font-size: 24px;
  white-space: nowrap;
  margin-right: 2em;
  margin-top: 1em;
}


.fawry-modal-content {
  margin: auto;
  background-color: #fff;
  position: relative;
  padding: 0;
  outline: 0;
  width: 700px;
  box-shadow: 0px 1px 8px #625f5f !important;
  border-radius: 10px !important;
}

@media (min-width: 993px) {
  .fawry-modal-content {
    width: 900px;
  }
}


@media (max-width: 600px) {
  .fawry-modal-content {
    margin: 0 10px;
    width: auto !important;
  }
}

@media (max-width: 993px) {
  .modal_content {
    width: 100% !important;
  }
}


/* --------- description list --------*/
dl[class=row] {
  margin-bottom: 0;
}

[class*=dlist-] {
  margin-bottom: 5px;
}

[class*=dlist-] dd {
  margin-bottom: 0;
}

.dlist-inline dt,
.dlist-inline dd {
  display: inline-block;
}

.dlist-align {
  display: flex;
}

.dlist-align dt {
  width: 150px;
  word-wrap: break-word;
  font-weight: normal;
}

.dlist-align dd {
  flex-grow: 1;
}

/*
@import 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'; */

/* The Modal (background) */
.modal-f {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 9999;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: .3rem;
  width: 60%;
}
.wrapper {
  width: 95%;
  max-width: 1200px;
  margin: 30px auto;
}





.pricing-table {
  border-radius: 3px;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  text-align: center;
}


.pricing-table ul li {
  padding: 3px 0;
  font-size: 0.95em;
}
.pricing-table ul {
  color: #000;
  padding: 15px 60px;
  text-align: left;
}

@media (max-width: 950px) {
  .pricing-table {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .pricing-table > div:nth-child(3) {
    -ms-grid-row: 1;
    grid-row-start: 1;
  }
  .pricing-table > div:nth-child(2) {
    -ms-grid-row: 2;
    grid-row-start: 2;
  }
  .pricing-table > div {
    -ms-flex-item-align: top;
    -ms-grid-row-align: top;
    align-self: top;
  }
}


</style>