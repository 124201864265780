<template>
  <v-dialog
      v-model="activeStat"
      max-width="1000px"
  >

    <v-card>
      <v-card-title>
        {{!item ? $t('AddServicePlace') : $t("EditServicePlace")}}
      </v-card-title>

      <v-card-text>
        <AddServicePlaceForm :item="item" @onResult="OnResult">

        </AddServicePlaceForm>
      </v-card-text>

<!--      <v-card-actions>-->
<!--        <v-spacer></v-spacer>-->
<!--        <v-btn-->
<!--            color="red darken-1"-->
<!--            @click="close"-->
<!--        >-->
<!--          الغاء-->
<!--        </v-btn>-->
<!--        <v-btn-->

<!--            class="primary"-->
<!--            @click="save"-->
<!--        >-->
<!--          {{ $t('Save') }}-->
<!--        </v-btn>-->
<!--      </v-card-actions>-->
    </v-card>
  </v-dialog>
</template>

<script>
import AddServicePlaceForm from "@/components/ServicesPlaces/AddServicePlaceForm";
export default {
  name: "EditServicePlaceDialog",
  components: {AddServicePlaceForm},
  props:{
    dialog:Boolean,
    item:Object,
    editedIndex : Number
    },data(){
      return {
        activeStat : this.dialog
      }
  }
  ,methods:{
    save(){
      this.$emit('edited' , {item : this.item , index : this.editedIndex})
    }
    ,close(){
      this.activeStat = false;
      this.editedIndex = -1;
      this.$emit('edited' , {item : null , index : this.editedIndex})
    },
    OnResult(res){

    }
  },watch:{
    dialog (val) {
      this.activeStat = val;
    },
    activeStat(val){
      this.$emit('edited' , {item : null , index : this.editedIndex})
    }
  },
}
</script>

<style scoped>

</style>