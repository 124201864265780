import { render, staticRenderFns } from "./EgyptSetter.vue?vue&type=template&id=00bb2562&scoped=true&"
import script from "./EgyptSetter.vue?vue&type=script&lang=js&"
export * from "./EgyptSetter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00bb2562",
  null
  
)

export default component.exports