<template>
  <div class="dashboard-container">
    <v-container v-if="loading" className="text-center">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "PaymentResult",
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    console.log(this.$route.query);
    if (this.$route.query.statusCode !== '200') {
      if (this.$route.query.statusCode === '499') {
        this.$toastr.w(this.$route.query.statusDescription);
      } else {
        this.$toastr.e(this.$route.query.statusDescription);
      }
      this.$router.replace({name: 'Home'});
    } else {
      this.loading = true;
      this.$store.dispatch('pointsPackageModule/SendPaymentRequest', this.$route.query).then(res => {
        this.loading = false;
        this.$toastr.s(this.$route.query.statusDescription);
        this.$router.push({name: 'AccountStatement', params: {type: 'ShowPaymentRequests'}});
      });
    }
  }
}
</script>

<style scoped>

</style>