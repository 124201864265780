<template>
  <v-col class="col-12 col-sm-6 col-md-4">
    <div class="flex-center">
      <v-card-text>

        <div class="text--disabled text-uppercase text-h5 my-2">{{dataObj.name}}</div>
        <v-divider class="my-2"/>
        <div class="text-uppercase blue--text">{{`${dataObj.points} ${$t('point')}` }}</div>
        <v-divider class="my-2"/>

        <div class="text-uppercase text-h5   blue--text">{{`${dataObj.balance} ${dataObj.currency.prefix}`}}</div>

        <v-divider class="my-2"/>
        <div class="text-uppercase  blue--text">{{`${dataObj.monthsNumber} ${$t('Month')}` }}</div>

        <v-btn
            rounded
            outlined
            @click="GoToSubscribe"

            color="blue"
            class="mt-6"
        >
         {{$t('Subscribe')}}
        </v-btn>
      </v-card-text>

    </div>
  </v-col>
</template>

<script>
export default {
  name: "PricingPlan",
  props:{
    PointsPackage:Object,
  }
  ,data(){
    return {
      dataObj:this.PointsPackage
    }
  },
  methods:{
    GoToSubscribe(){
      this.$emit('Selected' , this.dataObj);
    }
  }
}
</script>

<style scoped>

</style>