<template>
  <div>

    <AddUserFrom :service-place-id="ServicePlaceId" :branch-id="BranchId" @userSaved="userSaved" :rank="this.rank" v-if="!user"></AddUserFrom>
    <UserHeader v-if="user" :user="user">
    </UserHeader>
    <UserPermissions @returnToUsers="returnToUsers" v-if="user" :user="user">
    </UserPermissions>
  </div>
</template>

<script>
import AddUserFrom from "@/components/Users/AddUserFrom";
import UserPermissions from "@/components/Roles/UserPermissions";
import UserHeader from "@/components/Users/UserHeader";
export default {
  name: "AddNewUserForm",
  components : {
    UserHeader,
    AddUserFrom ,UserPermissions
  }
  , props : {
    rank  : Object,
    UserObj : Object,
    ServicePlaceId: null,
    BranchId: null,
  },
  data(){
    return {
      ServicePlace : null,
      user : this.UserObj
    }
  },

  methods:{

    userSaved(user){
      if(this.$store.getters['authModule/HasPermission']('EditUserRoles')){
        this.user = user;
        this.user.rank = this.rank.name
      }
      this.$emit('Saved' , user);

    },
    returnToUsers(){

      this.$emit("returnToUsers");
    },

  }
}
</script>

<style scoped>

</style>