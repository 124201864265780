<template>
  <div class="container--fluid pa-8 dashboard-container" >
    <v-row >
      <v-col
          md="6"
          sm="8"
      >
        <RanksDropDown :removeList="['ServiceSupplier' , 'BranchManager']" @Selected="SetSelectedRank"></RanksDropDown>
      </v-col>
      <v-col
          md="6"
          sm="4"
      >
        <v-btn
            class="primary ma-1 float-left"
            v-if="$store.getters['authModule/HasPermission']('AddUser')"
            :disabled="addUserBtn.disabled"
            :loading="addUserBtn.loading"

            @click="AddUser"
        >
          {{$t('AddUser')}}
        </v-btn>
      </v-col>
    </v-row>

    <UsersGrid :rank="'User'" @editUserRoles="editUserRoles" :selected="this.selectedRank" v-if="!addUserBtn.isActive && !user"></UsersGrid>
    <AddNewUserForm @returnToUsers="returnToUsers" :rank="selectedRank"  :user-obj="user" v-if="addUserBtn.isActive || user"></AddNewUserForm>
  </div>

</template>

<script>
import RanksDropDown from "../../../components/Roles/RanksDropDown";
import UsersGrid from "../../../components/Users/UsersGrid";
import AddNewUserForm from "@/components/Users/AddNewUserForm";
export default {
  name: "Users",
  components: {AddNewUserForm, RanksDropDown , UsersGrid},
  data(){
    return {
      selectedRank : null,
      addUserBtn :{
        loading : false ,
        disabled : true,
        isActive : false
      },
      user : null
    }
  },
  mounted() {

  },
  methods:{
    SetSelectedRank(rank){
      this.selectedRank = rank;
      this.addUserBtn.disabled = false;
      this.addUserBtn.isActive =false;
      this.user = null;

    },
    AddUser(){
      this.addUserBtn.isActive= true ;
      this.addUserBtn.disabled =true;
    },
    returnToUsers(){
      this.user = null;
      this.addUserBtn.isActive = false;
    },
    editUserRoles(user){
      this.user = user;
    }
  }
}
</script>

<style>

</style>