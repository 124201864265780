<template>
<div id="fawry-payments" >

</div>
</template>

<script>
import '@/Helpers/Fawry/FawryStaging';
import FawryStaging from "@/Helpers/Fawry/FawryStaging";
import {sha256} from "js-sha256";

export default {
  name: "FawryPay",
  props:{
    item:Object,
  },
  mounted() {
    if(this.item){

      FawryStaging.checkout(this.item, this.buildChargeRequest())
    }
  },
  computed: {

    GetUser(){
      return this.$store.getters['authModule/GetLoginUser'];
    },


  },
  methods:{
    buildChargeRequest() {
      console.log(window.location.path+'/Payment/Result');
      let chargeRequest =   {
        merchantCode: 'siYxylRjSPx/cYHbM0uEvw==',
        merchantRefNum: 'lakoffers2338404444',
        customerMobile:this.GetUser.phone ,
        customerEmail: this.GetUser.email,
        customerName: this.GetUser.name,
        customerProfileId:'lakoffersprofile_'+this.GetUser.id,
        chargeItems: [
          {
            itemId: 'pkg_lak_offers_'+this.item.id,
            description: this.item.name,
            price:parseInt(this.item.balance)+'.00',
            quantity: 1,
          },

        ],
        returnUrl:window.location.origin+'/Payment/Result',
        authCaptureModePayment: false,
        signature: ""
      };
      chargeRequest.signature = this.GetSignature(chargeRequest);
      console.log(JSON.stringify(chargeRequest));
      return chargeRequest;
    },
    GetSignature(chargeRequest) {

      let item = chargeRequest.chargeItems[0];
      let signText =
          chargeRequest.merchantCode
          + chargeRequest.merchantRefNum
          + chargeRequest.customerProfileId + chargeRequest.returnUrl
          + item.itemId
          + item.quantity
          + item.price
          + "5bda5238-85ff-43cc-9f72-7f826b61b5e5";
      console.log( signText);

      return sha256(signText);
    }

  }
}
</script>

<style scoped>
body {
  overflow-x: hidden
}

.responsive-iframe {
  /* position: absolute; */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* height: auto;*/
  min-height: 900px;
  overflow-x: hidden
}


.fawry-display-topright {
  position: absolute;
  left: 0;
  top: 0;
  color: #000;
  z-index: 500;
}

.fawry-button:hover {
  color: #000 !important;
  background-color: #ccc !important;
}


.fawry-container {
  height: 100%;
  min-height: 600px
}

.side-page {
  position: fixed;
  top: 0px;
  right: 0;
  height: 100%;
  background: #fff;
  width: 552px;
  box-shadow: -3px 1px 21px #515151;
  z-index: 800;
}

@media only screen and (max-width: 375px) {
  .side-page {
    width: 100% !important;
  }
}

@media only screen and (max-width: 420px) {
  .side-page {
    width: 100% !important;
  }
}


.fawry-modal {
  z-index: 800;
  display: none;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.fawry-btn,
.fawry-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  font-size: 24px;
  white-space: nowrap;
  margin-right: 2em;
  margin-top: 1em;
}


.fawry-modal-content {
  margin: auto;
  background-color: #fff;
  position: relative;
  padding: 0;
  outline: 0;
  width: 700px;
  box-shadow: 0px 1px 8px #625f5f !important;
  border-radius: 10px !important;
}

@media (min-width: 993px) {
  .fawry-modal-content {
    width: 900px;
  }
}


@media (max-width: 600px) {
  .fawry-modal-content {
    margin: 0 10px;
    width: auto !important;
  }
}

@media (max-width: 993px) {
  .modal_content {
    width: 100% !important;
  }
}


/* --------- description list --------*/
dl[class=row] {
  margin-bottom: 0;
}

[class*=dlist-] {
  margin-bottom: 5px;
}

[class*=dlist-] dd {
  margin-bottom: 0;
}

.dlist-inline dt,
.dlist-inline dd {
  display: inline-block;
}

.dlist-align {
  display: flex;
}

.dlist-align dt {
  width: 150px;
  word-wrap: break-word;
  font-weight: normal;
}

.dlist-align dd {
  flex-grow: 1;
}

/*
@import 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'; */

/* The Modal (background) */
.modal-f {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 9999;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: .3rem;
  width: 60%;
}
</style>