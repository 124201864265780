<template>
  <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-3"
      :loading="loading"

      :no-data-text="$t('NoData')"
      :no-results-text="$t('NoResult')"
      :loading-text="$t('PleaseWait')"
      :footer-props="footer"
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>{{$t('Categories')}}</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-col
            md="2"
            cols="12"
        >
          <v-text-field
              v-model="search.name"
              append-icon="mdi-magnify"
              :label="$t('EnterName')"
              outlined
              dense
              hide-details
          ></v-text-field>

        </v-col>
        <v-col cols="12" md="8" >

          <v-btn class="warning float-left pa-2 ma-2" small>
           {{$t('Search')}}
          </v-btn>
          <v-btn @click="editItem(null)" class="primary float-left pa-2 ma-2" small>
            {{$t('Add')}}
          </v-btn>

        </v-col>

        <CategoryServices @Close="CloseServicesModal" v-if="editedItem && servicesDialog" :dialog="servicesDialog" :category-id="editedItem.id"></CategoryServices>
        <EditCategoryDialog @edited="itemEdited" :item="editedItem" v-if="dialog" :dialog="dialog" :edited-index="editedIndex"></EditCategoryDialog>
        <DeleteDialog @result="deleteItemConfirm" :action="'categoriesModule/deleteCategory'" :item="editedItem" v-if="dialogDelete" :dialog="dialogDelete"></DeleteDialog>
      </v-toolbar>
    </template>
    <template v-slot:item.imgePath="{ item }">
      <v-img
          v-if="item.imgePath"
          :src="APP_URL+item.imgePath"
          max-width="80px"
          class="ma-2 rounded"

      >

      </v-img>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-menu offset-y v-if="menu_items.some(s=>s.show === true)">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              small

              v-bind="attrs"
              v-on="on"
          >
            <v-icon>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-for="(m_item, index) in menu_items"
              :key="'action_'+index"
              v-if="m_item.show"
              @click="m_item.Click(item)"
          >
            <v-list-item-icon class=""><v-icon small>{{m_item.icon}}</v-icon></v-list-item-icon>

            <v-list-item-title>{{ m_item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </template>
    <template v-slot:no-data>
      <v-btn
          v-if="selected"
          color="primary"
          @click="initialize"
      >
        {{ $t("tryAgain") }}
      </v-btn>
    </template>


  </v-data-table>

</template>


<script>
import AppPagination from "@/components/General/AppPagination";
import {APP_URL} from "@/Helpers/Config";
import EditCategoryDialog from "@/components/Categories/EditCategoryDialog";
import DeleteDialog from "@/components/General/DeleteDialog";
import CategoryServices from "@/components/Categories/CategoryServices";
export default {
  name: 'CategoriesGrid',
  components: {CategoryServices, DeleteDialog, EditCategoryDialog, AppPagination},
  props:{
    selected : Object
  },
  data: () => ({
    search:{
      name: ''
    },
    servicesDialog:false,
    dialog: false,

    dialogDelete: false,
    items: [],
    editedIndex: -1,
    editedItem: {

    },

    loading:false,
    APP_URL
  }),
  computed: {
    headers(){
      return  [
        { text: this.$i18n.t("Image"), value: 'imgePath' },
        { text: this.$i18n.t("NameAr"), value: 'nameAr' , align:'start'},
        { text: this.$i18n.t("NameEn"), value: 'nameEn' , align:'start'},
        { text: '', value: 'actions', sortable: false },
      ]
    },
    footer(){
      let lang = localStorage.getItem('lang');
      if(lang === 'ar'){
        return {
          showFirstLastPage: false,
          'page-text':'{0}-{1} من {2}',
          'items-per-page-text':'العدد في الصفحة',

        }
      }else{
        return {
          showFirstLastPage: false,
          'page-text':'{0}-{1} from {2}',
          'items-per-page-text':'page number',

        }
      }
    },
    GetAll(){
      return this.$store.getters['categoriesModule/GetAllCategories'];
    },
    HasPermission(per){
      return this.$store.getters['authModule/HasPermission'](per);
    },
    menu_items(){ return [
      {title : this.$i18n.t('Edit') , Click : (item)=>{this.editItem(item)} , icon : "mdi-pencil", show : this.$store.getters['authModule/HasPermission']('EditCategory')},
      {title : this.$i18n.t('CategoryServices') , Click : (item)=>{this.CatServices(item)} , icon : "mdi-apps", show : this.$store.getters['authModule/HasPermission']('EditCategory')},
      {title : this.$i18n.t('Delete') , Click : (item)=>{this.deleteItem(item)} ,icon:"mdi-delete",  show : this.$store.getters['authModule/HasPermission']('DeleteCategory')},
    ]}
  },
  created() {
    this.initialize();
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    selected(val){
      if(val!==null){
        this.initialize()

      }
    },
    GetAll(val){
     console.log(val);
    }
  },

  methods: {
    CloseServicesModal(){
      this.editedItem = null;
      this.servicesDialog= false;
    },
    initialize () {
      this.items = this.GetAll
    },
    CatServices(item){
      this.servicesDialog = true;
      this.editedItem = item;
    },
    editItem (item) {

      this.editedIndex = item  ? this.items.indexOf(item) : -1
      this.editedItem =item ?  Object.assign({}, item):  null;
      this.dialog = true
    },
    deleteItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = item
      this.dialogDelete = true
    },
    deleteItemConfirm (item) {
      console.log(item);
      if(item.activeStat)
        this.items.splice(this.editedIndex, 1);
      this.closeDelete()
    },
    close () {
      this.dialogDelete = false
      this.editedIndex = -1;
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    itemEdited(obj){
      console.log(obj);
      this.editedItem = obj.item;
      this.editedIndex = obj.index;
      this.items = [];
      setTimeout(()=>{
        this.items = this.GetAll;
      } , 10);
      this.editedIndex = -1;
      this.dialog = false;
    },
    updated(){
      this.items = this.GetAll();
    },
    selectItem(item){
      this.$emit("itemSelected" , item);
    }

  },
}
</script>
