<template>
  <v-select
      :items="GetAllRanks"
      :label="$t('SelectRank')"
      @change="ChangeDropDown"
      outlined
      dense
      class="dropdown-list"
      v-model="selected"
  ></v-select>
</template>

<script>
export default {
  name: "RanksDropDown" ,
  props:{
    removeList:Array,
  },
  data(){
    return {
      selected : {name:'' , id :0},
    }
  },
  computed:{
    GetAllRanks(){
      return this.$store.getters['rankRolesModule/GetAllRanks'].map((el , index)=>{
        return {
          text : el.name ,
          value: {
            name : el.name,
            index : index,
            id : el.id,
            baseName : el.value
          }
        }
      }).filter(el =>!this.removeList.includes(el.value.baseName));
    }
  },
  methods:{
    ChangeDropDown(item){
      this.$emit('Selected' , item);
    }
  }
}
</script>

<style scoped>

</style>