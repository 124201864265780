
import axios_instance  from "./ApiService";


class PageSectionService {
    controllerName = '/PageSection';
//GetMainPageSection
    GetSectionsByPage(Page) {
        return axios_instance.get(this.controllerName + '/GetByPage' , {params:{Page:Page}}).then(response =>{

            return Promise.resolve(response.data);
        });
    }

    GetMainPageSection(Page) {
        return axios_instance.get(this.controllerName + '/GetMainPageSection' , {params:{Page:Page}}).then(response =>{
            return Promise.resolve(response.data);
        });
    }


    SaveSection(payload) {
        return axios_instance.post(this.controllerName+'/SavePageSection' , payload ).then(res=>{
            return Promise.resolve(res.data);
        })
    }

    UploadImage(file) {
        let formDate = new FormData();
        formDate.append('image' , file);
        delete axios_instance.defaults.headers.common['content-type'];
        return axios_instance.post(this.controllerName + '/UploadImage' , formDate , {
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        }).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    DeletePageSection(Id) {
        return axios_instance.delete(this.controllerName + '/DeletePageSection' , {params:{Id:Id}}).then(response =>{
            return Promise.resolve(response.data);
        });
    }
}
export default new PageSectionService();