<template>
  <div class="dashboard-container">
    <v-container>
      <v-row>
        <v-col md="2" >
          <v-card height="150" class="cursor-pointer" link :to="{name : 'AdminReports.PeriodToPeriodUsageReport'}">
            <v-card-title class="text-center">
              <v-icon x-large>
                mdi-chart-bar-stacked
              </v-icon>
            </v-card-title>
            <v-card-text>
              {{$t('reports.PeriodToPeriodUsageReport')}}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="2">
          <v-card height="150" class="cursor-pointer" link :to="{name : 'AdminReports.PeriodToPeriodClientsSalesReport'}">
            <v-card-title class="text-center">
              <v-icon x-large>
                mdi-chart-bar-stacked
              </v-icon>
            </v-card-title>
            <v-card-text>
              {{$t('reports.PeriodToPeriodClientsSalesReport')}}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>


  </div>
</template>

<script>
import UsageGrid from "../../../components/Reports/UsageGrid";
export default {
  name: "Reports",
  components: {UsageGrid}
}
</script>

<style scoped>

</style>