<template>
  <v-row>
    <v-col cols="12" md="3" sm="4">
      <PhoneCodesDropDown :dense="dense" :item="item" @Selected="PhoneCodeSelected"></PhoneCodesDropDown>

    </v-col>
    <v-col cols="12" md="9" sm="8">
      <v-text-field
          :ref="'phone'"
          :disabled="code===''"
          :error-messages="errorMessages"
          type="text"
          :dense="dense"
          autocomplete="phone"
          filled
          :rules="[() => !!phoneNumber || $t('errors.requiredField')]"
          :label="$t('Phone')"
          v-model="phoneNumber"
          @change='Changed'
      >

      </v-text-field>
    </v-col>


  </v-row>
</template>

<script>
import PhoneCodesDropDown from "@/components/General/PhoneCodesDropDown";

export default {
  name: "PhoneInput",
  props:{
    phone : '',
    rules : [],
    item: Object,
    dense:false
  },
  data(){
    return {
      errorMessages :''
      ,phoneNumber : '',
      code : ''
    }
  },
  mounted() {

  },
  watch:{
    phone(val){
      this.phoneNumber = this.phone;
    }
  },
  components:{
    PhoneCodesDropDown
  },methods:{
    PhoneCodeSelected(item) {
      debugger;
      if(item)this.code = item.code;
      this.Changed();
    },
    Changed(){
      this.$emit('Changed' , {
        phone : this.phoneNumber ,
        phoneCode:this.code
      })
    }
  }
}
</script>

<style scoped>

</style>