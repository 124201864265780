import i18n from "@/plugins/i18n/i18n";
export const rules =  {
    required: value => !!value || i18n.t('errors.requiredField'),
    min: v => (v.length >= 8 )|| i18n.t('errors.Password'),
    symbol: v=> /[!@#\$%\^\&*\)\(+=._-]/.test(v) || i18n.t('errors.symbol'),
    lower: v=> /[a-z]/.test(v) || i18n.t('errors.lower'),
    upper: v=> /[A-Z]/.test(v) || i18n.t('errors.upper'),
    number: v=> /\d/.test(v) || i18n.t('errors.passNumber'),
    numeric: v=> /^\d+$/.test(v) || i18n.t('errors.number'),
    float: v=> /^\d+\.\d+$/.test(v) || i18n.t('errors.number'),
}