
import axios_instance  from "./ApiService";
class ChatService {
    controllerName = '/Chatting'
    GetChats(obj){
        return axios_instance.post(`${this.controllerName}/GetChats` , obj ).then(res=>{
            return Promise.resolve(res.data);
        })
    }

    GetMessages(obj) {
        return axios_instance.get(`${this.controllerName}/GetChatMessages`  , {params: {
            chatId : obj.chatId ,
             pageId: obj.page,
            }
            } ).then(res=>{
            return Promise.resolve(res.data);
        })
    }
    
    SendMessage (message){
        return axios_instance.post(`${this.controllerName}/SendMessage` ,message ).then(res=>{
            return Promise.resolve(res.data);
        })   
    }

    ProcessReport (reportId){
        return axios_instance.post(`${this.controllerName}/ProcessReport` ,null ,{
            params : {ReportId : reportId}
        } ).then(res=>{
            return Promise.resolve(res.data);
        })
    }


    GetUserReports() {
        return axios_instance.get(`${this.controllerName}/GetUserReports` ).then(res=>{
            return Promise.resolve(res.data);
        })
    }

    SendGuestComplaint(payload) {
        return axios_instance.post(`${this.controllerName}/SendGuestComplaint` ,payload ).then(res=>{
            return Promise.resolve(res.data);
        });
    }

    GetComplainedChats(obj) {
        return axios_instance.post(`${this.controllerName}/GetComplainedChats` , obj ).then(res=>{
            return Promise.resolve(res.data);
        })
    }

    CloseConversation(chatId) {
        return axios_instance.post(`${this.controllerName}/CloseConversion` , null , {
           params:{ chatId :chatId}
        }).then(res=>{
            return Promise.resolve(res.data);
        });
    }

    SendContactMail(ContactEmail) {
        return axios_instance.post(`${this.controllerName}/SendContactMail` , ContactEmail ).then(res=>{
            return Promise.resolve(res.data);
        });
    }
    GetChatBetween(userId) {
        return axios_instance.get(`${this.controllerName}/GetChatBetween` , {
            params:{
                UserId:userId
            }
        } ).then(res=>{
            return Promise.resolve(res.data);
        });
    }
}
export default new ChatService();