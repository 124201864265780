import axios_instance from "./ApiService";
class RegionsService {
    controllerName = "/Regions";
    GetAllRegionsByGovernmentId(id){
        return axios_instance.get(this.controllerName+"/GetAllAirportsByGavernmentId" ,
            {params:{GovernmentId : id}}).then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.resolve(err);
        })
    }
    GetAllCountries(){
        return axios_instance.get(this.controllerName+"/GetAllCountries").then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.resolve(err);
        })
    }
    GetAllActivatedCountries(){
        return axios_instance.get(this.controllerName+"/GetAllActivatedCountries").then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.resolve(err);
        })
    }

    ActivateCountry(payload) {
        return axios_instance.post(this.controllerName+"/ActivateCountry" , {},{params:{CountryId : payload.CountryId , status : payload.Status}}).then(res=>{
            return Promise.resolve(res);
        });
    }


    SaveCountry(payload) {
        let form = new FormData();
        form.append('NameAr' , payload.nameAr);
        form.append('NameEn' , payload.nameEn);
        if (payload.CategoryImage !== null)
            form.append('FlagImage' , payload.flagImage);
        form.append('Id' , payload.id);
        form.append('PhoneCode' , payload.phoneCode);
        form.append('CurrencyId' , payload.currencyId?payload.currencyId:'');
        console.log(payload)
        delete axios_instance.defaults.headers.common['content-type'];
        return axios_instance.post(this.controllerName + '/SaveCountry' , form , {
            headers:{
                'content-type' : 'multipart/form-data'
            }
        }).then(response =>{
            return Promise.resolve(response.data);
        });
    }
    SaveGovernment(payload) {
        return axios_instance.post(this.controllerName+"/SaveGavernment" , payload
        ).then(res=>{
            return Promise.resolve(res.data);
        });
    }


    GetGovernment(id) {
        return axios_instance.get(this.controllerName+"/GetGovernment" , {
            params : {
                id : id
            }
        }).then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.resolve(err);
        })
    }

    GetCountry(id) {
        return axios_instance.get(this.controllerName+"/GetCountry" , {
            params : {
                id : id
            }
        }).then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.resolve(err);
        })
    }

    GetCountryByCode(code) {
        return axios_instance.get(this.controllerName+"/GetCountryByCode" , {
            params : {
                code : code
            }
        }).then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.resolve(err);
        })
    }

    GetGovernmentsByCountryId(id) {
        return axios_instance.get(this.controllerName+"/GetAllGovernmentsByCountryId" , {
            params : {
                CountryId : id
            }
        }).then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.resolve(err);
        })
    }

    deleteGovernment(id) {
        return axios_instance.delete(this.controllerName+"/DeleteGovernment" , {params : {GovernmentId : id}}).then(res=>{
            return Promise.resolve(res);
        });
    }

    deleteCountry(id) {
        return axios_instance.delete(this.controllerName+"/DeleteCountry" , {params : {CountryId : id}}).then(res=>{

            return Promise.resolve(res);
        });
    }

    SaveCountryDetails(payload) {
        return axios_instance.post(this.controllerName+"/SaveCountryDetails" , payload
        ).then(res=>{
            return Promise.resolve(res.data);
        });
    }

    SaveAirport(payload) {
        return axios_instance.post(this.controllerName+"/SaveAirport" , payload
        ).then(res=>{
            return Promise.resolve(res.data);
        });
    }

    DeleteAirport(id) {
        return axios_instance.delete(this.controllerName+"/DeleteAirport" , {params : {AirportId : id}}).then(res=>{
            return Promise.resolve(res);
        });
    }
}

export default new RegionsService();