import SalesClaimService from "@/Services/SalesClaimService";
import ServicesPlacesService from "@/Services/ServicesPlacesService";
import salesClaimService from "@/Services/SalesClaimService";

export const salesClaimModule = {
    namespaced : true,
    state:{
        notClaimed :[],
    },actions:{
        GetAllProfitSlices({commit} , obj){
            return SalesClaimService.GetAllProfitSlices(obj).then(res =>{
                return Promise.resolve(res);
            });
        },
        GetAllNotClaimsUsersByType({commit} , obj){
            return SalesClaimService.GetAllNotClaimsUsersByType(obj).then(res =>{
                return Promise.resolve(res);
            });
        },
        SaveSlice({commit} , obj){
            return SalesClaimService.SaveProfitSlice(obj).then(res =>{
                return Promise.resolve(res);
            });
        },
        DeleteSlice({commit} , id){
          return SalesClaimService.DeleteSlice(id).then(r=>{
              return Promise.resolve(r);
          })
        },
        ClaimUser({commit} , obj){
            return SalesClaimService.ClaimUser(obj).then(res =>{
                return Promise.resolve(res);
            });
        },
        GetUserStat({commit}){
            return SalesClaimService.GetUserStat();
        },
        GetUserStatById({commit} , userId){
            return SalesClaimService.GetUserStatByUserId(userId);
        }
        ,GetUserStatDetail({commit} , payload){
            return salesClaimService.GetUserStatDetail(payload);
        }
    }
    ,mutations: {



    },getters:{

    }

}