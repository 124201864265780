import axios_instance from "./ApiService";
class RankRolesService {
    controllerName = "/RankRoles";
    GetRankRolesGrid(){
        return axios_instance.get("/RankRoles/GetRankRolesGrid").then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.resolve(err);
        })
    }
    GetRanks(){
        return axios_instance.get("/RankRoles/GetRanks").then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.resolve(err);
        })
    }

    SaveRankRoles(RankRoles) {
        return axios_instance.post("/RankRoles/SaveMultipleRankRole" , RankRoles).then(res=>{
            console.log(res);
            return Promise.resolve(res);
        });
    }
    GetAllRolesByUser(userId){
        return axios_instance.get("/RankRoles/GetAllRolesByUser" , {params:{userId : userId}}).then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.resolve(err);
        })
    }

    SaveUserRoles(payload) {
        return axios_instance.post("/RankRoles/SaveUserRoles" , payload

            ).then(res=>{
            console.log(res);
            return Promise.resolve(res);
        });
    }
}

export default new RankRolesService();