<template>
<v-row>
  <v-col v-if="loading" cols="12">
    <v-container  class="text-center">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-container>
  </v-col>
  <v-col v-else cols="12">
    <v-row>
      <v-col class="custom-text-left" cols="12" lg="12" md="12" sm="12">
<!--        <v-icon large style="cursor:pointer" color="primary" @click="OpenChooser">-->
<!--          mdi-image-plus-->
<!--        </v-icon>-->
        <v-file-input  :loading="file_loading"  ref="file_chooser"  prepend-icon="mdi-image-plus" hide-input v-model="file" @change="AddNewImage">
          {{$t("Add")}}
        </v-file-input>

      </v-col>
      <v-col :style="image.isMain === true ?'background:#217c2191' :''" v-for="image in images" cols="12" md="4" lg="4" sm="12" >
        <v-img  :key="`image_${image.id}`"  :src="`${APP_URL}${image.path}`" height="400"  max-width="400">
          <v-btn v-if="$store.getters['authModule/HasPermission']('EditBranch')" icon :loading="deleteLoading && selected.id === image.id"  @click="DeleteImage(image)">
            <v-icon  color="red" style="z-index: 1000 ;  cursor: pointer;">
              mdi-delete
            </v-icon>
         </v-btn>

        </v-img>
      </v-col>
    </v-row>
  </v-col>

</v-row>
</template>

<script>
import {APP_URL} from "@/Helpers/Config";
export default {
  name: "BranchImages",
  data(){
    return{
      APP_URL,
      loading:false,
      images : [],
      file : null,
      file_loading:false,
      selected:null,
      deleteLoading:false,
    }
  },
  props:{
    BranchId:Number
  },
  mounted() {
      this.Init();
  },

  methods:{


    Init(){
      if(this.BranchId){
        this.loading=true;
        this.$store.dispatch('servicesPlacesModule/GetBranchImagesByBranchId' , this.BranchId).then(res=>{
          debugger;
          this.loading = false;
          this.images = res;
        });
      }
    },
    AddNewImage(){
      let toSave = {
        isBranch :true,
        TargetId :this.BranchId,
        Image : this.file
      }
      this.loading = true;
      this.$store.dispatch('servicesPlacesModule/SaveBranchImage' , toSave).then(res=>{
        this.Init();

      });
    },
    DeleteImage(image){
      this.selected = image;
      this.deleteLoading = true;
      this.$store.dispatch('servicesPlacesModule/deleteBranchImage' , {BranchId :this.BranchId , id : image.id , isMain : image.isMain}).then(res=>{
        this.deleteLoading = false;
        this.images.splice(this.images.findIndex(s=>{return s.id === image.id}) , 1);
        let first = this.images[0];
        if(first)
          first.isMain= true;
        this.selected= false;
      });
    }
  }
}
</script>

<style scoped>

</style>