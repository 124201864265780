import * as VueGoogleMaps from 'vue2-google-maps';
import Vue from 'vue';
import {GOOGLE_MAP_API_KEY} from '@/Helpers/Config'
Vue.use(VueGoogleMaps, {
    load: {
      key: GOOGLE_MAP_API_KEY,
      libraries: "places",
      language:localStorage.getItem('lang') ==='en'?"en":"ar"
    }
  });
  export default VueGoogleMaps;