import ReportsService from "@/Services/ReportsService";

export const reportsModule = {
    namespaced : true,
    state:{
        dataSource :{},
    },actions:{
        GetUsageBetweenTowPeriods({commit} , payload){
            return ReportsService.GetUsageBetweenTowPeriods(payload).then(res=>{
                commit("setDataSource" , res);
                return Promise.resolve("done");
            }).catch(err=>{
                return Promise.reject("fail");
            });


        },
    }
    ,mutations: {
        setDataSource(state , res){
            state.dataSource = res;
        }


    },getters:{
        GetDataSource : (state)=>{
            return state.dataSource;
        }
    }

}