<template>
  <v-dialog
      content-class="overflow-hidden elevation-0 login-dialog"
      v-model="activeStat"
      fullscreen
      overlay-color="black"
      scrollable

  >
    <LoginSection @GoToRegister="RegisterEvent" @Close="close" @OnResult="OnResult"  v-if="dialogType==='login'"></LoginSection>
    <RegisterSection @GoToLogin="LoginEvent" @ServicePlace="GoToServicePlaceRegister"  @Close="close" @OnResult="OnResult"  v-if="dialogType==='register'"></RegisterSection>
  </v-dialog>
</template>

<script>
import LoginSection from "@/Layout/Login";
import RegisterSection from "@/Layout/RegisterSection";
import ServicePlaceRegisterForm from "@/components/ServicesPlaces/ServicePlaceRegisterForm";
export default {
  name: "LoginRegisterDialog",
  components: {ServicePlaceRegisterForm, LoginSection , RegisterSection},
  props:{
    dialog:Boolean,
    type:String,
    },data(){
      return {
        activeStat : this.dialog,
        dialogType : this.type
      }
  }
  ,methods:{
    close(){
      this.activeStat = false;
      this.$emit('OnResult')
    },
    OnResult(res){
      console.log(res)
      this.$emit('OnResult')
      this.activeStat= false;
    },GoToServicePlaceRegister(){
      this.activeStat = false;
      this.$emit('OnResult')
      this.$router.push({name:"ServicePlaceRegister"});
    },
    RegisterEvent(){
      this.dialogType='register';
    },
    LoginEvent(){
      this.dialogType='login';
    }
  },watch:{
    dialog (val) {
      this.activeStat = val;
    },
    activeStat(val){
      this.$emit('OnResult')
    },

  },
}
</script>

<style>
.login-dialog{
  box-shadow: none !important;
  overflow: hidden !important;
}
</style>