import axios_instance from "./ApiService";
import UserNotificationTopics from "@/Helpers/UserNotificationTopics";
import AppHelper from "@/Helpers/AppHelper";
class NotificationService {
    controllerName  = '/Notifications';
    GetNotifications(page){
        return axios_instance.post(this.controllerName+'/GetAllNotifications' , {
            id : 0,
            isServicePlace :false,
            Page:page
        }).then(res=>{
            return Promise.resolve(res.data);
        });
    }

    GetUserTopics() {
        return UserNotificationTopics();
    }

    async SubscribeToUserTopics() {
        debugger;
        let topics = this.GetUserTopics();
        let fcm_token = localStorage.getItem('fcm_token');
        if(topics.length>0){
            for (const topic of topics) {
                await AppHelper.subscribeTokenToTopic(fcm_token , topic);
            }
        }
    }

    GetNotificationsCount() {
        return axios_instance.get(this.controllerName+'/GetAllNotificationsCount').then(res=>{
            return Promise.resolve(res.data);
        });
    }

    UpdateSeen() {
        return axios_instance.post(this.controllerName+'/UpdateSeen' ).then(res=>{
            return Promise.resolve(res.data);
        });
    }
}

export default new NotificationService();