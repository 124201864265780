
import axios_instance  from "./ApiService";
class SalesClaimService {
    controllerName = '/SalesClaims';

    GetAllNotClaimedUsers() {
        return axios_instance.get(this.controllerName + '/GetByPage' , {params:{Page:Page}}).then(response =>{

            return Promise.resolve(response.data);
        });
    }
    GetUserStatByUserId(UserId){
        return axios_instance.get(this.controllerName + '/GetUserStatByUserId' , {params:{UserId:UserId}}).then(response =>{

            return Promise.resolve(response.data);
        });
    }
    GetUserStat() {
        return axios_instance.get(this.controllerName + '/GetUserStat').then(response =>{

            return Promise.resolve(response.data);
        });
    }
    GetAllProfitSlices(payload) {
        return axios_instance.get(this.controllerName + '/GetAllProfitSlices' ,{params:{type:payload.type}}).then(response =>{
            return Promise.resolve(response.data);
        });
    }


    SaveProfitSlice(payload) {
        return axios_instance.post(this.controllerName+'/SaveProfitSlice' , payload ).then(res=>{
            return Promise.resolve(res.data);
        })
    }

    ClaimUser(payload) {
        return axios_instance.post(this.controllerName+'/ClaimUser' , payload ).then(res=>{
            return Promise.resolve(res.data);
        })
    }

    DeleteSlice(Id) {
        return axios_instance.delete(this.controllerName + '/DeleteProfitSlice' , {params:{Id:Id}}).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    GetAllNotClaimsUsersByType(obj) {
        return axios_instance.get(this.controllerName + '/GetAllNotClaimedUsers' ,{params:obj}).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    GetUserStatDetail(payload) {
        return axios_instance.post(this.controllerName + '/GetUserStatDetail' ,payload).then(response =>{
            return Promise.resolve(response.data);
        });
    }
}
export default new SalesClaimService();