<template>
  <div>
    <p>
      <v-btn
          v-for="(icon, i) in icons"
          :key="i"
          class="white--text"
          :href="icon.link"
          target="_blank"
          icon
      >
        <v-icon size="24px">{{ icon.text }}</v-icon>
      </v-btn>
    </p>


  </div>

</template>

<script>
export default {
  name: "SocialIcons",
  data: () => ({
    icons: [
      {
        text: "mdi-facebook",
        link: "",
      },
      {
        text: "mdi-twitter",
        link: "",
      },
      {
        text: "mdi-linkedin",
        link: "",
      },
      {
        text: "mdi-instagram",
        link: "https://instagram.com/",
      },
    ],
  }),
}
</script>

<style scoped>

</style>