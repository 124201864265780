<template>
  <div class="dashboard-container">
    <v-container>
      <ServicePlaceHeader :service-place-id="parseInt($route.params.id)">
      </ServicePlaceHeader>
    </v-container>
    <v-container  fluid>
      <ServicePlaceServicesList :service-place-id="parseInt($route.params.id)">

      </ServicePlaceServicesList>
    </v-container>

  </div>

</template>

<script>
import ServicePlaceHeader from "@/components/ServicesPlaces/ServicePlaceHeader";
import ServicePlaceServicesList from "@/components/ServicesPlaces/ServicePlaceServices";
export default {
  name: "ServicePlaceServices",
  components: {ServicePlaceHeader , ServicePlaceServicesList}
}
</script>

<style scoped>

</style>