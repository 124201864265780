import i18n from "@/plugins/i18n/i18n";
import store from '@/store/index'
console.log(store.getters['authModule/GetMenuCountByName']('complaints'))
export default [
    {
        icon: 'mdi-home',
        title: i18n.t("Home") ,
        to: {name : "Home"},
        roles:['SuperAdmin' , 'User' , 'ServiceSupplier' , 'Moderator']
    },
    {
        icon: 'mdi-face-agent',
        title: i18n.t("Complaints.Complaints") ,
        to: {name : "UsersComplaints"},
        permission:"ManageUserReports",
        count : store.getters['authModule/GetMenuCountByName'],
        countName : 'complaints'
    },

];