<template>
<v-row>
  <v-container v-if="loading" class="text-center">
    <v-progress-circular
        indeterminate
        color="primary"
    ></v-progress-circular>
  </v-container>
  <v-card v-else class="ma-4">
    <v-card-title>
      {{$t('ServicePlaceData')}}
      <v-chip class="ml-2 mr-2" v-if="GetServicePlace.isApproved" color="success">
        {{$t('Approved')}}
      </v-chip>
      <v-chip class="ml-2 mr-2" v-else color="warning">
        {{$t('NotApproved')}}
      </v-chip>
    </v-card-title>
    <v-card-text>
      <v-row>


        <v-col cols="12" md="3" class="pa-2 ma-3">
          <div class="pa-3 ma-3">
            <div class="text-h5">
              {{$t("Name")}}
            </div>
            <div >
              {{GetServicePlace.name}}
            </div>
          </div>


        </v-col>
      </v-row>
    </v-card-text>

  </v-card>
</v-row>
</template>

<script>
import {APP_URL} from "@/Helpers/ConfigSandbox";
export default {

  name: "ServicePlaceHeader",
  props:{
    ServicePlaceId:0
  }
  ,data(){
    return {
      APP_URL ,
      loading : false,

    }
  },
  computed:{
    GetServicePlace(){
      return this.$store.getters["servicesPlacesModule/GetServicePlace"]
    }
  },
  created() {
    this.loading = true;
    this.$store.dispatch('servicesPlacesModule/GetServicePlaceFullData'  , this.ServicePlaceId).then(res=>{
      this.loading = false;
    });
  }

}
</script>

<style scoped>

</style>