<template>
  <v-app :class='$i18n.locale'>
    <app-preloader v-if="!ClosePreloader"></app-preloader>
    <div v-else>
        <navbar  />
        <v-main transition="none"   class="pt-0 main-section">
            <vue-progress-bar></vue-progress-bar>
            <router-view />
        </v-main>
      <foote v-if="$route.name !== 'Chat'"/>
    </div>
  </v-app>
</template>

<script>
import  AppPreloader from './components/General/AppPreloader.vue';
import Navbar from "./Layout/Navbar";
import Home from "@/views/Home";
import AppHelper from './Helpers/AppHelper';
import  foote from "@/components/Footer";
export default {
  name: 'App',
  components: {Home, Navbar , AppPreloader ,foote},
  data () {
    return  {
      ClosePreloader : false,
      loading : []
    }
  },

  computed:{
    getLoginStatus(){return this.$store.getters['authModule/IsLoggedIn'];}
  },
  mounted () {
    this.Initialize();

  },
  watch :{
    loading(val) {
      if(val.length ===0){
        setTimeout(() => {
          this.ClosePreloader= true;
        }, 200);
      }
    },
    getLoginStatus(val){
      if(val){
        this.Initialize();
      }
    }
  }
  ,methods:{
    async Initialize(){
      this.loading.push(true);
      AppHelper.GetCountryFromIp().then(res=>{
        this.$store.dispatch('authModule/Startup' , res).then(res=>{
          this.$store.dispatch('categoriesModule/SetDefaultCats' , res.categories);
          this.$store.dispatch('rankRolesModule/SetDefaultRanks' , res.ranks);
          this.$store.dispatch('regionsModule/SetDefaultCountries' , res.countries);
          localStorage.setItem('countryId' , res.country.id);
          this.loading.splice(this.loading.length-1, 1);

        }).catch(err=>{
          console.log(err);
        });
      });
      if( this.getLoginStatus){
        this.loading.push(true);
        this.$store.dispatch("authModule/checkAuth").then(res=>{
          this.loading.splice(this.loading.length-1, 1);
        })
      }
    }
  }
};
</script>
<style scoped>
.main-section{
  min-height: calc(100vh - 60px) !important;
}
</style>
