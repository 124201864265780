import ChatService from "@/Services/ChatService";

export const userComplaintModule = {
    namespaced : true,
    state:{
        userReports:[],
        selectedReportToProcess : null
    },actions:{
        GetUserReports({commit}) {
            return ChatService.GetUserReports().then(res => {
                commit('SET_REPORTS', res);
                return Promise.resolve(res);
            })
        },

        ProcessReport({commit} , reportId) {
            return ChatService.ProcessReport(reportId).then(res=>{
                commit('SET_SELECTED_REPORT' , res);
                return Promise.resolve(res);
            })
        },
        SendGuestComplaint({commit} , payload){
            return ChatService.SendGuestComplaint(payload).then(res=>{
                return Promise.resolve(res);
            })
        }
    }
    ,mutations: {

        SET_REPORTS(state , res){
            state.userReports = res;
        },
        SET_SELECTED_REPORT(state , res){
            state.selectedReportToProcess =res;
        }
    },getters:{
        GetUsersReports : (state)=>{
            return state.userReports;
        }
        ,GetSelectedReportToProcess:(state) =>{
            return state.selectedReportToProcess;
        }
    }
}