import Admin from "@/Helpers/RouteMenueItmes/Admin";
import Common from "@/Helpers/RouteMenueItmes/Common";
import ServiceSupplier from "@/Helpers/RouteMenueItmes/ServiceSupplier";
import Moderator from "@/Helpers/RouteMenueItmes/Moderator";
import SalesConsultant from "@/Helpers/RouteMenueItmes/SalesConsultant";
import User from "@/Helpers/RouteMenueItmes/User";
import BranchManager from "@/Helpers/RouteMenueItmes/BranchManager";
let MainList =
    [].concat(Common)
    .concat(Admin)
    .concat(ServiceSupplier)
    .concat(Moderator)
    .concat(SalesConsultant)
    .concat(BranchManager)
    .concat(User);
export default MainList;