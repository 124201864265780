import axios_instance  from "./ApiService";
class OffersService {
    controllerName = 'Offer';
    SaveOffer(payload){
        let form = new FormData();
        for ( let key in payload ) {
            let it =  payload[key];
            form.append(key.charAt(0).toUpperCase() + key.slice(1),it);
        }
        if(payload.images && payload.images.length > 0){
            payload.images.forEach((file , index)=>{
                form.append('Images', file );
            })
        }

        delete axios_instance.defaults.headers.common['content-type'];
        return axios_instance.post(this.controllerName +'/SaveOffer' , form , {headers:{
                'Content-Type': 'multipart/form-data'
            }}).then(res=>{
            return Promise.resolve(res.data);
        })
    }

    GetAllServicePlaceOffers(id){
        return axios_instance.post(this.controllerName+'/AdminGetOffers' , {
                ServicePlaceId:id,
                Page:1
            }).then(res=>{
            return Promise.resolve(res.data);
        });
    }

    GetOfferById(id) {
        return axios_instance.get(this.controllerName+'/AdminGetServicesPlaceOfferByOfferId' , {params:{
                OfferId:id
            }}).then(res =>{return Promise.resolve(res.data)})
    }

    DeleteOffer(id) {
        return axios_instance.delete(this.controllerName+'/DeleteOffer' , {params:{
                Id:id
            }}).then(res =>{return Promise.resolve(res.data)})
    }

    OfferUserSearch(payload) {
        axios_instance.post(this.controllerName +'/UserSearchOffers', payload).then(res=>{
            return Promise.resolve(res);
        })
    }
}

export default new OffersService();