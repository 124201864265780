import PageSectionService from "@/Services/PageSectionService";

export const pageSectionModule = {
    namespaced : true,
    state:{
        sections :[],
    },actions:{
        GetSectionsByPage({commit} , page){
            return PageSectionService.GetSectionsByPage(page).then(res=>{
                commit("SetSections" , res);
                return Promise.resolve(res);
            }).catch(err=>{
                return Promise.reject("fail");
            });


        },
        GetMainPageSection({commit} , page){
            return PageSectionService.GetMainPageSection(page).then(res=>{
                return Promise.resolve(res);
            }).catch(err=>{
                return Promise.reject("fail");
            });


        },
        SaveSection({commit} , payload){
            return PageSectionService.SaveSection(payload).then(res=>{
                return Promise.resolve("done");
            }).catch(err=>{
                return Promise.reject("fail");
            });
        },
        DeletePageSection({commit} , Id){
            return PageSectionService.DeletePageSection(Id).then(res=>{
                return Promise.resolve("done");
            }).catch(err=>{
                return Promise.reject("fail");
            });
        },
        UploadImage({commit} ,file){
            return PageSectionService.UploadImage(file);
        }
    }
    ,mutations: {
        SetSections(state , res){
            state.sections = res;
        }


    },getters:{
        GetSections : (state)=>{
            return state.sections;
        }
    }

}