<template>
  <div class="dashboard-container">
    <ServicePlacesGrid></ServicePlacesGrid>

  </div>
</template>

<script>
import ServicePlacesGrid from "@/components/ServicesPlaces/ServicePlacesGrid";
export default {
  name: "ServicesPlaces",
  components:{
    ServicePlacesGrid
  }
}
</script>

<style scoped>

</style>