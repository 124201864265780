
import axios_instance  from "./ApiService";


class UsersService {
    controllerName = '/UsersControl';

    GetAllUsers(obj) {
        return axios_instance.post(this.controllerName + '/GetAllUsers' , obj).then(response =>{
            console.log(response);
            return Promise.resolve(response.data);
        });
    }

    SaveUser(payload) {
        return axios_instance.post(this.controllerName + '/CreateUser' , payload).then(response =>{
            return Promise.resolve(response.data.user);
        });
    }

    GetById(id) {
        return axios_instance.get(this.controllerName + '/GetById' , {params:{
                UserId : id
            }}).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    AttachUsers(payload) {
        return axios_instance.post(this.controllerName + '/AttachUsers' , null,  {
            params:payload}
        ).then(response =>{
            return Promise.resolve(response.data.user);
        });
    }
}
export default new UsersService();