export default function GetTopics(){
    let user = JSON.parse(localStorage.getItem('user'));
    console.log(user);
    let RankTopics = {};
    let topics = [];
    if(user){
        RankTopics = {
            SuperAdmin:['admin_topic',`user_topic_${user.id}`],
            User:[`user_topic_${user.id}`],
            SalesConsultant:[`sales_topic_${user.id}`],
            ServiceSupplier:[`place_topic_${user.servicePlaceId}`],
            Moderator:[`admin_topic`]
        };
        topics = RankTopics[user.rankBaseName];
    }
    return topics;
}
