import i18n from "@/plugins/i18n/i18n";
import store from '@/store/index'
export default [
    {
        icon: 'mdi-wallet',
        title: i18n.t("AccountStatement") ,
        to: {name : "AccountStatement"  },
        roles:['User']
    },
    {
        icon: 'mdi-cart-percent',
        title: i18n.t("Order.PendingOrders") ,
        to: {name : "ClientPendingOrders"  },
        roles:['User']
    },
    {
        icon: 'mdi-cart-check',
        title: i18n.t("Order.DoneOrders") ,
        to: {name : "ClientDoneOrders"  },
        roles:['User']
    },
    {
        icon: 'mdi-chat',
        title: i18n.t("Chat") ,
        to: {name : "Chat"   },
        roles:['User']
    },
    {
        icon: 'mdi-swap-horizontal',
        title: i18n.t("TransferPoints") ,
        to: {name : "WalletTransferPoints"  , params: {userId : store.getters['authModule/GetLoginUser']?.id||0} },
        roles:['User']
    },
    {
        icon: 'mdi-cash-clock',
        title: i18n.t("PaymentRequests") ,
        to: {name : "PaymentRequests"  },
        roles:['User']
    },



    {
        icon: 'mdi-help-box-multiple-outline',
        title: i18n.t("Help") ,
        to: {name : "Pages" , params:{Title : 'Help'} },
        roles:['User']
    },
    {
        icon: 'mdi-information',
        title: i18n.t("About") ,
        to: {name : "Pages" , params:{Title : 'About'} },
        roles:['User']
    },
    {
        icon: 'mdi-frequently-asked-questions',
        title: i18n.t("Faqs") ,
        to: {name : "Pages" , params:{Title : 'Faqs'} },
        roles:['User']
    },
];