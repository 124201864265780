<template>
  <v-container>
    <v-container v-if="loading" class="text-center">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-container>
    <v-row class="grid" v-if="!loading && user">
      <v-col
          cols="12"
          md="6"
      >
        <v-toolbar-title>{{$t("RolesGrid")}}</v-toolbar-title>
      </v-col>
      <v-col
          cols="12" md="6"
      >
        <v-btn
            class="red float-left pa-2 ma-2"


            @click="BackToUsers"
        >
          <v-icon>

          </v-icon>
          {{$t("Back")}}
        </v-btn>
        <v-btn
            class="primary float-left pa-2 ma-2"

            :loading="btn.loading"
            :disabled="btn.disabled"
            @click="SaveUserRoles"
        >
          <v-icon>

          </v-icon>
          {{$t("Save")}}
        </v-btn>
      </v-col>
        <div class="text-center">
          <v-chip
              class="ma-4"
              color="indigo"
              outlined
              large
              v-for="item in GetAllRoles" :key="'role_'+item.id"
          >
            <v-checkbox
                color="primary"

                :value="item.id"
                v-model="roles"
                :label="item.showName"
            ></v-checkbox>

          </v-chip>
        </div>

    </v-row>
  </v-container>

</template>

<script>
import AppCircularLoader from "@/components/General/AppCircularLoader";
export default {
  name: "UserPermissions",
  components: {AppCircularLoader},
  props:{
    user :Object
  },data(){
    return {
      loading : false,
      roles : [],
      btn : {
        loading : false,
        disabled : false
      }
    }
  },mounted() {
    if(this.user)this.Initialize();
  },
    computed:{
      GetAllRoles(){
        return this.$store.getters['rankRolesModule/GetAllRoles'];
      }
    }
  ,methods:{
    Initialize(){
      this.loading = true
      this.$store.dispatch("rankRolesModule/GetAllRolesByUser" , this.user.id).then(res=>{
        this.loading = false
        this.roles = this.GetAllRoles.filter(s=>s.isInUser === true).map(s=>s.id);
      });
    },
    SaveUserRoles(){
      this.btn.loading =true;
      this.$store.dispatch('rankRolesModule/SaveUserRoles' ,
          {userId : this.user.id , rankId:this.user.rankId , roles:this.roles}).then(res=>{
          this.btn.loading = false;
          this.btn.disabled = true;
          this.$emit('returnToUsers')
      });
    },
    BackToUsers(){
      this.$emit("returnToUsers")
    }
  }
}
</script>

<style scoped>

</style>