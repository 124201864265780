<template>
<v-container>
  <v-card-title>
    {{$t('Branches')}}
  </v-card-title>
  <v-row>
    <v-col cols="12" class="custom-text-left">
      <v-btn v-if="mode!== 'select'" @click="editItem(null)" color="primary" >
        {{$t('AddBranch')}}
      </v-btn>
    </v-col>
    <v-col cols="12" v-for="branch in GetAll" :key="`branch_${branch.id}`">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" lg="6">
              <p>{{branch.name}}</p>
              <p><span style="color:#00d5b2;">{{`${$t('WorkTimes')} : ${branch.startString} - ${branch.endString}`}}</span>
              </p>
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <p>{{branch.addressAr}}</p>
              <p>{{branch.addressEn}}</p>

            </v-col>
            <v-col cols="12" md="2" lg="2">
              <p style="width: 100%">
            <span class="custom-float-right" v-if="mode!=='select'">
                  <v-btn

                      fab
                      small
                      dark
                      class="ma-1"
                      @click="editItem(branch)"
                      color="cyan"
                  >
                  <v-icon dark >
                    mdi-pencil
                  </v-icon>
                </v-btn>
               <v-btn
                   fab
                   dark
                   class="ma-1"
                   small
                   @click="openImages(branch)"
                   color="error"
               >
                  <v-icon dark  >
                    mdi-folder-multiple-image
                  </v-icon>
                </v-btn>

              <v-btn
                  fab
                  dark
                  class="ma-1"
                  small
                  @click="deleteItem(branch)"
                  color="error"
              >
                  <v-icon dark  >
                    mdi-delete
                  </v-icon>
                </v-btn>
            </span>
            <span class="custom-float-right" v-if="mode==='select'">
              <v-btn @click="selectItem(branch)" color="info" class="ma-1">
                  {{$t('Select')}}
              </v-btn>

            </span>


              </p>
            </v-col>
          </v-row>


        </v-card-text>
        </v-card>
    </v-col>
  </v-row>

  <ServicePlaceBranchDialog v-if="dialog" @edited="EditResult" :service-place-id="placeId" :item="Selected" :dialog="dialog"></ServicePlaceBranchDialog>
  <DeleteDialog v-if="deleteDialog" :item="{id:Selected.id}" :dialog="deleteDialog" @result="DeleteDialogResult" :action="'servicesPlacesModule/DeleteBranch'"></DeleteDialog>
  <v-dialog
      v-model="ImagesDialog"
      v-if="ImagesDialog"
      max-width="1500px"
  >
    <v-card>
      <v-card-title>
        {{$t('BranchImages')}}
      </v-card-title>
      <v-card-text>
        <BranchImages v-if="Selected" :branch-id="Selected.id" ></BranchImages>
      </v-card-text>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import ServicePlaceBranchDialog from "@/components/ServicesPlaces/ServicePlaceBranchDialog";
import DeleteDialog from "@/components/General/DeleteDialog";
import BranchImages from "@/views/ServicePlace/Branch/BranchImages.vue";
export default{
  name: "ServicePlaceBranches",
  components: {BranchImages, ServicePlaceBranchDialog,DeleteDialog},
  props:{
    placeId: 0,
    mode:''
  },
  data(){
    return {
      ImagesDialog:false,
      loading : false,
      Selected : null,
      dialog : false,
      deleteDialog : false,
      btn:{
        loading:false,
        disable:false
      }
    }
  },
  computed:{
    GetAll(){
      return this.$store.getters['servicesPlacesModule/GetAllServicesPlaceBranches'];
    }
  },
  mounted() {
    this.initialize();
  }
  ,methods:{
    initialize(){
      if(!this.placeId){
        return;
      }
      this.loading = true;
      this.$store.dispatch('servicesPlacesModule/GetServicePlaceBranches' , this.placeId).then(res=>{
        this.loading = false;
      });
    },
    editItem(obj){
      this.Selected= obj;
      this.dialog = true;
    },
    deleteItem(obj){
      this.deleteDialog = true;
      this.Selected = obj;
    },
    DeleteDialogResult(obj){
      this.Selected = null;
      this.deleteDialog = false;
    },
    EditResult(){
      this.Selected = null;
      this.dialog = false;
      this.initialize();
    },
    selectItem(branch){
      this.$emit('Selected' , branch);
    },
    openImages(selected){
      this.Selected = selected;
      this.ImagesDialog = true;
    }
  }

}
</script>

<style scoped>

</style>