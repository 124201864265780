<template>
  <div>
    <div class="text-center" v-if="loading">
      <AppCircularLoader>

      </AppCircularLoader>
    </div>
      <v-card-text v-if="!loading">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="form.nameAr"
                outlined
                dense
                :label="$t('NameAr')"
                :rules="[rules.required]"
            >

            </v-text-field>
            <v-text-field
                v-model="form.nameEn"
                outlined
                dense
                :label="$t('NameEn')"
                :rules="[rules.required]"
            >

            </v-text-field>
          </v-col>

          <v-col cols="12" md="5">

            <span>
              <v-img  max-width="150px" class="rounded" :src="imagePath">
            <v-file-input  hide-input  @change="change_file" dense class="ma-0" prepend-icon="mdi-camera"
              v-model="form.CategoryImage">

            </v-file-input>
            </v-img>
            </span>
            <span>

            </span>

          </v-col>



        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="primary mt-2 pa-2" :loading="btn.loading" :disabled="btn.disable"  small  @click="SaveForm">
          {{$t('Save')}}
        </v-btn>
      </v-card-actions>

  </div>
</template>

<script>
import {rules} from "@/Helpers/ValidationRules";

import AppCircularLoader from "@/components/General/AppCircularLoader";
import {APP_URL} from "@/Helpers/Config";
export default {
  name: "EditCategoryForm",
  components: {AppCircularLoader},
  props:{
    item : Object
  }
  ,data(){
    return {
      form:{
        nameAr : "",
        nameEn : "",
        imgePath: "",
        id : 0 ,
        CategoryImage:null,
      },
      imagePath : "",
      loading: false,
      rules : rules,
      btn : {
        loading : false,
        disable :false
      },
      APP_URL
    }
  }
  ,created() {
    if(this.item){
      this.form = this.item;
      this.imagePath = this.APP_URL + this.item.imgePath;
    }
    this.form.CategoryImage = null;
  },
  computed:{

  },
  methods:{
    CategorySelected(obj){
      this.form.CatId = obj.id
    },
    SaveForm(){
      this.btn.loading = true;

      this.$store.dispatch("categoriesModule/SaveCategory" , this.form).then(res=>{
        this.btn.loading = false;
        this.btn.disable = true;
        this.$toastr.s(this.$i18n.t('SuccessSaveMessage'));
        this.$emit('edited' , res);
      });
    },
    change_file(file){
        this.imagePath = URL.createObjectURL(file);
    }
  }

}
</script>

<style scoped>

</style>