import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
//import preset from './default-preset/preset'




Vue.use(Vuetify)

export default new Vuetify({
 //preset,
  rtl : localStorage.getItem('lang') === 'ar',
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,

    }
  },
})
