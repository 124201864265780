<template>
  <div>
    <div class="text-center" v-if="loading">
      <AppCircularLoader>

      </AppCircularLoader>
    </div>
      <v-card-text v-if="!loading">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
                v-model="form.nameAr"
                outlined
                dense
                :label="$t('NameAr')"
                :rules="[rules.required]"
            >

            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
                v-model="form.nameEn"
                outlined
                dense
                :label="$t('NameEn')"
                :rules="[rules.required]"
            >

            </v-text-field>
          </v-col>

          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="primary mt-2 pa-2" :loading="btn.loading" :disabled="btn.disable"  small  @click="SaveForm">
          {{$t('Save')}}
        </v-btn>
      </v-card-actions>

  </div>
</template>

<script>
import {rules} from "@/Helpers/ValidationRules";

import AppCircularLoader from "@/components/General/AppCircularLoader";
import {APP_URL} from "@/Helpers/Config";
export default {
  name: "EditGovernmentForm",
  components: {AppCircularLoader},
  props:{
    item : Object
    ,countryId:0
  }
  ,data(){
    return {
      form:{
        nameAr : "",
        nameEn : "",
        code: "",
        parentId : 0,
        Id : 0 ,
      },
      imagePath : "",
      loading: false,
      rules : rules,
      btn : {
        loading : false,
        disable :false
      },
      APP_URL
    }
  }
  ,created() {
    if(this.item){
      this.form = this.item;

    }
    this.form.parentId = this.countryId;
  },
  computed:{

  },
  methods:{
    SaveForm(){
      if(this.form.parentId ===0) return this.$toastr.e('invalid country')
      this.btn.loading = true;
      this.form.ParentId = this.form.countryId;

      this.$store.dispatch("regionsModule/SaveGovernment" , this.form).then(res=>{
        this.btn.loading = false;
        this.btn.disable = true;
        this.$toastr.s(this.$i18n.t('SuccessSaveMessage'));
        this.$emit('edited' , res);
      });
    },
    change_file(file){
        this.imagePath = URL.createObjectURL(file);
    }
  }

}
</script>

<style scoped>

</style>