<template>
  <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-3"
      :loading="loading"
      :no-data-text="$t('NoData')"
      :no-results-text="$t('NoResult')"
      :loading-text="$t('PleaseWait')"
      :footer-props="footer"
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>{{$t('Governments')}}</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-col
            md="2"
            cols="12"
        >
          <v-text-field
              v-model="search.name"
              append-icon="mdi-magnify"
              :label="$t('EnterName')"
              outlined
              @change="searchFilter"
              dense
              hide-details
          ></v-text-field>

        </v-col>
        <v-col cols="12" md="8" >
          <v-btn class="primary float-left pa-2 ma-2"
                 @click="AddItem"
                 small
                 v-if="HasPermission('RegionControl')"
          >
            {{$t('Add')}}
          </v-btn>

<!--          <v-btn class="warning float-left pa-2 ma-2" small>-->
<!--           {{$t('Search')}}-->
<!--          </v-btn>-->

        </v-col>


        <EditGovernmentDialog :country-id="selected.id" @edited="itemEdited" :item="editedItem" v-if="dialog" :dialog="dialog" :edited-index="editedIndex"></EditGovernmentDialog>
        <DeleteDialog @result="deleteItemConfirm" :action="'regionsModule/deleteGovernment'" :item="editedItem" v-if="dialogDelete" :dialog="dialogDelete"></DeleteDialog>
      </v-toolbar>
    </template>
    <template v-slot:item.imgePath="{ item }">
      <v-img
          :src="APP_URL+item.imgePath"
          max-width="80px"

          class="ma-2 rounded"

      >

      </v-img>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-menu offset-y v-if="menu_items.some(s=>s.show === true)">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              small

              v-bind="attrs"
              v-on="on"
          >
            <v-icon>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-for="(m_item, index) in menu_items"
              :key="'action_'+index"
              v-if="m_item.show"
              @click="m_item.Click(item)"
          >
            <v-list-item-icon class=""><v-icon small>{{m_item.icon}}</v-icon></v-list-item-icon>

            <v-list-item-title>{{ m_item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </template>
    <template v-slot:no-data>
      {{$t('NoData')}}
      <v-btn
          v-if="selected"
          color="primary"
          @click="initialize"
      >
       <v-icon>
         mdi-reload
       </v-icon>
      </v-btn>
    </template>


  </v-data-table>

</template>


<script>
import AppPagination from "@/components/General/AppPagination";
import {APP_URL} from "@/Helpers/ConfigSandbox";
import EditGovernmentForm from "@/components/Regions/Governments/EditGovernmentForm";
import DeleteDialog from "@/components/General/DeleteDialog";
import EditGovernmentDialog from "@/components/Regions/Governments/EditGovernmentDialog";
export default {
  name: 'GovernmentsGrid',
  components: {EditGovernmentDialog, DeleteDialog ,EditGovernmentForm, AppPagination},
  props:{
    selected : Object
  },
  data: () => ({
    search:{
      name: ''
    },
    dialog: false,

    dialogDelete: false,
    items: [],
    countryId: 0,
    editedIndex: -1,
    editedItem: {

    },

    loading:false,
    APP_URL
  }),
  computed: {
    headers(){
      return  [
        { text: this.$i18n.t("NameAr"), value: 'nameAr' , align: 'start'},
        { text: this.$i18n.t("NameEn"), value: 'nameEn' },
        { text: '', value: 'actions', sortable: false },
      ]
    },
    footer(){
      let lang = localStorage.getItem('lang');
      if(lang === 'ar'){
        return {
          showFirstLastPage: false,
          'page-text':'{0}-{1} من {2}',
          'items-per-page-text':'العدد في الصفحة',

        }
      }else{
        return {
          showFirstLastPage: false,
          'page-text':'{0}-{1} from {2}',
          'items-per-page-text':'page number',

        }
      }
    },
    GetAll(){
      return this.$store.getters['regionsModule/GetAllGovernment'];
    },

    menu_items(){ return [
      {title : this.$i18n.t('Cities') , Click : (item)=>{this.GoToAirports(item)} , icon : "mdi-eye", show : true},
      {title : this.$i18n.t('Edit') , Click : (item)=>{this.editItem(item)} ,icon:"mdi-pencil",  show : this.$store.getters['authModule/HasPermission']('RegionControl')},
      {title : this.$i18n.t('Delete') , Click : (item)=>{this.deleteItem(item)} ,icon:"mdi-delete",  show : this.$store.getters['authModule/HasPermission']('RegionControl')},
    ]}
  },
  created() {
    this.initialize();
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },

    selected(val){
     this.initialize();
    },

  },

  methods: {
    HasPermission(per){
      return this.$store.getters['authModule/HasPermission'](per);
    },
    initialize () {
      this.loading = true;
      this.$store.dispatch('regionsModule/GetGovernmentsByCountryId' , this.selected.id).then(res=>{
        this.loading = false;
        this.items = this.GetAll;
      });
    },
    editItem (item) {

      this.editedIndex = item  ? this.items.indexOf(item) : -1
      this.editedItem =item ?  Object.assign({}, item):  null;
      this.dialog = true
    },
    deleteItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = item
      this.dialogDelete = true
    },
    deleteItemConfirm (item) {
      this.closeDelete()
    },
    close () {
      this.dialogDelete = false
      this.editedIndex = -1;
    },
    closeDelete () {
      this.dialogDelete = false
    },
    itemEdited(obj){
      this.editedItem = obj.item;
      this.editedIndex = obj.index;
      this.items = [];
      setTimeout(()=>{
        this.searchFilter();
      } , 10);
      this.editedIndex = -1;
      this.dialog = false;
    },

    GoToAirports(item){
      this.$router.push({name: 'CitiesManagement' , params:{governmentId:item.id , countryId:item.parentId}})
    },
    AddItem(){
        this.editedIndex = -1;
        this.editedItem = null;
        this.dialog = true;
    },
    searchFilter(){
      this.items = this.GetAll;
      if(this.search.name !== ''){
        let results = this.items.filter((value) =>{
          return value.nameAr.includes(this.search.name) || value.nameEn.includes(this.search.name)
        })
        if(results.length>0)
          this.items = results;
      }
    }

  },
}
</script>
