<template>
  <div>
    <home />
    <v-row class="mt-4">
      <v-col cols="12" class="text-center">
        <h2>
          {{$t('SubscribePackages')}}
        </h2>
      </v-col>
      <v-col cols="12">
        <PricingPlansList :type="'online'" ></PricingPlansList>

      </v-col>
    </v-row>
    <DownloadSection :mobile="mobile" />

<!--    <contact></contact>-->

    <PaymentSection></PaymentSection>

<!--    <v-scale-transition>-->
<!--      <v-btn-->
<!--          fab-->
<!--          v-show="fab"-->
<!--          v-scroll="onScroll"-->
<!--          dark-->
<!--          fixed-->
<!--          bottom-->
<!--          right-->
<!--          color="secondary"-->
<!--          @click="toTop"-->
<!--      >-->
<!--        <v-icon>mdi-arrow-up</v-icon>-->
<!--      </v-btn>-->
<!--    </v-scale-transition>-->

  </div>
</template>

<script>
import navigation from "@/components/Navigation";
import foote from "@/components/Footer";
import home from "@/components/HomeSection";
import about from "@/components/AboutSection";
import download from "@/components/DownloadSection";
import pricing from "@/components/Client/PricingSection";
import DownloadSection from "@/components/DownloadSection";
import PricingSection from "@/components/Client/PricingSection";
import PricingPlansList from "@/components/Pricing/PricingPlansList";
import PaymentSection from "@/components/PaymentSection";

export default {
  name: "Home",

  components: {
    PaymentSection,
    PricingPlansList,
    PricingSection,
    DownloadSection,
    navigation,
    foote,
    home,
    about,
    download,
    pricing,
  },

  data: () => ({
    fab: null,
    color: "",
    flat: null,
    mobile : false
  }),

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    Resize(mobile){
      this.mobile = mobile;
    }
  },
};
</script>

<style scoped>

</style>