<template>
  <v-data-table
      :headers="headers"
      :items="GetAllUsers"
      class="elevation-3 pa-2"
      :loading="loading"
      :no-data-text="$t('NoData')"
      :no-results-text="$t('NoResult')"
      :loading-text="$t('PleaseWait')"
      hide-default-footer
      :items-per-page="20"
  >
    <template v-slot:top>
      <v-toolbar
          flat
          class="pa-2"
      >


          <v-toolbar-title>{{$t('Users')}}</v-toolbar-title>
          <v-divider
              class="mx-3"
              inset
              vertical
          ></v-divider>

            <v-text-field
                append-icon="mdi-magnify"
                :label="$t('EnterName')"
                v-model="search.name"
                outlined
                dense
                hide-details
            ></v-text-field>
          <v-btn
              color="primary"
              dark
              class="mr-2 ml-2"
              @click="initialize"
          >
            {{$t("Search")}}
          </v-btn>

        <v-spacer></v-spacer>

        <EditUserDialog @edited="UserEdited" v-if="editDialog" :dialog="editDialog" :edited-index="editedIndex"  :user="editedItem" :rank="{id:editedItem.rankId , name:editedItem.rank}"></EditUserDialog>
<!--        <v-dialog-->
<!--            v-model="chat.dialog"-->
<!--            max-width="500px"-->
<!--            v-if="chat.selected"-->

<!--        >-->
<!--          <v-card>-->
<!--            <v-card-title>{{selected.name}}</v-card-title>-->
<!--            <v-card-text>-->
<!--              <v-form v-model="chat.messageForm" ref="messageForm">-->

<!--              </v-form>-->
<!--              <v-container class="chat">-->

<!--                  <v-col cols="12" sm="12" lg="12" md="12" class="pa-0">-->
<!--                    <v-textarea-->
<!--                        v-model="chat.message"-->
<!--                        :label="$t('Message')"-->
<!--                        flat-->
<!--                        solo-->
<!--                        rounded-->
<!--                        background-color="#e1e1e1ba"-->
<!--                        required-->
<!--                        rows="3"-->
<!--                    />-->

<!--                  </v-col>-->

<!--              </v-container>-->
<!--            </v-card-text>-->
<!--            <v-card-actions>-->
<!--              <v-btn  :loading="chat.loading" :disabled="chat.disabled"  @click="SendMessage" class="primary ma-1">-->
<!--                <v-icon small>mdi-chat</v-icon> {{$t('Send')}}-->
<!--              </v-btn>-->
<!--            </v-card-actions>-->

<!--          </v-card>-->
<!--        </v-dialog>-->
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              small

              v-bind="attrs"
              v-on="on"
          >
           <v-icon>
             mdi-dots-vertical
           </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-for="(m_item, index) in menu_items"
              :key="'action_'+index"
              v-if="m_item.show"
              @click="m_item.Click(item)"
          >
            <v-list-item-icon class=""><v-icon small>{{m_item.icon}}</v-icon></v-list-item-icon>

            <v-list-item-title>{{ m_item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:no-data>
      <v-container class="text-center">
        <v-row>
          <v-col :cols="12">
            {{$t('NoData')}}
          </v-col>
          <v-col :cols="12">
            <v-btn
                color="primary"
                @click="initialize"
            >
              {{ $t("tryAgain") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

    </template>
    <template v-slot:footer>
      <AppPagination :total-visible="20" :store="'usersModule'" :action="'GetAll'" :collection="'GetAllUsers'"></AppPagination>
    </template>

  </v-data-table>

</template>


<script>
import AppPagination from "@/components/General/AppPagination";
import EditUserDialog from "@/components/Users/EditUserDialog";
export default {
  name: 'UsersGrid',
  components: {AppPagination ,EditUserDialog},
  props:{
    rank : '',
    selected:null,
    ServicePlaceId : 0,
    BranchId : null,
    managerId:null,

  },

  data: () => ({
    search:{
      name: ''
    },

    editDialog: false,
    snackbar:{
      status:false,
      class:"primary"
      ,text:""
    },
    dialogDelete: false,
    items: [],
    editedIndex: -1,
    editedItem: {
    },

    loading:false,
    chat:{
      loading: false,
      disabled : false,
      selected : null,
    }
  }),
  computed: {

    headers(){
      return  [
        {
          text: this.$i18n.t("UserName"),
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: this.$i18n.t("Email"), value: 'email'},
        { text: this.$i18n.t("Phone"), value: 'phone'},
        { text: this.$i18n.t("Rank"), value: 'rank'},
        { text: '', value: 'actions', sortable: false },
      ]
    },
    GetAllUsers(){
      return this.$store.getters['usersModule/GetAllUsers'].data;
    },
    HasPermission(per){
      return this.$store.getters['authModule/HasPermission'](per);
    },
    menu_items(){ return [
      {title : this.$i18n.t('Edit') , Click : (item)=>{this.editItem(item)} , icon : "mdi-pencil", show : this.$store.getters['authModule/HasPermission']('EditUser')},
      {title : this.$i18n.t('StartConversation') , Click : (item)=>{this.StartConversation(item)} , icon : "mdi-chat", show : this.$store.getters['authModule/HasPermission']('MessageUsers')},
      {title : this.$i18n.t('AccountStatement') , Click : (item)=>{this.ControlUserWallet(item)} , icon : "mdi-wallet", show : this.$store.getters['authModule/HasPermission']('UserAccountStatement')},
      {title : this.$i18n.t('EditRoles') , Click: (item)=>{this.EditRoles(item)},icon : "mdi-account-details " , show : this.$store.getters['authModule/HasPermission']('EditUserRoles')},
      {title : this.$i18n.t('Delete') , Click : (item)=>{this.deleteItem(item)} ,icon:"mdi-delete",  show : this.$store.getters['authModule/HasPermission']('DeleteUser')},
    ]}
  },
  watch: {

    dialogDelete (val) {
      val || this.closeDelete()
    },
    rank(val){
      if( !this.selected&&  val!==null){
        this.initialize()

      }
    }
    ,selected(val){
      if(val){
        this.initialize();
      }
    }
  },
  mounted() {
   this.initialize();
  },
  methods: {
    initialize () {
      debugger;
      this.loading = true
      this.$store.dispatch('usersModule/GetAll' , {
        rankId : this.selected? this.selected.id : this.$store.getters['rankRolesModule/GetRankIdByString'](this.rank),
        Page : 1,
        name : this.search.name,
        ServicePlaceId : this.ServicePlaceId,
        BranchId : this.BranchId
      }).then(()=>{
        this.loading = false;
      });
    },
    ControlUserWallet(item){
      this.$router.push({name:'userWalletControl' , params:{id:item.id}});
    },
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = item;
      this.editDialog = true
    },
    deleteItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.$store.dispatch('usersModule/deleteUser' , this.items[this.editedIndex].id);
      this.items.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    closeDelete () {
      this.dialogDelete = false

    },
    UserEdited(item){
      this.editedIndex = item.editedIndex;
      this.editDialog = false;
      this.editedItem = item.user;
    },
    EditRoles(user){
      this.$emit('editUserRoles', user);
    },
    StartConversation(user){
      this.chat.selected = user;
      this.chat.loading = true;
      this.chat.disabled = true;
      this.$store.dispatch('chatModule/GetChatBetween' , user.id).then(res=>{
        this.chat.loading = false;
        this.chat.disabled = false;
        if(res){
          this.$store.dispatch('chatModule/SetSelectedChat' ,res).then(res=>{
            this.$router.push({name:'Chat'});
          });
        }else{
          this.$store.dispatch('chatModule/SetSelectedChat' ,{
            chatId : 0,
            chatName : user.name,
            fromReport : false,
            newChat : true,
            userId : user.id
          }).then(res=>{
            this.$router.push({name:'Chat'});
          });
        }
      })

    }
  },
}
</script>
