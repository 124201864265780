import {sha256} from "js-sha256";
import moment from "moment";
class FawryPaymentHelper {
    GetChargeRequest(item , user){
        let chargeRequest =   {
            merchantCode: 'siYxylRjSPybBbgAjJ02xA==',
            merchantRefNum: 'order-'+moment().unix(),
            customerMobile:user.phone ,
            customerEmail: user.email,
            customerName: user.name,
            customerProfileId:user.id+'_'+item.id,
            chargeItems: [
                {
                    itemId: user.id+'_'+item.id,
                    description: item.name,
                    price:parseInt(item.balance)+'.00',
                    quantity: 1,
                },

            ],
            returnUrl:window.location.origin+'/Payment/Result',
            authCaptureModePayment: false,
            signature: ""
        };
        chargeRequest.signature = this.GetSignature(chargeRequest);
        return chargeRequest;
    }

    GetSignature(chargeRequest) {

        let item = chargeRequest.chargeItems[0];
        let signText =
            chargeRequest.merchantCode
            + chargeRequest.merchantRefNum
            + chargeRequest.customerProfileId + chargeRequest.returnUrl
            + item.itemId
            + item.quantity
            + item.price
            + "daa76fdf-c5ec-495d-bf01-ea29aa2031e7";

        return sha256(signText);
    }
}
export  default  new FawryPaymentHelper();