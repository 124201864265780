<template>
    <div class="dashboard-container">
      <v-card>
        <v-card-title>
          <h3 class="pa-2">{{$t('PointsPackages')}}</h3>

        </v-card-title>
        <v-card-text>
            <v-container>
              <v-row>

              <v-col md="4">
                <AppDropDown  :validate="true" :title="$t('SelectCounty')"   :action="'regionsModule/GetAllActivatedCountries'"  :getter="'regionsModule/GetAllActivatedCountries'"  @Selected="CountrySelected" ></AppDropDown>
              </v-col>
                <v-col md="2">
                  <v-btn
                      class="custom-float-Right"
                      color="primary"
                      @click="OpenDialog"
                  >
                    {{$t('Add')}}
                  </v-btn>
                </v-col>
              </v-row>

            </v-container>
          <PointsPackagesList :countryId="countryId" :dialog="dialog" @CloseDialog="CloseDialog"></PointsPackagesList>
        </v-card-text>
      </v-card>
    </div>
</template>
<script>
  import AppDropDown from "@/components/General/AppDropDown";
    import PointsPackagesList from "@/components/PointsPackages/PointsPackagesList";
    export default {
        name :"PointsPackages",
      components: {PointsPackagesList , AppDropDown},
      data(){
            return {
                dialog : false,
              countryId:0
            }
        }
        ,props : {

        },
        computed:{

        },
        methods:{
          CloseDialog(){
              this.dialog = false
          },
          OpenDialog(){
              this.dialog = true;
          },
          CountrySelected(obj){
            this.countryId = obj.id
              this.$store.dispatch('pointsPackageModule/GetAllPointsPackagesByCountryId' , obj.id).then(res=>{

              })
          }
        }
    }
</script>
<style>
</style>