
import axios_instance  from "./ApiService";


class OrdersService {
    controllerName = '/Order';
    GetAllPendingByServicePlace(ServicePlaceId) {
        return axios_instance.get(this.controllerName + '/GetAllPendingOrdersForServicesPlace' , {params:{ServicePlaceId:ServicePlaceId}}).then(response =>{

            return Promise.resolve(response.data);
        });
    }

    GetCurrentOrders(payload) {
        return axios_instance.get(this.controllerName + '/GetAllDoneBranchOrdersOrPlaceOrders' , {params:payload}).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    GetAllPendingUserOrders() {
        return axios_instance.get(this.controllerName + '/GetAllPendingUserOrders').then(response =>{
            return Promise.resolve(response.data);
        });
    }
    GetAllDoneUserOrders() {
        return axios_instance.get(this.controllerName + '/GetAllDoneUserOrders').then(response =>{
            return Promise.resolve(response.data);
        });
    }
}
export default new OrdersService();