import i18n from "@/plugins/i18n/i18n";

export default [
    //ServicePlace
    {
        icon: 'mdi-fireplace-off',
        title: i18n.t("EditServicePlace") ,
        to: {name : "PlaceMainData"},
        permission  : "EditServicePlace",
        roles:['ServiceSupplier']
    },
    {
        icon: 'mdi-account-multiple-plus',
        title: i18n.t("UsersManagement") ,
        to: {name : "PlaceUsers"},
        permission  : "UsersControl",
        roles:['ServiceSupplier']
    },
    {
        icon: 'mdi-source-fork',
        title: i18n.t("Branches") ,
        to: {name : "PlaceBranches"},
        permission  : "BranchesControl",
        roles  : ['ServiceSupplier'],
    },
    {
        icon: 'mdi-account-multiple-plus',
        title: i18n.t("BranchData") ,
        to: {name : "BranchData"},
        permission  : "EditBranch",
        roles  : ['BranchManager'],
    },
    {
        icon: 'mdi-tag',
        title: i18n.t("Offers") ,
        to: {name : "PlaceOffers"},
        permission  : "OffersControl",
        roles  : ['ServiceSupplier'],
    },
    {
        icon: 'mdi-cart-percent',
        title: i18n.t("Order.PendingOrders") ,
        to: {name : "PendingOrders"},
        permission  : "OrdersControl",
        roles  : ['ServiceSupplier'],
    },
    {
        icon: 'mdi-cart-percent',
        title: i18n.t("Order.CurrentOrders") ,
        to: {name : "CurrentOrders"},
        permission  : "OrdersControl",
        roles  : ['ServiceSupplier'],
    },


];