<template>
  <div>
    <v-container v-if="loading" class="text-center">
    <v-progress-circular
        indeterminate
        color="primary"
    ></v-progress-circular>
  </v-container>
      <v-card-text v-if="!loading">
          <v-form ref="package_form" v-model="valid">
                  <v-row>

             <v-col md="4">
            <v-text-field
                v-model="form.nameAr"
                outlined
                dense
                :error-messages="errorMessages"
                :label="$t('NameAr')"
                :rules="[rules.required]"
            >

            </v-text-field>
            </v-col>
            <v-col md="4">
             <v-text-field
                  v-model="form.nameEn"
                  outlined
                  dense
                  :error-messages="errorMessages"
                  :label="$t('NameEn')"
                  :rules="[rules.required]"
              >

              </v-text-field>           
          </v-col>

          <v-col md="4">
            <v-text-field
                v-model="form.points"
                outlined
                dense
                :error-messages="errorMessages"
                :label="$t('points')"
                :rules="[rules.numeric]"
            >

            </v-text-field>
          </v-col>
          <v-col md="4">
             <v-text-field
                v-model="form.balance"
                outlined
                dense
                :error-messages="errorMessages"
                :label="$t('balance')"
                :prefix="form.currency?form.currency.prefix:''"
                :rules="[rules.required,rules.numeric]"
            >

            </v-text-field>


          </v-col>
          <v-col md="4">
              <MonthsNumersDropDown @Selected="MonthsSelected"  :item="form.monthsNumber"></MonthsNumersDropDown>
          </v-col>
          <v-col md="4">
            <AppDropDown :item="{id:form.countryId}" :validate="true" :title="$t('SelectCounty')"   :action="form.countryId !== 0 ?'' : 'regionsModule/GetAllActivatedCountries'"  :getter="'regionsModule/GetAllActivatedCountries'"  @Selected="CountrySelected" ></AppDropDown>
          </v-col>
        </v-row>

          </v-form>
         
      </v-card-text>
      <v-card-actions>
        <v-btn class="primary mt-2 pa-2" :loading="btn.loading" :disabled="btn.disable"  small  @click="SaveForm">
          {{$t('Save')}}
        </v-btn>
        <v-btn class="red mt-2 pa-2"   small  @click="Close">
          {{$t('Cancel')}}
        </v-btn>
      </v-card-actions>

  </div>
</template>

<script>
import {rules} from "@/Helpers/ValidationRules";
import AppDropDown from "../General/AppDropDown.vue";
import MonthsNumersDropDown from "../General/MonthsNumersDropDown.vue";
import {APP_URL} from "@/Helpers/Config";
export default {
  name: "PointsPackageForm",
  components: {AppDropDown  , MonthsNumersDropDown},
  props:{
    item : Number,
    countryId:Number,
  }
  ,data(){
    return {
      valid:false,
      form:{
        nameAr : "",
        nameEn : "",
        countryId: "",
        points:'',
        balance:'',
        monthsNumber:0,
        Id : 0 ,
      },
      errorMessages:"",
      loading: false,
      rules : rules,
      btn : {
        loading : false,
        disable :false
      },
      APP_URL
    }
  }
  ,mounted() {
    if(this.item)
      this.initialize();

    if(this.countryId)this.form.countryId = this.countryId;
  },
  computed:{

  },
  methods:{
    initialize(){
      this.loading = true;
      this.$store.dispatch('pointsPackageModule/GetById' , this.item).then(res=>{
       this.loading = false;
       this.form = res;
      });
    },
    CountrySelected(obj){
      this.form.countryId = obj.id
    },
    MonthsSelected(obj){
      this.form.monthsNumber = obj;
    },
    Close(){
      this.$emit('edited' , null);
    },
    SaveForm(){
      if(!this.valid){
        this.$refs.package_form.validate();
        return;
      }
      this.btn.loading = true;
      this.$store.dispatch("pointsPackageModule/SavePointsPackage" , this.form).then(res=>{
        this.btn.loading = false;
        this.btn.disable = true;
        this.$toastr.s(this.$i18n.t('SuccessSaveMessage'));
        this.$emit('edited' , res);
      });
    }
  }

}
</script>

<style scoped>

</style>