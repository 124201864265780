<template>
  <div>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner" style="width: 100% !important;">
        <v-card class="auth-card pa-2">
          <v-card-title>
            <h4>
              {{$t("PleaseRegisterServicePlaceText")}}
            </h4>
          </v-card-title>
          <v-card-text>

          </v-card-text>
          <v-card-text>
            <v-stepper v-model="stepper_element">
              <v-stepper-header>
                <v-stepper-step
                    :complete="stepper_element > 1"
                    step="1"
                >
                  {{$t('ServicePlace.RegisterPlaceData')}}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                    :complete="stepper_element > 2"
                    step="2"
                >
                  {{$t('ServicePlace.RegisterMainBranchData')}}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="stepper_element>3" step="3">
                  {{$t('ServicePlace.RegisterUserData')}}
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-form ref="PlaceForm"
                          class="pt-3"
                    v-model="forms.PlaceForm"
                  >
                    <v-row>

                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                                v-model="form.NameAr"
                                outlined
                                dense
                                :error-messages="errorMessages"

                                :label="$t('ServicePlace.NameAr')"
                                :rules="[rules.required]"
                            >

                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                                v-model="form.NameEn"
                                outlined
                                dense
                                :error-messages="errorMessages"
                                :label="$t('ServicePlace.NameEn')"
                                :rules="[rules.required]"
                            >

                            </v-text-field>
                          </v-col>


                          <v-col md="12">
                            <v-text-field
                                v-model="phone"
                                outlined

                                dense
                                :placeholder="form.Phones.join(' , ')"
                                v-on:keyup.enter="changePhone"
                                :label="$t('ServicePlacePhones')"
                            >

                            </v-text-field>
                            <label v-if="form.Phones.length>0">{{this.form.Phones.join(' , ')}}</label>
                          </v-col>
                          <v-col cols="12" md="6">
                            <AppDropDown :title="$t('SelectCategory')" :validate="true" :getter="'categoriesModule/GetAllCategories'" :action="''" @Selected="CategorySelected"></AppDropDown>
                          </v-col>
                          <v-col md="6">
                            <v-text-field
                                v-model="form.TaxAccountNumber"
                                outlined
                                dense
                                :label="$t('ServicePlace.TaxAccountNumber')"
                            >

                            </v-text-field>
                          </v-col>
                        </v-row>

                      </v-col>




                      <!-- service place images -->
                      <v-col cols="12" md="6">

                        <ul class="row header-ul overflow-auto" style=" max-height: 500px" >
                          <li class="col-4">
                            <v-img  class="cursor-pointer" @click="fileTrigger('file_input1')" :src="IdentityPath ===''? require('@/assets/no-image.png') : IdentityPath">
                              <label style="background-color: white" class="pa-1">{{$t('IdentityImage')}} </label>

                            </v-img>

                            <input type="file" v-show="false" ref="file_input1"  @change="change_identity_file"/>


                          </li>
                          <li class="col-4" style="">
                            <v-img  class="cursor-pointer" @click="fileTrigger('file_input2')" :src="CommerialPath ===''? require('@/assets/no-image.png') : CommerialPath">
                              <label style="background-color: white" class="pa-1"> {{$t('CommerialImage')}} </label>

                            </v-img>
                            <input type="file" v-show="false" ref="file_input2"  @change="change_commerial_file"/>


                          </li>
                          <li class="col-4" >
                            <v-img class="cursor-pointer" @click="fileTrigger('file_input3')" :src="require('@/assets/no-image.png')">
                              <label> {{$t('PlaceImagesNote')}}</label>

                              <input  type="file" multiple v-show="false" ref="file_input3"  @change="change_file"/>

                            </v-img>

                            <label style="color:green" v-if="ImagesPaths.length>0">Count : {{ImagesPaths.length}} </label>

                          </li>
                          <li class="col-6 rounded"  v-for="(filePath , index) in ImagesPaths"   :key="filePath" :style="`max-width: 100% ; ${MainImage === index?'background : rgb(206 249 206)':''}` " >
                            <label style="color: black"  v-if="MainImage !== index">{{$t("SelectAsMainImage")}}</label>
                            <label style="color: black" v-else>{{$t("MainImage")}}</label>
                            <img class="rounded" style="max-width: 100% ;  cursor: pointer" :src="filePath" @click="SetMainImage(index)">
                          </li>
                        </ul>

                      </v-col>
                      <v-col cols="12">
                        <v-btn
                            color="primary"
                            :loading="btn.loading"
                            :disabled="btn.disable"
                            @click="HandleStepper"
                        >
                          {{ $t("Continue") }}
                        </v-btn>

                        <v-btn text @click="GoToRegister">
                          {{ $t("Cancel") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>


                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-form
                      ref="BranchForm"
                      v-model="forms.BranchForm"
                  >
                    <ServicePlaceBranchForm :is-main="true" @Changed="ChangeBranchData"></ServicePlaceBranchForm>

                  </v-form>
                  <div class="mt-5">
                    <v-btn
                        color="primary"
                        :loading="btn.loading"
                        :disabled="btn.disable"
                        @click="HandleStepper"
                    >
                      {{ $t("Continue") }}
                    </v-btn>

                    <v-btn text @click="stepper_element=1">
                      {{ $t("Back") }}
                    </v-btn>
                  </div>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <v-form
                      ref="UserForm"
                      class="mt-3"
                      v-model="forms.UserForm"
                  >
                    <v-row>
                      <v-col cols="12"  md="3">
                        <v-text-field
                            ref="name"
                            dense
                            v-model="form.SignupModel.UserName"
                            :rules="[() => !!form.SignupModel.UserName || $t('errors.requiredField')]"
                            :error-messages="errorMessages"
                            :label="$t('UserName')"
                            outlined
                            autocomplete="name"
                            placeholder=""
                            required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12"  md="3">
                        <v-text-field
                            ref="email"
                            dense
                            v-model="form.SignupModel.Email"
                            :rules="[() => !!form.SignupModel.Email || $t('errors.requiredField')]"
                            :error-messages="errorMessages"
                            :label="$t('Email')"
                            autocomplete="username"
                            outlined
                            placeholder=""
                            required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12"   md="6">
                        <PhoneInput :dense="true" :phone="form.SignupModel.Phone"
                                    @Changed="ChangePhoneInput"
                                    :item="{code:form.SignupModel.PhoneCode , id:0, img : ''}"
                        >
                        </PhoneInput>
                      </v-col>


                      <v-col cols="12" md="4">
                        <v-text-field
                            ref="password"
                            dense
                            v-model="form.SignupModel.Password"
                            :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.min , rules.required , rules.symbol , rules.upper , rules.lower ,rules.number]"
                            :error-messages="errorMessages"
                            :label="$t('Password')"
                            value=""
                            outlined
                            :type="showPassword ? 'text' : 'password'"
                            placeholder=""
                            @click:append="showPassword = !showPassword"
                            required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">

                        <v-btn
                            color="primary"
                            :loading="btn.loading"
                            :disabled="btn.disable"
                            @click="HandleStepper"
                        >
                          {{ $t("Save") }}
                        </v-btn>
                        <v-btn text @click="stepper_element=2">
                          {{ $t("Back") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </v-card>
    </div>
    </div>
  </div>
</template>

<script>
import {rules} from "@/Helpers/ValidationRules";
import CategoriesDropDown from "@/components/Categories/CategoriesDropDown";
import AppDropDown from "@/components/General/AppDropDown";
import PhoneInput from "@/components/General/PhoneInput";
import AppMap from "@/components/General/AppMap";
import ServicePlaceBranchForm from "@/components/ServicesPlaces/ServicePlaceBranchForm";
export default {
  name: "ServicePlaceAddStepper",
  components: {ServicePlaceBranchForm, AppMap, CategoriesDropDown , AppDropDown , PhoneInput},
  props:{
    item : Object
  }
  ,data(){
    return {
      forms:{
        PlaceForm:false,
        UserForm :false,
        BranchForm:false,
      },
      stepper_element : 1,
      form_valid:false,
      form:{
        Phones:[],
        NameAr : "",
        NameEn : "",
        CatId : 0 ,
        Id : 0 ,
        TaxAccountNumber:"",
        Images:[],
        Identity:null,
        CommerialImage:null,
        ServicePlaceLocation:{
          Lat : 0,
          Lng :0,
          AddressAr : "",
          AddressEn : "",
          CountryId:0,
          GovernmentId:0,
          RegionId:0,
        },
        SignupModel:{
          Email : '',
          Password : '',
          UserName : '' ,
          Phone : '',
          RankString : 'ServiceSupplier',
          PhoneCode:''
        }
      },
      errorMessages : '',
      showPassword : false,

      phone:'',
      MainImage : -1,
      ImagesPaths:[],
      IdentityPath: "",
      location : null,
      CommerialPath: "",
      rules : rules,
      btn : {
        loading : false,
        disable :false
      }
    }
  }
  ,created() {
    if(this.item){
      this.form = this.item;
      this.form.Image = null;
    }
  },
  computed:{

  },
  methods:{
    ChangeBranchData(item){
      this.form.ServicePlaceLocation =item;
    },
    changePhone()
      {
        if(this.phone.length>5){
          this.form.Phones.push(this.phone);
          this.phone='';
        }
      }
    ,
    ChangePhoneInput(item){
      this.form.SignupModel.Phone = item.phone;
      this.form.SignupModel.PhoneCode= item.phoneCode
    },
    CategorySelected(obj){
      this.form.CatId = obj.id
    },

    SetMainImage(Index){
      if(this.MainImage === Index && Index === 0)return;
      let file = this.form.Images[Index];
      let ImagePath = this.ImagesPaths[Index];
      this.ImagesPaths.splice(Index , 1);
      this.form.Images.splice(Index , 1);
      this.MainImage = 0;
      this.form.Images.unshift(file);
      this.ImagesPaths.unshift(ImagePath);

    },
    fileTrigger(ref){
      let elem = this.$refs[ref];
      elem.click();
    },
    change_identity_file(event){
      let files = event.target.files;
      if(files.length === 0)return;
      this.IdentityPath = URL.createObjectURL(files[0])
      this.form.Identity = files[0];

    },
    change_commerial_file(event){
      let files = event.target.files;
      if(files.length === 0)return;
      this.CommerialPath = URL.createObjectURL(files[0])
      this.form.CommerialImage = files[0];
    },
    change_file(event){
      let files = event.target.files;
      let valid = false;
      Array.from(files).forEach((file)=>{
        if(this.ImagesPaths.length === 6) valid = true;
        if(valid) return;
        if(this.ImagesPaths.length === 0 ){
          this.ImagesPaths.push(URL.createObjectURL(file));
          this.form.Images.push(file);
        }else{
          this.ImagesPaths.unshift(URL.createObjectURL(file));
          this.form.Images.unshift(file);
        }
        if(this.MainImage!== -1){
          this.MainImage++;
        }

      })
      if(this.MainImage!== -1){
       this.SetMainImage(this.MainImage);
      }

      if(valid) return this.$toastr.e(this.$i18n.t('errors.Images-length'));
    },
    HandleStepper(){
      let valid = false;
      if(this.stepper_element === 1){
         valid = this.ValidateServicePlaceDataForm();
         if(valid){
           this.stepper_element = 2;
           return;
         }
      }
      if(this.stepper_element === 2){
        valid = this.ValidateBranchForm();
        if(valid){
          this.stepper_element = 3;
          return;
        }
      }
      if(this.stepper_element === 3){
        valid = this.ValidateUserForm();
        if(valid){
          this.SaveForm();
        }
      }
    },
    ValidateBranchForm(){
      this.$refs.BranchForm.validate();
      if(this.forms.BranchForm===false){
        return false;
      }
      if(this.form.lat===0 && this.form.lng===0 ){
        this.$toastr.e(this.$i18n.t('errors.LocationRequired'));
        return false;
      }
      return true;
    },
    ValidateUserForm(){
      this.$refs.UserForm.validate();
      return this.forms.UserForm !== false;
    },
    ValidateServicePlaceDataForm(){
      this.$refs.PlaceForm.validate();
      if(this.forms.PlaceForm===false){
        return false;
      }
      if(this.form.Phones.length===0){
        this.$toastr.e(this.$i18n.t('errors.PhoneMin'));
        return false;
      }  
      if(this.form.Images.length===0){
        this.$toastr.e(this.$i18n.t('errors.PlaceImagesError'));
        return false;
      }
      if(this.MainImage===-1){
        this.$toastr.e(this.$i18n.t('errors.MainImageError'));
        return false;
      }
      return true;
    },
    SaveForm(){
      this.btn.loading = true;
      this.btn.disable = true;
      this.$store.dispatch('servicesPlacesModule/RegisterServicePlace' , this.form).then(response=>{
         this.$toastr.s(this.$i18n.t('RegisterRequestSent'));
         this.$emit("Saved" , response);
         this.btn.loading = false;
      }).catch(err=>{
        this.$toastr.e(err);
      });
    },
    GoToRegister(){
      this.$router.push({name:"Home"})
    }
  }

}
</script>

<style scoped>

</style>