<template>
  <v-dialog v-model="activeStat" max-width="500px">
<!--    <v-card>-->
<!--      <v-card-title style="color:red!important;"  class="text-h6">{{$t('WarningDelete')}}</v-card-title>-->
<!--      <v-card-actions>-->
<!--        <v-spacer></v-spacer>-->
<!--        <v-btn  text @click="ChangeStatus(false)">{{$t('Cancel')}}</v-btn>-->

<!--        <v-btn class="red" :loading="loading" :disabled="disable" text @click="DeleteItem()">{{$t('Confirm')}}</v-btn>-->

<!--        <v-spacer></v-spacer>-->
<!--      </v-card-actions>-->
<!--    </v-card>-->
      <v-sheet
          class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
          color="#13255d"
          dark
      >
        <div class="grey--text text--lighten-1 text-h5 mb-4">
          {{$t('WarningDelete')}}

        </div>

        <v-btn
            :disabled="loading"
            class="ma-1"
            color="white"
            @click="ChangeStatus(false)"
            plain
        >
          {{$t('Cancel')}}
        </v-btn>

        <v-btn
            class="ma-1"
            color="error"
            :loading="loading"
            :disabled="disable"

            @click="DeleteItem()"
        >
          {{$t('Confirm')}}
        </v-btn>
      </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteDialog",
  props:{
    item : Object ,
    dialog : false,
    action : String ,
  },data(){
    return {
      activeStat : false,
      loading : false,
      disable : false
    }
  },
  watch:{
    activeStat(val){
      if(!val){
        this.ChangeStatus(false)
      }
    }
  }
  ,created() {
    this.activeStat = this.dialog
  },methods:{
    ChangeStatus(activeStat){
      this.$emit('result' , {item : this.item , activeStat })
    },
    DeleteItem(){
      console.log(this.item)
      this.loading = true;
      this.disable= true;
      this.$store.dispatch(this.action , this.item.id).then(res =>{
        this.loading = false;
        this.disable= false;
        this.$toastr.s(this.$i18n.t("SuccessDeleteMessage"));
        this.ChangeStatus(true);

      });
    }
  }
}
</script>

<style scoped>

</style>