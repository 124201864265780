<template>
<v-container fluid class="dashboard-container" >
  <v-row>
    <v-col cols="12" md="4">
      <AppDropDown :title="$t('SelectCounty')" :data-id="CountryId" :action="GetCountry.id !== 0 ?'' : 'regionsModule/GetAllCountries'" @Selected="CountrySelected" :getter="'regionsModule/GetAllCountries'" :item="GetCountry" ></AppDropDown>
    </v-col>
  </v-row>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <GovernmentsGrid v-if="selected"  :selected="this.selected"></GovernmentsGrid>
    </v-col>
  </v-row>

</v-container>
</template>

<script>
import AppDropDown from "@/components/General/AppDropDown";
import GovernmentsGrid from "@/components/Regions/Governments/GovernmentsGrid";
export default {
  name: "Governments",
  components: {GovernmentsGrid, AppDropDown},
  data(){
    return {
      CountryId : 0,
      selected : null
    }
  },
  computed : {
    GetCountry(){
      let item =  this.$store.getters["regionsModule/GetAllCountries"].find(s=>{return s.id === this.CountryId});
      if(item){
        return { id:item.id};
      }else{
        return {id:0}
      }


    }
  },
  created() {
    this.CountryId = parseInt(this.$route.params.CountryId);
    this.selected = {
      id : this.CountryId
    }
  },
  methods:{
    CountrySelected(item){
      this.CountryId = item.id;
      this.selected=item;
    }
  }
}
</script>

<style scoped>

</style>