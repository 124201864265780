<template>
  <v-container class="dashboard-container">
    <v-text-field v-model="latCoords.start" type="number" label="start lat" outlined>

    </v-text-field>
    <v-text-field type="number" v-model="latCoords.end" label="end lat" outlined>

    </v-text-field>
    <v-text-field type="number" v-model="lngCoords.start" label="start lng" outlined>

    </v-text-field>
    <v-text-field type="number" v-model="lngCoords.end" label="end lng" outlined>

    </v-text-field>
    <v-text-field type="number" v-model="km" label="km" outlined>

    </v-text-field>
    <v-text-field  v-model="code" label="country code" outlined>

    </v-text-field>
    <v-btn class="primary" @click="StartDownload">
        Start
    </v-btn>
  </v-container>
</template>
<script>
import AppHelper from "@/Helpers/AppHelper";


export default {
  data(){
    return {
      governments:[],
      country:null,
      latCoords:{
        start:0,
        end:0
      },
      lngCoords:{
        start:0,
        end:0
      },
      km:0,
      code : ""
    }
  },
  mounted() {
    debugger;
    let cachedData = localStorage.getItem('mapLoad')??null;
    if(cachedData){
      cachedData = JSON.parse(cachedData);
      this.latCoords = cachedData.latCoords;
      this.lngCoords = cachedData.lngCoords;
      this.km = cachedData.km;
      this.code = cachedData.code;
    }
  },
  methods:{
    async StartDownload(){
      localStorage.setItem('mapLoad' , JSON.stringify({latCoords :this.latCoords
        , lngCoords:this.lngCoords , code :this.code , km : this.km  }) );

      if(this.km === 0) return;
      for(let lat = parseFloat(this.latCoords.start)+(parseFloat(this.km)/110);
          lat<parseFloat(this.latCoords.end) ; lat+=(parseFloat(this.km)/110)){
        for(let lng = parseFloat(this.lngCoords.start)+(parseFloat(this.km)/100); lng<parseFloat(this.lngCoords.end) ; lng+=(parseFloat(this.km)/100)){
          await this.GetAddress(lat , lng)
        }
      }
      localStorage.setItem('CountryDetails' , JSON.stringify({country :this.country , governments:this.governments }) );


      console.log("Done!")
    },

    GetAddressComponents(res ){
      let adCom = null;
      adCom = res.data.results.find(ad=>{
        return ad.types.includes('administrative_area_level_2');
      });
     return adCom? adCom.address_components : [];
    }
    ,async GetAddress(lat , lng){
      let country_ar = '';
      let country_en = '';
      let government_ar = '';
      let government_en= '';
      let region_ar = '';
      let region_en = '';
      return await AppHelper.GetCurrentLocationFromCoords({lat , lng} , 'ar').then(res=>{
        let com_ar =this.GetAddressComponents(res);
          if(com_ar.length<1)return;
          country_ar = this.GetCountry(com_ar);
          government_ar = this.GetGovernment(com_ar);
          region_ar = this.GetRegion(com_ar);
          if(!country_ar ||  government_ar === '')return null;

        return AppHelper.GetCurrentLocationFromCoords({lat , lng} , 'en').then(res2=>{
          let com_en = this.GetAddressComponents(res2);

          country_en = this.GetCountry(com_en);
          government_en = this.GetGovernment(com_en);
          region_en = this.GetRegion(com_en);
          if(!this.country && country_ar.short_name.toLowerCase() === this.code){
            this.country = {
              nameAr : country_ar.long_name,
              code : country_ar.short_name,
              nameEn : country_en.long_name,
            }
          }

            if(this.country&&this.code === country_ar.short_name.toLowerCase()){
              let gov = this.governments.find(s=>{
                return s.nameEn.includes(government_en)
              });
              console.log({government_ar , region_ar})
              console.log({government_en  , region_en})
              if(!gov){
                gov = {
                  nameAr : government_ar,
                  nameEn:government_en,
                  regions:[]
                }
                this.governments.push(gov)

              }
              if(region_ar !== ''){
                let reg = gov.regions.find(s=>{
                  return s.nameEn.includes(region_en)
                });
                if(!reg){
                  reg = {
                    nameAr : region_ar,
                    nameEn : region_en,
                  }
                  gov.regions.push(reg);
                }
              }

            }


        });
      });

    }


    ,GetCountry(address) {
      return address.find(s => s.types.includes('country'));
    },
    GetGovernment(address) {
      let component = address.find(s=>s.types.includes('administrative_area_level_1'));
      if(!component) return '';
      return component.long_name.replace('Governorate' , '').replace('محافظة' , '');
    },
    GetRegion(address){
      let component = address.find(s=>s.types.includes('administrative_area_level_2'));
      if(!component) return '';
      if(component.long_name.includes('الظهير الصحراوى'))return '';
      return component.long_name.replace('Governorate ' , '')
          .replace('محافظة ' , '')
          .replace('قسم ' , '')
          .replace( 'مركز ' , ''  )
          .replace( ' Desert' , ''  )
    }
  }
}
</script>