import UsersService from "@/Services/UsersService";

export const usersModule = {
    namespaced : true,
    state:{
        users :{},
        user : {}
    },actions:{
        GetAll({commit} , obj){
            return UsersService.GetAllUsers(obj).then(res =>{
                commit('setUsers' , res);
                return Promise.resolve(res);
            });
        },
        SaveUser({commit} , payload){
            return UsersService.SaveUser(payload).then(res=>{
               return Promise.resolve(res);
            });
        },
        GetUserById({commit} , id){
            return UsersService.GetById(id).then(res=>{
                return Promise.resolve(res)
            });
        },
        AttachUsers({commit} , payload){
            return UsersService.AttachUsers(payload).then(res=>{
                return Promise.resolve(res);
            })
        }
    }
    ,mutations: {
        setCurrentPage(state , page){
            state.users.CurrentPage = page;
        },
        setUsers(state , users){
            state.users = users;
        }
        ,setUser(state , user){
            state.user = user
        }
    },getters:{
        GetAllUsers : (state)=>{
            return state.users;
        }
    }
}