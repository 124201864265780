import RegionsService from "@/Services/RegionsService";

export const regionsModule = {
    namespaced : true,
    state:{
        countries :[],
        governments :[],
        regions :[],
        activatedCountries: []
    },actions:{
        GetAllCountries({commit} ){
            return RegionsService.GetAllCountries().then(res =>{
                commit('setCountries' , res);
                return Promise.resolve(res);
            });
        },
        GetAllRegionsByGovernmentId({commit} , id){
          return RegionsService.GetAllRegionsByGovernmentId(id).then(res=>{
            commit('setRegions' , res);
            return Promise.resolve(res);
          });
        },
        GetAllActivatedCountries({commit}){
            return RegionsService.GetAllActivatedCountries().then(res =>{
                commit('setActivatedCountries' , res);
                return Promise.resolve(res);
            });
        },
        SetDefaultCountries({commit} , countries){
            commit("setActivatedCountries" , countries);
        },
        GetCountryByCode({commit} , code){
            debugger;
            return RegionsService.GetCountryByCode(code).then(res =>{
                commit('SetDefaultAppCountry' , res);
                return Promise.resolve(res);
            });
        },

        GetCounty({commit} , id){
            return RegionsService.GetCountry(id).then(res =>{

                return Promise.resolve(res);
            });
        },

        GetGovernment({commit} , id){
            return RegionsService.GetGovernment(id).then(res =>{

                return Promise.resolve(res);
            });
        },
        deleteGovernment({commit} , id){
            return RegionsService.deleteGovernment(id).then(res =>{
                commit('deleteGovernment' , id);
                return Promise.resolve(res);
            });
        },
        deleteCountry({commit} , id){
            return RegionsService.deleteCountry(id).then(res =>{
                commit('deleteCountry' , id);
                return Promise.resolve(res);
            });
        },
        DeleteAirport({commit} , id){
            return RegionsService.DeleteAirport(id).then(res =>{
                commit('deleteAirport' , id);
                return Promise.resolve(res);
            });
        },
        GetGovernmentsByCountryId({commit} , id){
            return RegionsService.GetGovernmentsByCountryId(id).then(res =>{
                commit("setGovernments" , res);
                return Promise.resolve(res);
            });
        },
        SaveCountry({commit} , payload){
            return RegionsService.SaveCountry(payload).then(res=>{
                commit("setCountry" ,res);
                return Promise.resolve(res);
            });
        },
        SaveGovernment({commit} , payload){
            return RegionsService.SaveGovernment(payload).then(res=>{
                commit("setGovernment" , res);
                return Promise.resolve(res);
            });
        },
        ActivateCountry({commit} , payload){
            return RegionsService.ActivateCountry(payload).then(res=>{
                commit("ActiveCountry" , payload);
                return Promise.resolve(res);
            });
        },
        SaveCountryDetails({commit} ,payload){
            return RegionsService.SaveCountryDetails(payload);
        },
        SaveAirport({commit} ,payload){
            return RegionsService.SaveAirport(payload).then(res=>{
                commit('setRegion' , res);
                return Promise.resolve(res);
            });
        }
    }
    ,mutations: {
        SetDefaultAppCountry(state , res){
            debugger;
            localStorage.setItem('countryId' , res.id);
        },
        setCountries(state , countries){
            state.countries = countries;
        },
        setActivatedCountries(state , countries){
            state.activatedCountries = countries;
        },
        setGovernments(state , governments){
            state.governments = governments;
        },
        setCountry(state, country){
            let countryIndex = state.countries.findIndex(s=>s.id === country.id);
            console.log(country);
            state.countries[countryIndex] = country;
        },
        setGovernment(state, Government){
            let countryIndex = state.governments.findIndex(s=>s.id === Government.id);
            if(countryIndex === -1){
                state.governments.push(Government)
            }else{
                state.governments[countryIndex] = Government;

            }
        },
        setRegions(state , res ){
          state.regions = res;
        },
        deleteCountry(state , id){
            state.countries.splice(state.countries.findIndex(s=>s.id===id) , 1);
        },
        deleteAirport(state , id){
            state.regions.splice(state.regions.findIndex(s=>s.id===id) , 1);
        },
        deleteGovernment(state , id){
            state.governments.splice(state.governments.findIndex(s=>s.id===id) , 1);
        },
        ActiveCountry(state , obj){
            let countryIndex = state.countries.findIndex(s=>s.id === obj.CountryId);
            state.countries[countryIndex].isActive= obj.Status;
        },
        setRegion(state , res){
           let countryIndex = state.regions.findIndex(s=>s.id === res.id);
            if(countryIndex === -1){
                state.regions.push(res)
            }else{
                state.regions[countryIndex] = res;
            }
        }

    },getters:{
        GetAllCountries : (state)=>{
            return state.countries;
        },
        GetAllGovernment : (state)=>{
            return state.governments;
        },
        GetAllActivatedCountries : (state)=>{
            return state.activatedCountries;
        }
        ,GetAllRegions:(state)=>{
            return state.regions
        }
    }
}