import axios_instance from "./ApiService";
class UserWalletService {
    controllerName = "/UserWallet";

    GetAllCurrencies(){
        return axios_instance.get(this.controllerName+"/GetAllCurrencies").then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.resolve(err);
        })
    }

    GetWalletByUser(id) {
        return axios_instance.get(this.controllerName+"/GetWalletByUser" , {params:{
                UserId:id
            }}).then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.resolve(err);
        })
    }
    AccountStatement() {
        return axios_instance.get(this.controllerName+"/AccountStatement").then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.resolve(err);
        })
    }

    GetAllTransactions(payload) {
        return axios_instance.get(this.controllerName + '/GetUserWalletTransactions',{params:payload}).then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.reject(err);
        });
    }
    GetWalletTransactions(payload) {
        return axios_instance.get(this.controllerName + '/GetWalletTransactions',{params:payload}).then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.reject(err);
        });
    }

    SubscribeToPackage(payload) {
        return axios_instance.post(this.controllerName+'/SubscribeToPackage' , null  , {
            params:payload
        }).then(res=>{
            if(res.data.isError){
                return Promise.reject(res.data.errorMessage)
            }
            return Promise.resolve(res.data);
        })
    }

    TransferPoints(payload) {
        return axios_instance.post(this.controllerName+'/TransferPoints' , null  , {
            params:payload
        }).then(res=>{
            if(res.data.isError){
                return Promise.reject(res.data.errorMessage)
            }
            return Promise.resolve(res.data);
        });
    }

    GetWalletByServicePlaceId(id) {

    }
}

export default new UserWalletService();