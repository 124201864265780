import i18n from "@/plugins/i18n/i18n";
export default [
    {
        icon: 'mdi-account-multiple-plus',
        title: i18n.t("UsersManagement") ,
        to: {name : "PlaceUsers"},
        permission  : "UsersControl",
        roles:['BranchManager']
    },
    {
        icon: 'mdi-account-multiple-plus',
        title: i18n.t("BranchData") ,
        to: {name : "BranchData"},
        permission  : "EditBranch",
        roles  : ['BranchManager'],
    },
    {
        icon: 'mdi-cart-percent',
        title: i18n.t("Order.CurrentOrders") ,
        to: {name : "BranchCurrentOrders"},
        permission  : "OrdersControl",
        roles  : ['BranchManager'],
    },
];