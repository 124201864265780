import VueProgressBar from 'vue-progressbar'
import Vue from 'vue'
const options = {
    color: '#5ad3ff',
    failedColor: '#874b4b',
    thickness: '5px',
    transition: {
      speed: '2s',
      opacity: '0.7s',
      termination: 1000
    },
  
    location: 'top',
    inverse: false
}
Vue.use(VueProgressBar , options);
export default VueProgressBar;