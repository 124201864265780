<template>
  <v-select
      :items="GetAll"
      :label="$t('period')"
      @change="ChangeDropDown"
      outlined
      :error-messages="errorMessages"
      :rules='[Rules.required]'
      dense
      v-model="selected"
  ></v-select>
</template>

<script>
import { rules } from '../../Helpers/ValidationRules';
export default {
  name: "MonthsNumersDropDown",
  props:{
    item:Number
  },
  data(){
    return {
        errorMessages:'',
        Rules :rules,
      MONTHS_NUMBERS_LIST:[6,12,18,24,30,36,42,48],

      selected : {text:'' , value :0}
    }
  },
  created(){
    this.initialize();
  },

  computed:{
    GetAll(){
      let Months_Numbers = this.MONTHS_NUMBERS_LIST;
      let month_text = this.$i18n.t('Month');
      return Months_Numbers.map(s=>{
        return {
            text : `${s} ${month_text}`,
            value: s
        }
      });
    }
  },
  watch:{
    item(val){
        console.log(val);
        this.initialize();
    }
  },
 
  methods:{
    initialize(){
        debugger;
     if(this.item){
        this.selected=this.GetAll.filter(s=>{
            return s.value === this.item
        })[0];
    }
    },
    ChangeDropDown(item){
      this.$emit('Selected' , item);
    }
  }
}
</script>

<style scoped>

</style>