import i18n from "@/plugins/i18n/i18n";

export default [
    {
        icon: 'mdi-home',
        title: i18n.t("Home") ,
        to: {name : "SalesHome"},
        roles:['SalesConsultant']
    },

    {
        icon: 'mdi-account-cash-outline',
        title: i18n.t("AddPackagesToUsers") ,
        to: {name : "UserPackageSubscribe"},
        permission  : "AddPoints",
        roles:['SuperAdmin' , 'Moderator' , 'SalesConsultant']
    },
];