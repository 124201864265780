<template>
  <v-container class="dashboard-container container--fluid">
    <countries-grid></countries-grid>
  </v-container>

</template>

<script>
import CountriesGrid from "@/components/Regions/Countries/CountriesGrid";
export default {
  name: "Regions",
  components: {CountriesGrid}
}
</script>

<style scoped>

</style>