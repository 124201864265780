<template>
  <div class="dashboard-container">

    <CategoriesGrid>

    </CategoriesGrid>
  </div>
</template>

<script>
import CategoriesGrid from "@/components/Categories/CategoriesGrid";
export default {
  name: "Categories",
  components: {CategoriesGrid}
}
</script>

<style scoped>

</style>