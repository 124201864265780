<template>
  <v-col md="3" sm="6">
    <v-card
        class="mx-auto"
    >
      <v-card-text>
        <p class="text-h4 text--primary">
          <img  style="max-width: 50px"  class="v-responsive custom-float-right" :src="App_url +( pointsPackage.country?pointsPackage.country.imagePath : '') ">
          {{pointsPackage.name}}
        </p>
        <p class="text-h6 text--primary" v-if="pointsPackage.country">
        </p>
        <p class="text-h6">{{pointsPackage.points}}</p>
        <p class="text-h6" style="color: #00cc00">{{pointsPackage.balance}} <small>{{pointsPackage.currency.prefix}}</small></p>
        <p>{{ $t('periodMessage' ,{limit : pointsPackage.monthsNumber}  )}}</p>
      <DeleteDialog v-if="dialog" :item="{id:dataObj.id}" :dialog="dialog" @result="DeleteDialogResult" :action="'pointsPackageModule/deletePointsPackage'"></DeleteDialog>
      </v-card-text>
      <v-card-actions>
        <v-btn
            @click="OpenDialog"
            color="red"
        >
          {{$t('Delete')}}
        </v-btn>
        <v-btn
            @click="SelectItem"
            color="teal accent-4"
        >
          {{$t('Edit')}}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-col>
</template>

<script>

import DeleteDialog from '../General/DeleteDialog.vue';
import  {APP_URL} from '@/Helpers/Config'
export default {
  name: "PointPackage",
  props: {
    pointsPackage: Object
  },
  data() {
    return {
      dataObj: this.pointsPackage,
      dialog: false,

      App_url : APP_URL
    };
  },
  methods: {
    SelectItem() {
      this.$emit("Selected", this.dataObj.id);
    },
    DeleteDialogResult(res) {
      this.dialog = false;
    },
    OpenDialog() {
      this.dialog = true;
    }
  },

  components: {DeleteDialog}
}
</script>

<style scoped>

</style>