<template>
  <v-dialog
      v-model="activeStat"
      max-width="800px"
  >

    <v-card>
      <v-card-title>
        {{ item === null ? $t('AddCity') : $t('EditCity') }}
      </v-card-title>

      <v-card-text>
        <EditCityForm  :government-id="this.governmentId" :item="item" @edited="OnResult">
        </EditCityForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EditCityForm from "@/components/Regions/Cities/EditCityForm";
export default {
  name: "EditCityDialog",
  components: {EditCityForm},
  props:{
    dialog:Boolean,
    item:Object,
    governmentId : 0,
    editedIndex : Number
    },data(){
      return {
        activeStat : this.dialog
      }
  }
  ,methods:{
    save(){
      this.$emit('edited' , {item : this.item , index : this.editedIndex})
    }
    ,close(){
      this.activeStat = false;
      this.editedIndex = -1;
      this.$emit('edited' , {item : null , index : this.editedIndex})
    },
    OnResult(res){
      console.log(res)
      this.$emit('edited' , {item : res , index : this.editedIndex})
    }
  },watch:{
    dialog (val) {
      this.activeStat = val;
    },
    activeStat(val){
      this.$emit('edited' , {item : null , index : this.editedIndex})
    }
  },
}
</script>

<style scoped>

</style>