import PointsPackageService from "@/Services/PointsPackageService";
export const pointsPackageModule = {
    namespaced : true,
    state:{
        pointsPackages :[],
        pointsPackage :{} ,
        subscribeStat:{
            loginFirst:false,
            done:false,
            item: null
        }
    },actions:{
        OpenLoginDialog({commit} , payload){
            commit('SetSubscribeStat' , payload)
        },
        GetAllPointsPackages({commit}){
            return PointsPackageService.GetAllPointsPackages().then(res =>{
                commit('setPointsPackages' , res);
                return Promise.resolve(res);
            });
        },
        GetAllPaymentRequestsById({commit} , userId){
            return PointsPackageService.GetAllPaymentRequestsById(userId).then(res =>{
                return Promise.resolve(res);
            });
        },
        GetById({commit} , Id){
            return PointsPackageService.GetById(Id).then(res =>{
                commit('SetPointPackage' , res);
                return Promise.resolve(res);
            });
        },
        GetAllPointsPackagesByCountryId({commit} ,Id){
            return PointsPackageService.GetAllByCountryId(Id).then(res =>{
                commit('setPointsPackages' , res);
                return Promise.resolve(res);
            });
        },
        SavePointsPackage({commit} , payload){
            return PointsPackageService.SavePointsPackage(payload).then(res=>{
                commit('setPointsPackageInList' , res);
                return Promise.resolve(res);
            });
        },
        deletePointsPackage({commit} ,id){
            return PointsPackageService.deletePointsPackage(id).then(res=>{
                commit('deletePointsPackage' , id);
                return Promise.resolve(res);
            });
        },
        CompleteSubscription({commit} , loginStatus){
            commit('CompleteSubscription' , loginStatus);
        },
        CompletePayment({commit}){
            commit('CompletePayment');
        },

        SendPaymentRequest({commit} , payload){
            return PointsPackageService.SendPaymentRequest(payload).then(res=>{
               return Promise.resolve(res);
            });
        }

    }
    ,mutations: {
        CompletePayment(state){
          state.subscribeStat.done = false;
          state.subscribeStat.loginFirst = false;

        },

        CompleteSubscription(state , loginStatus){
          if(loginStatus){
              state.subscribeStat.done = true;
          }
          state.subscribeStat.loginFirst = false;
        },
        SetSubscribeStat(state , res){
          state.subscribeStat.loginFirst = res.loginFirst;
          state.subscribeStat.item = res.item;
        },
        setPointsPackages(state , res){
            state.pointsPackages = res;
        },
        SetPointPackage(state , res){
            state.pointsPackage = res;
        },
        setPointsPackageInList(state , res){
            debugger;
           let ob=  state.pointsPackages[state.pointsPackages.findIndex(s=>s.id === res.id)];
           if(ob){
            state.pointsPackages[state.pointsPackages.findIndex(s=>s.id === res.id)] = res;
           }
           else{
            state.pointsPackages.push(res);
           }
        },
        deletePointsPackage(state , id){
            state.pointsPackages.splice(state.pointsPackages.findIndex(s=>s.id === id) , 1);
        }

    },getters:{
        GetAllPointsPackages : (state)=>{
            return state.pointsPackages;
        },

        GetPointPackage : (state)=>{
            return state.pointsPackage;
        },
        IsRequireLogin:(state)=> {
            return state.subscribeStat.loginFirst;
        },
        IsRequireLoginDone:(state)=> {
            return state.subscribeStat.done;
        },
        GetSubscribeStat:(state)=>{
            return state.subscribeStat;
        },
        GetById:state=>id=>{
            return state.pointsPackages.find(s=>{return s.id === id});
        }
    }
}