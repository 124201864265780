<template>
  <section id="hero" class="app-container">
    <div style="background-color:#7de2a7; height: auto;" class="mt-8 pt-5">
      <v-container class="container--fluid" >
        <v-row style="" class="pt-5 ma-4">

          <v-col cols="12"  xl="8" lg="8" md="8" sm="12"  class="align-center" >
            <div style="color:white" class="filter-content mt-lg-12">
              <h1 class="text-lg pt-2 pb-2">{{$t('SearchHomeSectionText')}}</h1>
              <p class="pt-2 pb-2" style="font-size: 20px">{{$t('OnOfferWorldText')}}</p>
                <v-row class="container-row item-group pa-0 ma-0" >
                  <v-col cols="12" lg="6" md="6" sm="12" class="pl-1 pr-1">

                    <v-card flat link :to="{name:'ServicePlacesFilter'}"  class="pa-4">
                      <v-card-title style="color:#c44118 !important;"><v-icon color="#c44118" >
                          mdi-toy-brick-search-outline
                      </v-icon> <h4 class="ml-2 mr-2" >{{$t('Places')}} </h4></v-card-title>

                      <v-card-subtitle>{{$t('PlacesText')}}  <v-icon class="custom-float-right" color="#c44118">mdi-arrow-left-thin </v-icon>  </v-card-subtitle>
                    </v-card>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12" class="pl-1 pr-1">
                    <v-card flat link  :to="{name:'OffersFilter'}"  class="pa-4">
                      <v-card-title style="color:#c44118 !important;"><v-icon  color="#c44118">
                        mdi-tag-search
                      </v-icon> <h4 class="ml-2 mr-2" >{{$t('Offers')}}</h4></v-card-title>

                      <v-card-subtitle>  {{$t('OffersText')}}<v-icon class="custom-float-right" color="#c44118">mdi-arrow-left-thin </v-icon>  </v-card-subtitle>
                    </v-card>
                  </v-col>

<!--                  <v-col cols="12" class="ma-0 pa-0 input-container" xl="3" lg="12" md="12">-->
<!--                    <v-text-field-->
<!--                        background-color="white"-->
<!--                        class="filter-text-field ma-2 pa-1"-->
<!--                        prepend-inner-icon="mdi-magnify"-->
<!--                        flat-->
<!--                        solo-->
<!--                        hide-details="true"-->
<!--                        :placeholder="$t('SearchFor')"-->
<!--                    >-->

<!--                    </v-text-field>-->
<!--                  </v-col>-->
<!--                  <v-col cols="12"  class="ma-0 pa-0 input-container" xl="3" lg="4" md="4">-->
<!--                    <v-text-field  background-color="white"-->
<!--                                   class="filter-text-field ma-2 pa-1"-->
<!--                                   flat-->
<!--                                   solo-->
<!--                                   hide-details="true"-->

<!--                                   prepend-inner-icon="mdi-map-marker-radius"-->
<!--                                   color="orange" :placeholder="$t('Location')">-->

<!--                    </v-text-field>-->
<!--                  </v-col>-->
<!--                  <v-col cols="12" class="ma-0 pa-0 input-container" xl="4" lg="4" md="4">-->
<!--                    <v-select-->
<!--                        background-color="white"-->
<!--                        class="filter-text-field filter-select ma-2 pa-1"-->
<!--                        flat-->
<!--                        solo-->

<!--                        hide-details-->
<!--                        :items="GetAllCategories"-->

<!--                        prepend-inner-icon="mdi-apps"-->
<!--                        color="orange"-->
<!--                        :placeholder="$t('Category')"-->
<!--                    >-->

<!--                    </v-select>-->
<!--                  </v-col>-->
<!--                  <v-col cols="12"-->
<!--                         class="input-container text-center pr-0"-->
<!--                          md="4"-->
<!--                         xl="2"-->
<!--                         lg="3">-->
<!--                    <v-btn-->
<!--                        x-large-->
<!--                        class="pa-6 elevation-0 btn-search">-->
<!--                      ابحث الان-->

<!--                    </v-btn>-->

<!--                  </v-col>-->
                </v-row>

            </div>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12"  >
            <v-img max-width="500" min="400" class="" src="@/assets/tarfih_log_hor.png"></v-img>
          </v-col>
        </v-row>

      </v-container>


    </div>
    <v-container fluid  id="features" class="mt-2">

<!--      <v-row>-->
<!--        <v-col cols="12" justify="center">-->
<!--          <h2>الاقسام</h2>-->
<!--        </v-col>-->
<!--        <v-col v-for="(feature, i) in GetAllCategories"-->
<!--               :key="`cat_col_${i}`"  cols="12" md="3" lg="3" sm="6">-->
<!--          <v-card-->
<!--              class="mx-1 cat-slide"-->
<!--              flat-->
<!--              min-height="200"-->
<!--              max-height="200"-->
<!--          >-->

<!--            <v-img-->
<!--                :src="APP_URL+feature.value.img"-->
<!--                class="white&#45;&#45;text align-center pa-3 text-center v-responsive cat-image"-->
<!--                gradient="to bottom, rgba(0,0,0,.55), rgba(0,0,0,.5)"-->
<!--                min-height="200"-->
<!--                max-height="200"-->

<!--            >-->
<!--              <h2 class="cat-img-text" style="color: #FFFFFF!important;" >{{feature.value.name}}</h2>-->

<!--              <v-btn   class="elevation-0 cat-btn">{{$t('Browse')}}</v-btn>-->



<!--            </v-img>-->

<!--          </v-card>-->
<!--        </v-col>-->
<!--      </v-row>-->
      <v-row align="center" justify="center">
        <v-col cols="12" class="text-center">
          <h2>{{$t("Categories")}}</h2>
        </v-col>
        <v-col cols="12">


            <v-sheet
                class="mx-auto"
                width="100%"
            >
              <v-slide-group
                  v-model="model"
                  show-arrows

                  style="background-color: transparent"
              >
                <v-slide-item
                    v-for="(feature, i) in GetAllCategories"
                    :key="`cat_${i}`"
                    :tabindex="i"
                    v-slot="{ active, toggle }"
                >
                    <v-card
                        class="mx-1 cat-slide"
                        flat
                        @click="toggle"

                    >
                      <v-img
                          :src="APP_URL+feature.value.img"
                          class="white--text align-center pa-3 text-center v-responsive cat-image"
                          gradient="to bottom, rgba(0,0,0,.55), rgba(0,0,0,.5)"

                      >
                        <h2 class="cat-img-text" style="color: #FFFFFF!important;" >{{feature.value.name}}</h2>

                      </v-img>

                    </v-card>

                </v-slide-item>
              </v-slide-group>


            </v-sheet>

        </v-col>
      </v-row>
    </v-container>

  </section>

</template>

<script>
import {APP_URL} from "@/Helpers/Config";
export default {
  name: "HomeSection",
  data() {
    return {
      APP_URL,
      dialog: false,
      model : null,
      videoId: "i8IvvHJssWE",
      listCatsIcons : new Map(),

    };
  },
  computed:{
    GetAllCategories(){
      return this.$store.getters['categoriesModule/GetAllCategories'].map((el , index)=>{
        return {
          text : el.name ,
          value: {
            name : el.name,
            index : index,
            id : el.id,
            img:el.imgePath?el.imgePath:''
          }
        }
      });
    }
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },
  created() {
    let map  =  new Map();


  },
  methods: {
    handleScroll(event){
      console.log(window.top.scrollY);

    }
  },
};
</script>

<style scoped>
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}
.cat-btn{
  border-radius: 0;
}

.item-group{
  background:  transparent!important;
}
.cat-slide{
  max-width: 300px;
  min-width: 300px;
  max-height: 200px;
  min-height: 200px;
}
.cat-slide  .cat-image{
  max-height: 200px;
  min-height: 200px;
}
.cat-img-text{
  font-size: 1.2rem;
}
@media screen and (max-width: 900px){
  .cat-slide{
    max-width: 200px;
    min-width: 200px;
    max-height: 150px;
    min-height: 150px;
  }
  .cat-slide  .cat-image{
    max-width: 200px;
    min-width: 200px;
    max-height: 150px;
    min-height: 150px;
  }
  .cat-img-text{
    font-size: .9rem;
  }
}


</style>
