<template>
  <div>
    <p class="text-h4">
      {{ IsMain? $t('ServicePlace.MainBranchData') : $t('ServicePlace.BranchData')}}
    </p>
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="itemData.nameAr"
                outlined
                dense
                :error-messages="errorMessages"
                :rules="[rules.required]"
                :label="$t('NameAr')"
                @input="ChangeFormData"
            >

            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="itemData.nameEn"
                outlined
                dense
                :error-messages="errorMessages"
                :rules="[rules.required]"
                :label="$t('NameEn')"
                @input="ChangeFormData"
            >

            </v-text-field>
          </v-col>
          <v-col md="6">
            <AppDropDown :validate="true" :item="{id:itemData.countryId}" :title="$t('SelectCounty')" :filter="location?location.country:''"  :action="itemData.countryId !== 0 ?'' : 'regionsModule/GetAllActivatedCountries'"  :getter="'regionsModule/GetAllActivatedCountries'"  @Selected="CountrySelected" ></AppDropDown>
          </v-col>
          <v-col md="6">
            <AppDropDown  :validate="true" :item="{id:itemData.governmentId}" :title="$t('SelectGovernment')" :filter="location?location.government:''" :data-id="itemData.countryId" :action="'regionsModule/GetGovernmentsByCountryId'"  :getter="'regionsModule/GetAllGovernment'"  @Selected="GovernmentSelected" ></AppDropDown>

          </v-col>
          <v-col md="6">
            <AppDropDown  :validate="true" :item="{id:itemData.regionId}" :title="$t('SelectRegion')" :filter="location?location.region:''" :data-id="itemData.governmentId" :action="'regionsModule/GetAllRegionsByGovernmentId'"  :getter="'regionsModule/GetAllRegions'"  @Selected="RegionSelected" ></AppDropDown>

          </v-col>
          <v-col cols="12">
            <v-text-field
                v-model="itemData.addressAr"
                outlined
                dense
                :error-messages="errorMessages"
                @input="ChangeFormData"
                :label="$t('addressAr')"
                :rules="[rules.required]"
            >

            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
                v-model="itemData.addressEn"
                outlined
                dense
                :error-messages="errorMessages"
                :rules="[rules.required]"
                :label="$t('addressEn')"
                @input="ChangeFormData"
            >

            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu
                ref="menu"
                v-model="start_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value="itemData.startString"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="itemData.startString"
                    :label="$t('ServicePlace.StartTime')"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly

                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                  v-if="start_menu"
                  v-model="itemData.startString"
                  full-width
                  @click:minute="$refs.menu.save(itemData.StartString)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu
                ref="menu"
                v-model="end_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value="itemData.endString"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="itemData.endString"
                    :label="$t('ServicePlace.EndTime')"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                  v-if="end_menu"
                  v-model="itemData.endString"
                  full-width
                  @click:minute="$refs.menu.save(itemData.EndString)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <AppMap  :coords="{lat : itemData.lat , lng : itemData.lng}" @result="MapResult"></AppMap>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {rules} from "@/Helpers/ValidationRules";
import AppMap from "@/components/General/AppMap";
import AppDropDown from "@/components/General/AppDropDown";
export default {
  props:{
    IsMain:Boolean,
    item:Object
  },
  components:{
    AppMap,
    AppDropDown
  }
  ,
  data(){
    return {
      rules,
      start_menu:false,
      end_menu:false,
      location: null,
      errorMessages : '',
      itemData:{
        lat : 0,
        lng :0,
        addressAr : "",
        addressEn : "",
        nameAr : "",
        nameEn : "",
        countryId:0,
        governmentId:0,
        regionId:0,
        startString:'00:00',
        endString:'00:00'
      },
    }
  },
  created() {
    if(this.item){
      this.itemData = this.item;
      this.ChangeFormData();
    }
  },
  watch:{
    item(val){
      this.itemData = val
    }
  }
  ,
  name: "ServicePlaceBranchForm",
  methods:{
    ChangeFormData(){
      this.$emit('Changed' , this.itemData);
    },
    RegionSelected(item) {
      this.itemData.regionId = item.id
      this.ChangeFormData()
    },
    CountrySelected(item) {
      this.itemData.countryId = item.id
      this.ChangeFormData()
    }
    ,GovernmentSelected(item){
      this.itemData.governmentId = item.id;
      this.ChangeFormData();
    },
    MapResult(loc){
      this.itemData.lat = loc.coords.lat;
      this.itemData.lng = loc.coords.lng;
      this.itemData.addressAr = loc.address_ar;
      this.itemData.addressEn = loc.address_en;
      this.location = loc;
      this.ChangeFormData();
    },
  }
}
</script>

<style scoped>

</style>