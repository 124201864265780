<template>
  <v-navigation-drawer
      v-model="drawer_value"
      :mini-variant="miniVariant"
      :clipped="clipped && getLoginStatus"
      fixed
      app
      temporary
      :class="[hideSysBar? 'mt-12' : '']"
      :right="$vuetify.rtl"
       
  >
    <v-list
      >
      <v-list-item
            v-for="(item, i) in main_list_items"
            :key="i"
            v-if="hasPermission(item.permission) && hasRole(item.roles)"
            :to="item.to"
            :exact="$route.name !== item.to.names"

        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title  v-text="item.title" >

            </v-list-item-title >

          </v-list-item-content>
          <v-list-item-action v-if="item.countName && item.count(item.countName)&&item.count(item.countName) > 0">
            <v-badge  :content="item.count(item.countName)"></v-badge>
          </v-list-item-action>
        </v-list-item>
      
      <!--clients-->
      
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import MainList from "@/Helpers/RouteMenueItmes/SideBarMenuList";
export default {
  name: "Sidebar",
  data : ()=>{
    return {
      drawer_value:false,
      selectedItem: 1,
      fixed: false,
    }
  },props : {
    hideSysBar : Boolean,
    drawer : Boolean,
    clipped:Boolean,
    miniVariant:Boolean
  },
  created() {
    this.drawer_value = this.drawer;
  },

  watch:{
    drawer_value(val){
      this.$emit("DrawerEvent" , this.drawer_value);
    }
  },
  methods:{
    hasPermission(permission){
      if(permission === null || permission === "" || permission === undefined)return true;
      return this.$store.getters['authModule/HasPermission'](permission);
    },
    hasRole(permission){
      if(permission === null || permission === "" || permission === undefined)return true;
      return this.$store.getters['authModule/HasRole'](permission);
    }

  }, computed:{
    getLoginStatus(){return this.$store.getters['authModule/IsLoggedIn'];},
    main_list_items(){return MainList }
  },

}
</script>

<style scoped>

</style>