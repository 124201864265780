<template>
  <div class="auth-wrapper auth-v1"  >
    <div class="auth-inner" style="width: 45rem!important;">
      <v-card class="auth-card" elevation="6" style="border:3px solid #9197b2">
        <!-- logo -->
        <v-card-title v-if="showHeader" class="d-flex align-center justify-center py-7">
          <p style="color:#518dff" class="text-2xl font-weight-semibold text--primary mb-2">
            {{$t("WelcomeToRegister")}}

          </p>
          <router-link
              to="/"
              class="d-flex align-center"
          >

            <v-img
                :src="require('../assets/logo.png')"
                max-height="70px"
                max-width="300px"
                alt="logo"
                contain
                class="me-3 "
            ></v-img>
          </router-link>
        </v-card-title>
        <!-- title -->
        <v-card-text>
          <p class="mb-2 text-md-h5 black--text text-lg font-weight-500">
            {{$t("PleaseRegisterText")}}
            <v-btn
                link
                text

                color="primary"
                @click="GoToServicePlaceRegister"
                class="custom-float-Right font-weight-500 text-md-h6">
              {{$t('GoRegisterServicePlace')}}
            </v-btn>
            <v-btn @click="()=>{$emit('GoToLogin')}"
                   class="custom-float-right font-size-regular success">
              {{$t('Login')}}</v-btn>
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                v-if="!confirmationSent"
            >
              <v-text-field
                  ref="name"
                  v-model="form.UserName"
                  :rules="[() => !!form.UserName || $t('errors.requiredField')]"
                  :error-messages="errorMessages"
                  :label="$t('UserName')"
                  autocomplete="name"
                  filled
                  rounded
                  required
              ></v-text-field>
              <v-text-field
                  ref="email"
                  v-model="form.Email"
                  :rules="[() => !!form.Email || $t('errors.requiredField')]"
                  :error-messages="errorMessages"
                  :label="$t('Email')"
                  autocomplete="username"
                  filled
                  rounded
                  placeholder=""
                  required
              ></v-text-field>
              <PhoneInput :phone="form.Phone"
                          @Changed="ChangePhone"
                          :item="{code:form.PhoneCode , id:0, img : ''}"
              >
              </PhoneInput>
              <div id="recaptcha-container"></div>
              <v-text-field
                  ref="field3"
                  v-model="form.Password"
                  :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.min , rules.required , rules.symbol , rules.upper , rules.lower ,rules.number]"
                  :error-messages="errorMessages"
                  :label="$t('Password')"
                  value=""
                  filled
                  rounded
                  autocomplete="hggg"
                  aria-autocomplete="none"
                  :type="showPassword ? 'text' : 'password'"
                  placeholder=""
                  @click:append="showPassword = !showPassword"
                  required
              ></v-text-field>
              <v-btn

                  color="primary"
                  class="mt-6"
                  :loading="loading"
                  :disabled="disabled"
                  @click="sendOtp"
              >
                {{$t("Register")}}
              </v-btn>
              <v-btn
                  style="color: white!important;"
                  class="red mt-6 ml-4 mr-4 text--white"
                  @click="$emit('Close')"
              >
                {{$t("Cancel")}}
              </v-btn>
            </v-form>
          <v-form
            ref="codeform"
            v-model="codeFormValid"
            v-if="confirmationSent"

          >
            <v-text-field
                ref="email"
                v-model="ConfirmationCode"
                :rules="[() => !!form.Email || $t('errors.EnterValidCode')]"
                :error-messages="errorMessages"
                :label="$t('VerCode')"
                outlined
                aria-autocomplete="none"
                autocomplete="off"
                placeholder=""
                required
            ></v-text-field>
          <v-btn

              color="primary"
              class="mt-6"
              :loading="verloading"
              :disabled="verdisabled"
              @click="ConfirmCode"
          >
            {{$t("Confirm")}}
          </v-btn>
          <v-btn
              style="color: white!important;"
              class="red mt-6 ml-4 mr-4 text--white"
              @click="$emit('Close')"
          >
            {{$t("Cancel")}}
          </v-btn>
        </v-form>

        </v-card-text>

      </v-card>
    </div>

  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import {rules as Rules} from "@/Helpers/ValidationRules";
import PhoneInput from "@/components/General/PhoneInput";
import {getAuth , RecaptchaVerifier , signInWithPhoneNumber} from "firebase/auth";


export default {
  name:"RegisterSection",
  components:{
    PhoneInput
  },
  props:{
    showHeader:true
  },
  data() {
    return {
      showPassword : false,
      ConfirmationCode:'',
      confirmationSent:false,
      confirmationValid:false,
      isPasswordVisible:false,
      codeFormValid:false,
      appVerifier: '',
      valid:false,
      form:{
        Email : '',
        Password : '',
        UserName : '' ,
        Phone : '',
        RankString : 'User',
        PhoneCode:''
      },
      AppName : this.$t('AppName'),
      loading :false,
      disabled:false,
      verloading :false,
      verdisabled:true,
      errorMessages : '',
      rules: Rules,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  mounted() {
   this.initReCaptcha();
  },
  methods:{
    ChangePhone(item){
      this.form.Phone = item.phone;
      this.form.PhoneCode= item.phoneCode
    },
    SubmitUser(){
      if(!this.confirmationValid)return;
      if(this.valid){
        this.formHasErrors = false
        this.loading = true
        this.$store.dispatch('authModule/Register' , this.form).then(res=>{
        this.loading = false;
        this.disabled = true;
        this.$toastr.s(this.$i18n.t("SuccessSignUpMessage"));
        this.$emit('Close')
        }).catch(err=>{
          this.$toastr.e(err);
        });
      }else{
        this.$refs.form.validate();
      }
    },
    sendOtp(){
      debugger;
      if(!this.valid){
        this.$refs.form.validate();
      }else{
        //
        let countryCode = '+'+this.form.PhoneCode
        let phoneNumber = countryCode + this.form.Phone

        const auth = getAuth();
        this.loading=true;
        this.disabled= true;
        signInWithPhoneNumber(auth, phoneNumber, this.appVerifier)
            .then((confirmationResult) => {
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              window.confirmationResult = confirmationResult;
              this.loading=false;
              this.disabled= false;
              this.verdisabled =false;
              this.confirmationSent=true;
              // ...
            }).catch((error) => {
              this.loading = false;
              this.disabled = false;
              console.log(error);
          // ...
        });
      }
    },
    ConfirmCode(){
      debugger;
      if(this.ConfirmationCode.length<6){
        this.$refs.codeform.validate();
      }else{
        this.verloading = true;
        this.verdisabled = true;
        let confirmationResult = window.confirmationResult;
        confirmationResult.confirm(this.ConfirmationCode).then(res=>{
          this.verloading = false;
          this.verdisabled = false;
          this.confirmationValid = true;

          this.SubmitUser();
        }).catch(res=>{
          this.verdisabled = false
          this.verloading = false;
          console.log(res);
        })
      }

    },
    GoToServicePlaceRegister(){
      this.$emit('ServicePlace');
    },
    initReCaptcha(){
        let auth = getAuth();
        auth.languageCode = 'ar';
        window.recaptchaVerifier =  new RecaptchaVerifier('recaptcha-container', {
          'size': 'invisible',
          'callback': (response) => {

          }
        } ,auth);

        this.appVerifier= window.recaptchaVerifier;


    }
  }

}
</script>

<style lang="scss">
@import '../plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
