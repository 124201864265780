import ChatService from "@/Services/ChatService";
import moment from "moment";
import AppHelper from "@/Helpers/AppHelper";
export const chatModule = {
    namespaced : true,
    state:{
        chats:[],
        selectedChat: null,
        messagesObj : {
            currentPage : 0,
            data : [],
            IdToScroll:null,
        },
        totalMessagesCount :0,
        chatsCounts :[]
    },actions:{

        nextPage({commit}){
            commit("NEXT_PAGE");
            return Promise.resolve();
        },
        GetChats({commit} , obj) {
            return ChatService.GetChats(obj).then(res => {
                commit('SET_CHATS', res);
                return Promise.resolve(res);
            })
        },
        //GetComplainedChats
        GetComplainedChats({commit} , obj) {
            return ChatService.GetComplainedChats(obj).then(res => {
                commit('SET_CHATS', res);
                return Promise.resolve(res);
            })
        },
        CloseConversation({commit} , chatId){
            return ChatService.CloseConversation(chatId).then(res=>{
               commit('CLOSE_CONVERSATION', chatId);
               return Promise.resolve(res);
            });
        },
        ResetTotalMessagesCount({commit}){
            commit("ResetTotalMessagesCount");
        },


        GetMessages({commit} , payload) {
            return ChatService.GetMessages(payload).then(res => {
                commit('SET_MESSAGES', res);
                return Promise.resolve(res);
            })
        },
        ResetMessages({commit}){
            commit('RESET_CHAT');
        },
        SetSelectedChat({commit} , chat){
            commit("SET_CHAT" , chat);
            commit("RESET_CHAT");
            return Promise.resolve(chat);
        },
        SendMessage({commit} , message) {
            commit('SET_MESSAGE' , message);

            return ChatService.SendMessage(message).then(res=>{
                commit('SET_MESSAGE_ID' , res);
                return Promise.resolve(res);
            })
        },
        SetNewMessage({commit} , message){
            commit('SET_NEW_MESSAGE' , message);
        },
        SendContactMail({commit} , ContactEmail){
            return ChatService.SendContactMail(ContactEmail).then(res=>{
                return Promise.resolve(res);
            })
        },
        GetChatBetween({commit} , userId ){
            return ChatService.GetChatBetween(userId).then(res=>{
                return Promise.resolve(res);
            })
        }
    }
    ,mutations: {
        RESET_CHAT(state){
          state.messagesObj = {currentPage : 0 , data : [], IdToScroll : null};
        },
        NEXT_PAGE(state){
          state.messagesObj.currentPage++;
        },
        SET_CHATS(state , res){
            state.chats = res;
        },
        SET_CHAT(state, res){
            state.selectedChat = res;
            if(res)
                state.selectedChat.messagesCount = 0;
        },
        ResetTotalMessagesCount(state){
            state.totalMessagesCount = 0;
        },
        CLOSE_CONVERSATION(state , chatId){
            state.selectedChat = null;
            state.chats= state.chats.filter(s=>{return s.chatId !== chatId});
            state.messagesObj = {currentPage : 0 , data : [], IdToScroll : null};
        }
        ,SET_MESSAGES(state , res) {
            if(res.data && res.data.length>0){
                let messages = state.messagesObj.data;
                state.messagesObj = res;
                state.messagesObj.IdToScroll =state.messagesObj.data[state.messagesObj.data.length-1].id;
                state.messagesObj.data=state.messagesObj.data.concat(messages);
            }
        },
        SET_MESSAGE(state , message){
            state.messagesObj.data.push({
                message:message.Message,
                chatId : message.ChatId,
                senderUserId:message.SenderUserId,
                senderBranchId :message.SenderBranchId,
                reciverBranchId : message.ReciverBranchId,
                reciverUserId : message.reciverUserId,
                messageKey : message.MessageKey,
                sendDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            });
            state.selectedChat.message = message.Message;
            let obj = state.chats.find(s=>{return s.chatId=== message.ChatId});
            if(obj){
                obj.message = message.Message;
            }
        },
        SET_MESSAGE_ID(state , res){
            let obj = state.messagesObj.data.find(rs=>{return rs.messageKey === res.messageKey});

            obj.id = res.id;
            if(state.selectedChat.chatId === 0){
                state.selectedChat.chatId = res.chatId;
                state.chats.unshift(state.selectedChat);
            }else{
                state.chats.unshift(state.chats.splice(state.chats.findIndex(s=>{return s.chatId === res.chatId}), 1)[0]);
            }
        },
        SET_NEW_MESSAGE(state , new_message){
            state.totalMessagesCount++;
            if(state.selectedChat && new_message.chatId === state.selectedChat.chatId){
                state.messagesObj.data.push(new_message);
                let chat = state.chats.find((s)=>{return new_message.chatId === s.chatId});
                chat.messagesCount = chat.messagesCount ? chat.messagesCount+1 : 1;
                chat.message = new_message.message;
                state.selectedChat.messagesCount++;
            }
        }

    },getters:{
        GetAllChats : (state)=>(filter)=>{
            return state.chats.filter(s=>{return filter===''||!filter ? true : s.chatName.includes(filter)});
        },
        GetSelectedChat:(state)=>{
            return state.selectedChat;
        }
        ,GetMessagesObj:(state)=>{
            return state.messagesObj;
        }
        ,IsMessagesHasNext:(state)=>{
            return state.messagesObj.hasNext === true
        },
        GetMessagesCount:(state)=>{
            return state.totalMessagesCount;
        }
    }
}