<template>
  <v-dialog
      v-model="activeStat"
      max-width="1000px"
  >

    <v-card>
      <v-card-title>
        {{item ? $t('Edit'):$t("Add")}}
      </v-card-title>

      <v-card-text>
        
        <PointsPackageForm :countryId="countryId" :item="item" @edited="OnResult">
        </PointsPackageForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PointsPackageForm from '@/components/PointsPackages/PointsPackageForm';
export default {
  name: "PointsPackageDialog",
  components: {PointsPackageForm},
  props:{
    dialog:Boolean,
    item:Number,
    countryId:Number
    },data(){
      return {
        activeStat : this.dialog
      }
  }
  ,methods:{
    
    close(){
      this.activeStat = false;
      this.$emit('edited')
    },
    OnResult(res){
      console.log(res)
      this.activeStat= false;
      this.$emit('edited')
    }
  },watch:{
    dialog (val) {
      this.activeStat = val;
    },
    activeStat(val){
      debugger;
      if(!val){
        this.close();
      }    
    }
  },
}
</script>

<style scoped>

</style>