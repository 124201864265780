import AuthService from '../Services/AuthService';
const token = localStorage.getItem('token');
const user = JSON.parse(localStorage.getItem('user'));
const permissions = JSON.parse(localStorage.getItem('permissions')) ?? [];
const roles = JSON.parse(localStorage.getItem('roles')) ?? [];
const initialState = token
    ? { status: { loggedIn: true }, token , user  , permissions , roles  , menuCounts : null}
    : { status: { loggedIn: false }, token: null , user : null , permissions: null , roles: null  , menuCounts : null};

export const authModule  = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                res=>{
                    if(res.isError === false){
                            commit('loginSuccess' , res);
                            return Promise.resolve(res);
                    }
                    else{
                        commit('logout');
                        return Promise.reject(res.errorMessage);
                    }
                }
            );
        },

        Register({commit} , payload){

            return AuthService.Register(payload).then(res=>{
                console.log(res);
                if(res.isError === false){
                    commit('loginSuccess' , res);
                    return Promise.resolve(res);
                }
                else{
                    commit('logout');
                    return Promise.reject(res.errorMessage);
                }
            })
        },
        EditProfile({commit} , payload){

            return AuthService.EditProfile(payload).then(res=>{
                commit('EditUser' , payload);
                return Promise.resolve(res);
            })
        },
        checkAuth({ commit }) {
            return AuthService.checkAuth().then(
                res=>{
                    if(res.isError === false){
                        commit('loginSuccess' , res);
                        return Promise.resolve(res);
                    }
                    else{
                        commit('logout');
                        return Promise.reject(res.errorMessage);
                    }
                }
            );
        },
        logout({ commit }) {
          commit('logout');
          return Promise.resolve();
        },

        Startup({commit} , code){
            return AuthService.getStartup(code).then(res=>{
                commit('SETMENUCOUNTS', res.counts);
                return Promise.resolve(res);
            });
        },
        DecreaseCount({commit} , payload){
            commit('DecreaseCount' , payload);
        }
    },
    getters : {
        IsLoggedIn  : state =>{
            return state.status.loggedIn;
        },

        GetLoginUser  : state =>{
            return state.user;
        },
        HasPermission  : state => permission =>{
            return state.permissions.some(s=>s.val === permission);
        }

        ,
        HasRole  :state => role=>{
            if(Array.isArray(role)) return role.includes(state.user.rankBaseName);
            return role === state.user.rankBaseName;
        },
        GetMenuCountByName:state=>name=>{
            let menuCount = state.menuCounts ?state.menuCounts[name]:null;
            return menuCount?menuCount : 0;
        },

    }
    ,
    mutations: {
        loginSuccess(state, res) {
            state.status.loggedIn = true;
            state.token = res.token;
            state.user = res.user;
            state.permissions = res.roles;
            AuthService.initHeaders(res.token);
            localStorage.setItem('token' , 'Bearer '+res.token );
            localStorage.setItem('user' ,JSON.stringify(res.user) );
            localStorage.setItem('permissions' , JSON.stringify(res.roles) );
        },

        logout(state) {
            state.status.loggedIn = false;
            state.token = null;
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('permissions');
            window.location.reload();

        },
        EditUser(state , payload){
            state.user.name = payload.name;
            state.user.phone = payload.phone;
            state.user.countryId = payload.countryId;
        },
        SETMENUCOUNTS(state , counts){
            state.menuCounts = counts;
        },
        DecreaseCount(state , payload){
            if(state.menuCounts){
                if(state.menuCounts[payload]){
                    state.menuCounts[payload] = state.menuCounts[payload]-1;
                }
            }
        }
    }
}