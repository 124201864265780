import en from "@/Lang/en.json"
import ar from "@/Lang/ar.json"

import VueI18n from "vue-i18n"
import Vue from "vue"
Vue.use(VueI18n)
export default new VueI18n({
    locale:localStorage.getItem('lang') || 'ar',
    messages:{
        ar : ar , 
        en : en
    }
});