import axios_instance from "./ApiService";
class PointsPackageService {
    controllerName = "/PointsPackage";
    GetAllPointsPackages(){
        return axios_instance.get(this.controllerName+"/GetAllPointsPackages").then(res=>{
           console.log(res);
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.reject(err);
        })
    }

    GetAllPaymentRequestsById(id){
        return axios_instance.get("/PaymentHandler/GetPaymentRequestsByUser" , {params:{
            userId : id
            }}).then(res=>{
            console.log(res);
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.reject(err);
        })
    }
    GetById(Id){
        return axios_instance.get(this.controllerName+"/GetById" , {
            params : {
                Id : Id
            }
        }).then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.reject(err);
        })
    }
    GetAllByCountryId(Id)
    {
        debugger;
         Id = Id? Id :localStorage.getItem('countryId');
        if(Id === undefined || Id === null || Id === '') return Promise.reject("no County");
        return axios_instance.get(this.controllerName+"/GetAllPointsPackagesByCountryId" , {
            params:{CountryId : Id}
        }).then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.reject(err);
        })
    }
    SavePointsPackage(payload) {
        return axios_instance.post(this.controllerName+ "/SavePointsPackage" , payload).then(res=>{
            console.log(res);
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.reject(err);
        });
    }
    deletePointsPackage(Id){
        return axios_instance.delete(this.controllerName+"/DeletePointsPackage" , {params:{Id : Id}}).then(res=>{
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.reject(err);
        })

    }

    SendPaymentRequest(payload) {
        return axios_instance.post('/PaymentHandler/SavePaymentRequest' , payload).then(res=>{
            console.log(res);
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.reject(err);
        });
    }


}

export default new PointsPackageService();