
import VueToastr from "vue-toastr";
import Vue from "vue";
Vue.use(VueToastr, {
    defaultTimeout:5000,
    defaultPosition: "toast-top-left",
    defaultCloseOnHover: true,
  
  
  });
  export default VueToastr;