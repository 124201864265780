
import axios_instance  from "./ApiService";
class AuthService {
    controllerName = '/Auth'
    login(user) {
        return axios_instance
            .post( '/Auth/login', {
                UserName: user.email,
                Password: user.password
            })
            .then(response => {
                return response.data;
            }).catch(error=>{
                if (error.response) {
                    return error.response.data;
                }
                else{
                    return []
                }
            });
    }
    initHeaders(token){
        axios_instance.defaults.headers.common['Authorization'] = "Bearer "+token;
    }
    Register(payload) {
        return axios_instance.post(this.controllerName + '/SignUp' , payload).then(response =>{
            return Promise.resolve(response.data);
        });
    }
    EditProfile(payload) {
        return axios_instance.post('UsersControl/EditProfile' , {
            UserName:payload.name,
            Email : payload.email,
            Phone : payload.phone,
            Password : payload.Password,
            CountryId : payload.countryId,
            Rank:payload.rankId,
            Id : payload.id
        }).then(response =>{
            return Promise.resolve(response.data);
        });
    }
    logout() {
        return axios_instance.get('/auth/logout').then(response =>{
            return response.data;
        });
    }
    getStartup(code){
        return axios_instance.get('/Startup/GetStartup' , {params:{
                countryCode : code
            }}).then(response =>{
            return response.data;
        });
    }
    checkAuth() {
        return axios_instance
            .get( '/Auth/CheckAuth')
            .then(response => {
                return response.data;
            }).catch(error=>{
                if (error.response) {
                    return error.response.data;
                }
                else{
                    return []
                }
            });
    }
}
export default new AuthService();