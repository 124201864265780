<template>
  <div class="">
          <v-tabs hide-slider grow class="elevation-1" v-model="tab">
            <v-tab btn class="tab-btn" >{{$t('ServicePlaceData')}}</v-tab>
            <v-tab class="tab-btn">{{$t('Branches')}}</v-tab>
            <v-tab class="tab-btn">{{$t('Users')}}</v-tab>
          </v-tabs>
          <v-card flat class="mt-2">
            <v-card-text>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <AddServicePlaceForm :item="id"></AddServicePlaceForm>
                </v-tab-item>
                <v-tab-item>
                  <ServicePlaceBranches :place-id="id"></ServicePlaceBranches>
                </v-tab-item>
                <v-tab-item>
                  <ServicePlaceUsersGrid :service-place-id="id"></ServicePlaceUsersGrid>
                </v-tab-item>
              </v-tabs-items>

            </v-card-text>
          </v-card>
  </div>
</template>

<script>
import {APP_URL} from "@/Helpers/Config";
import AddServicePlaceForm from "@/components/ServicesPlaces/AddServicePlaceForm";
import ServicePlaceBranches from "@/components/ServicesPlaces/ServicePlaceBranches";
import ServicePlaceUsersGrid from "@/components/ServicesPlaces/ServicePlaceUsersGrid";

export default {
  name: "ServicePlaceEditForm",
  components: {ServicePlaceUsersGrid, ServicePlaceBranches, AddServicePlaceForm },
  props:{
    id : 0
  }
  ,data(){
    return {
      APP_URL,
      tab:0,
    }
  }
  ,created() {

  },
  computed:{

  },
  mounted() {

  },
  methods:{
  }

}
</script>

<style scoped>
  .tab-btn{
    font-size: 1rem;
    letter-spacing:0!important;
  }
  .tab-btn::after{
    color: #00cc00!important;
  }

</style>