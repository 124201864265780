<template>
  <v-dialog
      v-model="activeStat"
      max-width="1000px"
  >

    <v-card>
      <v-card-title>
        {{$t("EditCountry")}}
      </v-card-title>

      <v-card-text>
        <EditCountryForm :item="item" @edited="OnResult">

        </EditCountryForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EditCountryForm from "@/components/Regions/Countries/EditCountryForm";
export default {
  name: "EditCountryDialog",
  components: {EditCountryForm},
  props:{
    dialog:Boolean,
    item:Object,
    editedIndex : Number
    },data(){
      return {
        activeStat : this.dialog
      }
  }
  ,methods:{
    save(){
      this.$emit('edited' , {item : this.item , index : this.editedIndex})
    }
    ,close(){
      this.activeStat = false;
      this.editedIndex = -1;
      this.$emit('edited' , {item : null , index : this.editedIndex})
    },
    OnResult(res){
      console.log(res)
      this.$emit('edited' , {item : res , index : this.editedIndex})
    }
  },watch:{
    dialog (val) {
      this.activeStat = val;
    },
    activeStat(val){
      this.$emit('edited' , {item : null , index : this.editedIndex})
    }
  },
}
</script>

<style scoped>

</style>