import {initializeApp} from "firebase/app";
import { GOOGLE_API_KEY  } from "@/Helpers/Config";
import {getMessaging} from 'firebase/messaging'
const firebaseConfig = {
    apiKey: GOOGLE_API_KEY,
    authDomain: "lakoffers-920e8.firebaseapp.com",
    projectId: "lakoffers-920e8",
    storageBucket: "lakoffers-920e8.appspot.com",
    messagingSenderId: "637020155803",
    appId: "1:637020155803:web:3c6b21b91e4886b11e7b3e",
    measurementId: "G-Y84703BXVX"
};
const app  = initializeApp(firebaseConfig);
export default getMessaging(app);