import axios_instance from "./ApiService";
class ReportsService {

    GetUsageBetweenTowPeriods(payload) {
        console.log(payload);
        return axios_instance.post("/SalesClaims/getAllUsageBetweenTwoDates" ,payload).then(res=>{
            console.log(res);
            return Promise.resolve(res.data);
        }).catch(err=>{
            return Promise.resolve(err);
        })
    }
}

export default new ReportsService();