import CategoriesService from "@/Services/CategoriesService";

export const categoriesModule = {
    namespaced : true,
    state:{
        categories :[],
        services : []
    },actions:{
        GetAll({commit} , obj){
            return CategoriesService.GetAllCategories(obj).then(res =>{
                commit('setCategories' , res);
                return Promise.resolve(res);
            });
        },
        SetDefaultCats({commit} , cats){
            commit("setCategories" , cats);
        },
        GetCategory({commit} , obj){
            return CategoriesService.GetCategory(obj).then(res =>{

                return Promise.resolve(res);
            });
        },
        SaveCategory({commit} , payload){
            return CategoriesService.SaveCategory(payload).then(res=>{
                commit('SetCategory' , res);
                return Promise.resolve(res);
            });
        },
        deleteCategory({commit} ,id){
            return CategoriesService.deleteCategory(id).then(res=>{
                return Promise.resolve(res );
            });
        },
        deleteCategoryService({commit} ,id){
            return CategoriesService.deleteService(id).then(res=>{
                return Promise.resolve(res );
            });
        },
        saveCategoryService({commit} ,payload){
            return CategoriesService.SaveCategoryService(payload).then(res=>{
                return Promise.resolve(res );
            });
        },
        GetCategoryServices({commit} , id){
            return CategoriesService.GetCategoryServices(id).then(res=>{
                commit('setServices' , res);
                return Promise.resolve(res);
            })
        }
    }
    ,mutations: {
        setCategories(state , categories){
            state.categories = categories;
        },
        SetCategory(state , res){
            let index = state.categories.findIndex(s=>s.id === res.id);
            if(index === -1){
                state.categories.push(res);
            }else{
                state.categories[index] = res;
            }
        },
        setServices(state, obj) {
            state.services = obj;
        }

    },getters:{
        GetAllCategories : (state)=>{
            return state.categories;
        },
        GetCategoryServices : (state)=>{
            return state.services;
        }
    }
}