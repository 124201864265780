<template>
  <div>
    <AppCircularLoader  v-if="loading"/>
    <v-simple-table v-if="!loading" class=" elevation-3">
      <template v-slot:default>
        <thead>
        <tr>
          <th>
            {{ $t('RoleName') }}
          </th>
          <th  v-for="item in GetAllRanks"  :key="'Rank_'+item.id">
            {{item.name}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="role in GetAllRoles"
            :key="role.name + role.id"
            :class="`${role.isScreen? 'screen-role':''}`"
        >
          <td>{{ role.name }}</td>
          <td v-for="rank in GetAllRanks" :key="'rankRole'+rank.id+'_'+role.id">
            <v-checkbox color="primary" v-model="RanksRoles" @change="CheckBoxChanged"  :value="{rank : rank.id , role : role.id}" >

            </v-checkbox>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import AppCircularLoader from "../General/AppCircularLoader";
export default {
  name: "RankRolesGrid",
  components: {AppCircularLoader},
  data(){
    return {
      RanksRoles : []
    }
  },
  props :{
    loading : Boolean,
    RRoles : []
  },
  watch:{
    GetRankRoles(val){
      this.Initialize();
    }
  },
  computed : {
    GetAllRoles (){
      return this.$store.getters["rankRolesModule/GetAllRoles"];
    },
    GetAllRanks (){
      return this.$store.getters["rankRolesModule/GetAllRanks"];
    },GetRankRoles (){
      return this.$store.getters["rankRolesModule/GetRankRoles"];
    },
  },methods:{
    CheckBoxChanged(){
      this.$emit('rankRoleChanged' , this.RanksRoles)
    },
    Initialize() {
      this.RanksRoles = this.GetRankRoles
    }
  },
  mounted() {
    this.Initialize()
  },


}
</script>

<style scoped>

.screen-role{
  background: rgba(73, 78, 159, 0.36) !important;
}
</style>