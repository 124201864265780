import '@/plugins/vue-composition-api'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify/index'
import VueProgressBar from './plugins/VueProgress'

import VueToastr from './plugins/VueToastr'
import VueGeolocation from "vue-browser-geolocation/src";
import VueGoogleMaps from './plugins/VueGoogleMaps'
import './plugins/Syncfusion';

import i18n from '@/plugins/i18n/i18n';
import '@/assets/style.css';
import '@/assets/Custom-user.css'
import '@/assets/Custom-user-en.css'
import '@/assets/chat.css'

Vue.config.productionTip = true
Vue.use(VueGeolocation);

new Vue({
  VueProgressBar,
  VueToastr,
  router,
  i18n,
  VueGoogleMaps,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
