<template>
  <div class="dashboard-container">
    <ServicePlaceEditForm  :id="ServicePlaceId"></ServicePlaceEditForm>

  </div>
</template>

<script>
import ServicePlaceEditForm from "@/components/ServicesPlaces/ServicePlaceEditForm";
export default {
  name: "ServicePlaceEdit",
  components:{
    ServicePlaceEditForm
  },
  data(){
    return {
      ServicePlaceId: 0
    }
  },
  created() {
    this.ServicePlaceId = parseInt(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>