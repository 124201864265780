<template>
  <v-dialog
      v-model="activeStat"
      max-width="1000px"
  >
    <v-card>
      <v-card-text>
        <AddUserFrom @userSaved="close" v-if="user" :item="user"  :rank="rank"></AddUserFrom>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import AddUserFrom from "@/components/Users/AddUserFrom";
export default {
  name: "EditUserDialog",
  components: {AddUserFrom},
  props:{
    dialog:Boolean,
    user:Object,
    rank:Object,
    editedIndex : Number
    },data(){
      return {
        activeStat : this.dialog
      }
  }
  ,methods:{
    save(){
      this.$emit('edited' , {user : this.user , index : this.editedIndex})
    }
    ,close(){
      this.activeStat = false;
      this.editedIndex = -1;
      this.$emit('edited' , {user : null , index : this.editedIndex})
    }
  },watch:{
    dialog (val) {
      this.activeStat = val;
    },
    activeStat(val){
      this.$emit('edited' , {user : null , index : this.editedIndex})
    }
  },
}
</script>

<style scoped>

</style>