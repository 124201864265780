import Home from "@/views/Home";
import About from "@/views/About";
import Login from "@/views/login";
import FawryPay from "@/views/Clients/FawryPay";
import Register from "@/views/Register";
import RolesManagement from "@/views/Admin/Roles/Roles"
import AdminHome from "@/views/Admin/Home"
import UsersManagement from "@/views/Admin/Users/Users"
import RegionsManagement from "@/views/Admin/Regoins/Regions"
import GovernmentsManagement from "@/views/Admin/Regoins/Governments"
import CategoriesManagement from "@/views/Admin/Categories/Categories"
import authMiddleware from "@/Middleware/authMiddleware";
import guestMiddleware from "@/Middleware/guestMiddleware";
import ServicePlaceRegister from "@/views/ServicePlaceRegister";
import ServicePlaces from "@/views/Admin/ServicePlaces/ServicesPlaces";
import ServicePlaceEdit from "@/views/Admin/ServicePlaces/ServicePlaceEdit";
import roleMiddleware from "@/Middleware/roleMiddleware";
import ServicePlaceServices from "@/views/Admin/ServicePlaces/ServicePlaceServices";
import ServicePlaceUsers from "@/views/Admin/ServicePlaces/ServicePlaceUsers";
import Reports from "@/views/Admin/Reports/Reports";
import PointsPackages from '@/views/Admin/PointsPackages/PointsPackages';
import EgyptSetter from "@/views/Admin/Regoins/EgyptSetter";
import Cities from "@/views/Admin/Regoins/Cities";
import AdminServicePlaceAdd from "@/views/Admin/ServicePlaces/AdminServicePlaceAdd";
import PaymentResult from "@/views/PaymentResult";
export const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/User/Offers/Search',
        name: 'OffersFilter',
        component: ()=>import('@/views/Clients/OffersFilter')
    },
    {
        path: '/User/ServicePlaces/Search',
        name: 'ServicePlacesFilter',
        component: ()=>import('@/views/Clients/ServicePlacesFilter')
    },
    {
        path: '/chat',
        name: 'Chat',
        component: ()=>import('@/views/Chatting'),
        meta: {
            middleware: [authMiddleware],

        }
    },
    {
        path: '/CallCenter',
        name: 'UsersComplaints',
        component: ()=>import('@/views/Admin/UsersComplaints/UsersComplaints'),
        meta: {
            middleware: [authMiddleware],

        }
    },
    {
        path: '/:Title',
        props:true,
        name: 'Pages',
        component: ()=>import('@/views/Pages')
    },

    {
        path: '/Admin/PageSections',
        name: 'Admin.PagesControl',
        component: ()=>import('@/views/Admin/Control/PagesControl'),
        meta: {
            middleware: [authMiddleware,roleMiddleware],
            permissions:["PageSectionControl"]
        }
    },
    {
        path: '/Plans',
        name: 'Plans',
        component: ()=>import('@/views/ServiceConsultant/PricingPlans')
    },
    {
        path: '/EgyptSetter',
        name: 'EgyptSetter',
        component:EgyptSetter
    },
    {
        path: '/Admin2',
        name: 'AdminHome2',
        component:()=> import('@/views/Admin/Home')
    },
    {
        path: '/Register/ServicePlace',
        name: 'ServicePlaceRegister',
        props: { default: true, add: false },
        component: ServicePlaceRegister,
        meta:{
            middleware:[guestMiddleware],
        },
    },
    {
        path: '/ServicePlace/Add',
        name: 'ServicePlaceAdd',
        component: AdminServicePlaceAdd,
        props: { default: true, add: true },
        meta:{
            middleware:[authMiddleware],
        },
    },
    {
        path: '/Admin/RolesManagement',
        name: 'RolesManagement',
        component: RolesManagement,
        meta:{
            middleware:[authMiddleware],
        },
    },{
        path: '/Admin',
        name: 'AdminHome',
        component: AdminHome,
        meta:{
            middleware:[authMiddleware],
        },
    },


    {
        path: '/Admin/UsersManagement',
        name: 'UsersManagement',
        component: UsersManagement,
        meta:{
            middleware:[authMiddleware],
        },
    },{
        path: '/Admin/Categories',
        name: 'CategoriesManagement',
        component: CategoriesManagement,
        meta:{
            middleware:[authMiddleware],
        },
    },{
        path: '/Admin/Countries',
        name: 'CountriesManagement',
        component: RegionsManagement,
        meta:{
            middleware:[authMiddleware],
        },
    },{
        path: '/Admin/Governments/:CountryId',
        name: 'GovernmentsManagement',
        component: GovernmentsManagement,
        meta:{
            middleware:[authMiddleware],
        },

    }
    ,{
        path: '/Admin/Cities/:countryId/:governmentId',
        name: 'CitiesManagement',
        component: Cities,
        meta:{
            middleware:[authMiddleware],
        },

    },
    {
        path: '/Admin/ServicePlaces',
        name: 'ServicePlaceManagement',
        component: ServicePlaces,
        meta:{
            middleware:[authMiddleware],
        },

    },
    {
        path: '/Admin/PointsPackages',
        name: 'PointsPackagesManagement',
        component: PointsPackages,
        meta:{
            middleware:[authMiddleware],
        },

    },
    {
        path: '/Admin/Reports',
        name: 'AdminReports',
        component: Reports,
        meta:{
            middleware:[authMiddleware , roleMiddleware],
            roles : ['SuperAdmin']
        },
    },
    {
        path:"/Admin/Reports/PeriodToPeriodUsageReport",
        name:"AdminReports.PeriodToPeriodUsageReport",
        component: () => import('@/views/Admin/Reports/PeriodToPeriodUsageReport'),
        meta:{
            middleware: [authMiddleware,roleMiddleware],
            permissions:["PeriodToPeriodUsageReport"]
        }
    },
    {
        path:"/Admin/Reports/PeriodToPeriodClientsSalesReport",
        name:"AdminReports.PeriodToPeriodClientsSalesReport",
        component: () => import('@/views/Admin/Reports/PeriodToPeriodClientsSalesReport'),
        meta:{
            middleware: [authMiddleware,roleMiddleware],
            permissions:["PeriodToPeriodClientsSalesReport"]

        }
    },
    {
        path:"/Payment/Result",
        name:"PaymentReturnUrl",
        component: () => import('@/views/PaymentReturnPage')
    },
    {
        path:"/ServiceConsultant/users/Package/Subscribe",
        name:"UserPackageSubscribe",
        props:true,
        component: () => import('@/views/ServiceConsultant/UserPackagesSubscribe'),
        meta: {
            middleware: [authMiddleware,roleMiddleware],
            permissions:["AddPoints"]
        }
    },
    {
        path:"/users/AccountStatement/TransferPoints",
        name:"WalletTransferPoints",
        props:true,
        component: () => import('@/views/Clients/wallet/TransferPoints'),
        meta: {
            middleware: [authMiddleware,roleMiddleware],
            permissions:["TransferPoints"]
        }
    },
    {
        path:"/users/Profile",
        name:"UserProfile",
        props:true,
        component: () => import('@/views/Clients/UserProfile'),
        meta: {
            middleware: [authMiddleware],
        }
    },
    {
        path:"/user/PaymentRequests",
        name:"PaymentRequests",
        props:true,
        component: () => import('@/views/Clients/wallet/PaymentRequests'),
        meta: {
            middleware: [authMiddleware],

        }
    },
    {
        path:"/moderator/users",
        name:"ModeratorUsers",
        props:true,
        component: () => import('@/views/Moderator/Users'),
        meta: {
            middleware: [authMiddleware,roleMiddleware],
            permissions:["AddUser"]
        }
    },


    {
        path:"/users/AccountStatement/:id",
        name:"userWalletControl",
        component: () => import('@/views/UserWalletControl'),
        meta: {
            middleware: [authMiddleware],
        }

    },

    {
        path:"/Admin/SalesConsultant/AttachToManager",
        name:"Admin.AttachUserToManger",
        props:true,
        component: () => import('@/views/Admin/Users/AttachUserToManager'),
        meta: {
            middleware: [authMiddleware,roleMiddleware],
            permissions:["UsersControl"]
        }
    },
    {
        path:"/User/AccountStatement",
        name:"AccountStatement",
        component: () => import('@/views/UserWalletControl'),
        meta: {
            middleware: [authMiddleware , roleMiddleware] ,
            permissions:["AccountStatement"]
        }

    },
    {
        path:"/User/Profile",
        name:"UserProfile",
        component: () => import('@/views/Clients/UserProfile'),
        meta: {
            middleware: [authMiddleware ] ,

        }

    },
    {
        path:"/Admin/Reports/PeriodToPeriodCancellationReport",
        name:"AdminReports.PeriodToPeriodCancellationReport",
        component: () => import('@/views/Admin/Reports/PeriodToPeriodCancelationReport')
    },
    {
        path: '/Admin/ServicePlaces/Edit/:id',
        name: 'ServicePlaceEdit',
        component: ServicePlaceEdit,
        meta:{
            middleware:[authMiddleware , roleMiddleware],
            permissions:["ServicesPlaceControl"]
        },

    }
    ,{
        path: '/ServicePlaces/Services/:id',
        name: 'ServicePlaceServices',
        component: ServicePlaceServices,
        meta:{
            middleware:[authMiddleware , roleMiddleware],
            permissions:["EditServicePlace"]
        },
    },
    {
        path: '/Admin/ServicePlaces/Users/:id',
        name: 'ServicePlaceUsers',
        component: ServicePlaceUsers,
        meta:{
            middleware:[authMiddleware , roleMiddleware],
            permissions:["ServicesPlaceControl"]
        },
    },
    //ServicePlace
    {
        path: '/ServicePlace/Users',
        name: 'PlaceUsers',
        component: ()=>import('@/views/ServicePlace/Users'),
        meta:{
            middleware:[authMiddleware , roleMiddleware],
            permissions:["UsersControl"]
        },
    },
    {
        path: '/ServicePlace/Offers/Add',
        name: 'AddOffer',
        props: true,
        component: ()=>import('@/views/ServicePlace/OffersDetail'),
        meta:{
            middleware:[authMiddleware , roleMiddleware],
            permissions:["AddOffer"]
        },
    },
    {
        path: '/ServicePlace/Offers/Edit/:id',
        name: 'EditOffer',
        props:true,
        component: ()=>import('@/views/ServicePlace/OffersDetail'),
        meta:{
            middleware:[authMiddleware , roleMiddleware],
            permissions:["AddOffer"]
        },
    },
    {
        path: '/ServicePlace/Branches',
        name: 'PlaceBranches',
        component: ()=>import('@/views/ServicePlace/Branches'),
        meta:{
            middleware:[authMiddleware , roleMiddleware],
            permissions:["BranchesControl"]
        },
    },
    {
        path: '/ServicePlace/MainData',
        name: 'PlaceMainData',
        component: ()=>import('@/views/ServicePlace/MainData'),
        meta:{
            middleware:[authMiddleware , roleMiddleware],
            permissions:["EditServicePlace"]
        },
    },
    {
        path: '/ServicePlace/Offers',
        name: 'PlaceOffers',
        component: ()=>import('@/views/ServicePlace/Offers'),
        meta:{
            middleware:[authMiddleware , roleMiddleware],
            permissions:["OffersControl"]
        },
    },
    {
        path: '/ServicePlace/Services',
        name: 'PlaceServices',
        component: ()=>import('@/views/ServicePlace/Offers'),
        meta:{
            middleware:[authMiddleware , roleMiddleware],
            permissions:["EditServicePlace"]
        },
    },
    {
        path: '/ServicePlace/PendingOrders',
        name: 'PendingOrders',
        component: ()=>import('@/views/ServicePlace/PendingOrders.vue'),
        meta:{
            middleware:[authMiddleware , roleMiddleware],
            permissions:["OrdersControl"]
        },
    },
    {
        path: '/ServicePlace/CurrentOrders',
        name: 'CurrentOrders',
        component: ()=>import('@/views/ServicePlace/CurrentOrders.vue'),
        meta:{
            middleware:[authMiddleware , roleMiddleware],
            permissions:["OrdersControl"]
        },
    },
    {
        path: '/User/PendingOrders',
        name: 'ClientPendingOrders',
        component: ()=>import('@/views/Clients/ClientPendingOrders.vue'),
        meta:{
            middleware:[authMiddleware ],
        },
    },
    {
        path: '/User/DoneOrders',
        name: 'ClientDoneOrders',
        component: ()=>import('@/views/Clients/ClientDoneOrders.vue'),
        meta:{
            middleware:[authMiddleware ],
        },
    },
    {
        path: '/Admin/ProfitSlices',
        name: 'AdminProfitSlices',
        component: ()=>import('@/views/Admin/ProfitSlice/ProfitSlices'),
        meta:{
            middleware:[authMiddleware , roleMiddleware],
            permissions:["ProfitSlicesControl"]
        },
    },
    {
        path: '/Admin/Employees/Claims',
        name: 'Admin.ProfitClaims',
        component: ()=>import('@/views/Admin/ProfitClaims/Claims'),
        meta:{
            middleware:[authMiddleware , roleMiddleware],
            permissions:["ProfitClaimsControl"]
        },
    },
    {
        path: '/Sales/Home',
        name: 'SalesHome',
        component: ()=>import('@/views/ServiceConsultant/SalesHome.vue'),
        meta:{
            middleware:[authMiddleware],
        },
    },
    {
        path: '/Admin/SalesClaimDetail/:userId',
        name: 'Admin.SalesClaimDetail',
        props:true,
        component: ()=>import('@/views/Admin/ProfitClaims/SalesClaimDetail.vue'),
        meta:{
            middleware:[authMiddleware],
        },
    },
    //EndServicePlace
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/user/login',
        name: 'SignIn',
        component: Login
    },
    {
        path: '/user/register',
        name: 'SignUp',
        component: Register
    },
    {
        path: '/User/FawryPay',
        name: 'FawryPay',
        props: true,
        component: FawryPay
    },
    {
        path: '/Payment/Result',
        name: 'PaymentResult',
        props: true,
        component: PaymentResult
    },


];
