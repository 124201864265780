import UserWalletService from "@/Services/UserWalletService";

export const userWalletModule = {
    namespaced : true,
    state:{
        currencies :[],
        wallet : {

        },
        transactions:{}
    },actions:{
        GetAllCurrencies({commit} ){
            return UserWalletService.GetAllCurrencies().then(res =>{
                commit('setCurrencies' , res);
                return Promise.resolve(res);
            });
        },
        GetAllTransactions({commit} , payload){
            return UserWalletService.GetAllTransactions(payload).then(res =>{
                commit('setTransactions' , res);
                return Promise.resolve(res);
            });
        },
        GetWalletTransactions({commit} , payload){
            return UserWalletService.GetWalletTransactions(payload).then(res =>{
                commit('setTransactions' , res);
                return Promise.resolve(res);
            });
        },

        GetWalletByUser({commit} , id){
            return UserWalletService.GetWalletByUser(id).then(res =>{
                commit('setWallet' , res);
                return Promise.resolve(res);
            });
        },
        AccountStatement({commit}){
            return UserWalletService.AccountStatement().then(res =>{
                commit('setWallet' , res);
                return Promise.resolve(res);
            });
        },
        setCurrentPage({commit} , value){
            commit('setCurrentPage' , value);
        },
        SubscribeToPackage({commit} , payload){
            return  UserWalletService.SubscribeToPackage(payload).then(res=>{
                return Promise.resolve(res);
            }).catch(re=>{
                return Promise.reject(re);
            });
        },
        TransferPoints({commit} , payload){
            return  UserWalletService.TransferPoints(payload).then(res=>{
                return Promise.resolve(res);
            }).catch(re=>{
                return Promise.reject(re);
            });
        },
        DecreasePoints({commit} , points){
            commit('DecreasePoints' , points);
          return Promise.resolve(points);
        },
        GetWalletByServicePlaceId({commit} , id){
            return UserWalletService.GetWalletByServicePlaceId(id).then(res =>{
                commit('setWallet' , res);
                return Promise.resolve(res);
            });

        }
    }
    ,mutations: {
        DecreasePoints(state , points){
            state.wallet.points-=points;
        },
        setCurrencies(state , currencies){
            state.currencies = currencies;
        },
        setWallet(state , wallet){
            state.wallet = wallet.wallet;
        },
        setTransactions(state , res){
            state.transactions = res;
        },
        setCurrentPage(state,value) {
            state.transactions.currentPage = value;
        }
    },getters:{
        GetAllCurrencies : (state)=>{
            return state.currencies;
        },
        GetWallet : (state)=>{
            return state.wallet;
        },
        GetAllTransactions:(state)=>{
            return state.transactions;
        },
    }
}