import router from "../router/index";
import axios from "axios";
import {APP_URL} from '@/Helpers/Config';
const instance = axios.create({
    baseURL : APP_URL,
});
const token = localStorage.getItem('token');
let lang = localStorage.getItem('lang');
if(token){
    instance.defaults.headers.common['Authorization'] = token;
}
if(!lang){
    lang = 'ar';
    localStorage.setItem('lang'  , lang);
}
instance.defaults.headers.common['Accept-Language'] = lang;

instance.defaults.headers.common['content-type'] = 'application/json';
let errorValid = false;
instance.interceptors.response.use(response =>{;
    return response;
} , error=>{

    if (!error.response) {
        debugger;
        // network error
        if(!errorValid){
            router.app.$toastr.e('network error')
            window.location.reload();
        }
        errorValid = true;
        setTimeout(()=>{errorValid = false} , 500);


        return Promise.reject(error);
    }
    if(error.response?.status ===401){
        return router.app.$store.dispatch('authModule/logout');
    }
    else if(error.response?.status ===500){
        router.app.$toastr.e("something wrong");
        return Promise.reject(error)
    }
    else{
        return Promise.reject(error);
    }
});
export default instance;
