import NotificationService from "@/Services/NotificationService";
export const notificationModule = {
    namespaced : true,
    state:{
        topics:[],
        notifications :{
            currentPage : 0,
            data : [],
            hasNext:true,
        },
        notificationCount :0
    },actions:{
        SetNewBackGroundNotification({commit} , payload){
            commit('setNewNotification' , payload);
        }
        ,GetNotifications({commit} , page ){
            return NotificationService.GetNotifications(page).then(res=>{
                commit('setNotifications' , res);
                return Promise.resolve(res);
            });
        },
        UpdateSeen({commit}){
            commit("setNotificationsCount" , 0);
        },
        ResetNots({commit} ){
            commit("ResetNots");
        },
        GetNotificationCount({commit}){
            return NotificationService.GetNotificationsCount().then(res=>{
                commit('setNotificationsCount' , res);
            });
        },
        async subscribeUser({commit}){
            let topics= NotificationService.GetUserTopics();
            await NotificationService.SubscribeToUserTopics();
            commit('setUserTopics' , topics);
            return Promise.resolve();
        }
    }
    ,mutations: {
        setNewNotification(state , res){
            state.notificationCount++;
        },
        setNotifications(state , res){
            debugger;

            if(res.data && res.data.length>0){
                state.notifications.data=state.notifications.data.concat(res.data);
                state.notifications.currentPage = res.currentPage;
                state.notifications.hasNext = res.hasNext;
            }
        },
        setUserTopics(state, res){
            state.topics=res;
        },
        setNotificationsCount(state, res){
            state.notificationCount = res;
        },
        ResetNots(state){
            state.notifications = {
                currentPage : 0,
                data : [],
                hasNext:true,
            };
        }
    },getters:{
        getNotifications : (state)=>{
            return state.notifications?.data??[];
        },
        getNotificationCount: (state)=>{
            return state.notificationCount;
        },
        HasNext: (state)=>{
            return state.notifications&& state.notifications.hasNext;
        },
        GetPage: (state)=>{
            return state.notifications? state.notifications.currentPage: 0;
        },
        getUserTopics:(state)=>{
            return state.topics;
        }
    }

}