<template>
  <v-select
      :items="GetAll"
      :label="$t('SelectCategory')"
      @change="ChangeDropDown"
      outlined
      dense
      v-model="selected"
  ></v-select>
</template>

<script>
export default {
  name: "CategoriesDropDown" ,
  data(){
    return {
      selected : {name:'' , id :0}
    }
  },
  computed:{
    GetAll(){
      return this.$store.getters['categoriesModule/GetAllCategories'].map((el , index)=>{
        return {
          text : el.name ,
          value: {
            name : el.name,
            index : index,
            id : el.id,
          }
        }
      });
    }
  },
  methods:{
    ChangeDropDown(item){
      this.$emit('Selected' , item);
    }
  }
}
</script>

<style scoped>

</style>