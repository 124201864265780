<template>
  <section id="pricing" class="pb-8">
    <v-container fluid>
      <v-container v-if="loading" class="text-center">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-container>
      <v-row  align="center" v-else justify="center">
        <v-col cols="12">
          <v-card style="width: 100%">
            <h1 class="text-center pt-6 font-weight-light display-2">{{$t('PricingPlans')}}</h1>
            <v-divider class="my-6"></v-divider>
            <v-row align="center" class="text-center flex-center pa-2">
              <PricingPlan @Selected="SubscribeTo" :devider="$vuetify.breakpoint.smAndUp" v-for="points_package in GetAllByCountry" :points-package="points_package" :key="'plan_'+points_package.id"></PricingPlan>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </section>
</template>

<style lang="scss">
$main_color: #283e79;

ul {
  font-size: 13px;
  line-height: 1.5em;
  margin: 5px 0 15px;
  padding: 0;

  li {
    list-style: none;
    position: relative;
    padding: 0 0 0 20px;
  }

  li {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 10px;
      height: 10px;
      background-color: $main_color;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-eeborder-radius: 50%;
    }
  }
}
</style>

<style scoped>
.header {
  background-color: #283e79;
  color: white;
}

.circle1 {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background-color: #f0f8ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle2 {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: #e0effc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-border-rounded svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #f4f7f5;
  z-index: -1;
}

#pricing {
  z-index: 0;
}

.content {
  z-index: 1;
}

svg {
  overflow: hidden;
}

section {
  position: relative;
}
</style>

<script>
import PricingPlan from "@/components/Client/PricingPlan";
export default {
  components: {PricingPlan},
  data (){
    return {
      loading:false
    }
  },
  computed: {
    GetAllByCountry(){
      return this.$store.getters['pointsPackageModule/GetAllPointsPackages'];
    },
    size() {
      const size = {md: "large", xl: "x-large"}[
          this.$vuetify.breakpoint.name
          ];
      return size ? {[size]: true} : {};
    }
  },
  created() {
    this.initialize();
  },
  methods:{
    SubscribeTo(item){
      this.$emit('Selected' , item);
    },
    initialize(){
      this.loading = true;
      return this.$store.dispatch('pointsPackageModule/GetAllPointsPackagesByCountryId').then(res=>{
        this.loading = false;
      });
    }
  }
};
</script>