<template>
  <div>
    <v-container fluid>
      <div>
        <ejs-grid  ref="grid" :allowGrouping='true' :allowPdfExport="true" :groupSettings="groupOptions" :allowExcelExport="true" hierarchyPrintMode="All"  :toolbarClick="GetExport" :toolbar="toolbarSettings" :allowPaging="true" :pageSettings="pageSettings" :dataSource="GetDataSource.dataList" >
          <e-columns>
            <e-column field='memberId' headerText='رقم العضوية'  textAlign='Right' ></e-column>
            <e-column field='customerName' headerText='اسم العميل'  textAlign='Right'></e-column>
            <e-column field='registerAmount' type="number" headerText='عدد النقاط'  textAlign='Right'></e-column>
            <e-column field='mony'  headerText='مبلغ الاشتراك'   textAlign='Right'></e-column>
            <e-column field='packageName'  headerText='اسم الباقة'   textAlign='Right'></e-column>
            <e-column field='registerWay'  headerText='نوع الاشتراك'   textAlign='Right'></e-column>
            <e-column field='claimStatus'  headerText='حالة الاستلام'   textAlign='Right'></e-column>
            <e-column field='userId' headerText='كود استشاري المبيعات'  textAlign='Right'></e-column>
            <e-column field='salesConsultantName' headerText='اسم استشاري المبيعات'  textAlign='Right'></e-column>
          </e-columns>
          <e-aggregates>
            <e-aggregate>
              <e-columns>
                <e-column type="Sum" field="registerAmount"  :groupFooterTemplate="footerSum"></e-column>
                <e-column type="Sum" field="mony"    :groupFooterTemplate="footerSum"></e-column>
              </e-columns>
            </e-aggregate>
            <e-aggregate>
              <e-columns>
                <e-column field="registerAmount" type="Sum"  :footerTemplate='TotalFooterSum'></e-column>
                <e-column field="mony" type="Sum"  :footerTemplate='TotalFooterSum'></e-column>
                <e-column field="registerWay" type="Count"  :footerTemplate='TotalFooterCount'></e-column>
              </e-columns>
            </e-aggregate>
            <e-aggregate>
              <e-columns>
                <e-column field="mony" type="Sum"  :footerTemplate='SumOnline'></e-column>
                <e-column field="packageName" type="Sum"  :footerTemplate='SumOffline'></e-column>
                <!--              <e-column field="registerWay" type="Count"  :footerTemplate='CountOline'></e-column>-->
                <!--              <e-column field="claimStatus" type="Count"  :footerTemplate='CountOffline'></e-column>-->
              </e-columns>
            </e-aggregate>
            <e-aggregate>
              <e-columns>
                <e-column columnName="salesConsultantName" type="Custom"  :footerTemplate='DatesAggregates'></e-column>
              </e-columns>
            </e-aggregate>
          </e-aggregates>
        </ejs-grid>
      </div>

    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { L10n, setCulture } from '@syncfusion/ej2-base';
import {
  GridPlugin,
  GridComponent,
  Group,
  Page,
  Toolbar,
  PdfExport,
  Aggregate,
  ExcelExport
} from "@syncfusion/ej2-vue-grids";

setCulture('ar-EG');

Vue.use(GridPlugin);
export default {
  name: "UsageGrid",
  props:{
    dateFrom:'',
    dateTo:''

  }
  ,data(){
    return {
      groupOptions: { columns: ["userId"] , captionTemplate:"<span> العدد: ${count} </span>"   , showDropArea:false},
      pageSettings: { pageSizes: [12,50,100,200], pageCount: 4 },
      toolbarSettings:['Print' , "ExcelExport"] ,


    }
  },
  computed:{
    GetDataSource(){
      return this.$store.getters["reportsModule/GetDataSource"];
    }
  },
  methods:{
    DatesAggregates(){
      let dates =  'التاريخ من : '+this.dateFrom + " ----- التاريخ الي :"+ this.dateTo;
      return {template : Vue.component('datesTemplate', {
          template: `<span> {{data.dates}}</span>`,
          data () {return { data: {dates:dates}};}
        })
      }
    },
    footerSum () {
      return  { template : Vue.component('sumTemplate', {
          template: `<span>المجموع: {{data.Sum}}</span>`,
          data () {return { data: {}};}
        })
      }
    },
    TotalFooterSum () {
      return  { template : Vue.component('tsumTemplate', {
          template: `<span> المجموع الكلي: {{data.Sum}}</span>`,
          data () {return { data: {}};}
        })
      }
    },
    SumOnline () {
      let total = this.GetDataSource.totalOnlineBalance;
      return  { template : Vue.component('osumTemplate', {
          template: `<span> مجموع الاونلاين: {{data.total}}</span>`,
          data () {return { data: { total : total }};}
        })
      }
    },
    SumOffline () {
      let total = this.GetDataSource.totalOfflineBalance;
      return  { template : Vue.component('osumTemplate', {
          template: `<span> مجموع الاوفلاين: {{data.total}}</span>`,
          data () {return { data: { total : total }};}
        })
      }
    },
    TotalFooterCount () {
      return  { template : Vue.component('tcountTemplate', {
          template: `<span>العدد الكلي: {{data.Count}}</span>`,
          data () {return { data: {}};}
        })
      }
    },
    footerCount () {
      return  { template : Vue.component('countTemplate', {
          template: `<span>العدد : {{data.Count}}</span>`,
          data () {return { data: {}};}
        })
      }
    },
    GetExport(args){
      console.log(args);

      let grid = this.$refs.grid;
      if(grid){
        if(args.item.id.includes('pdfexport')){
          grid.pdfExport({
            fileName:"reportdata.pdf"
          });

        }
        if(args.item.id.includes('excelexport')){
          debugger;
          grid.excelExport({
            fileName:'usageReport-'+moment().format('lll')+'.xlsx'
          });
        }
      }

    }


  },
  provide: {
    grid: [Page ,Toolbar, PdfExport , ExcelExport , Group , Aggregate]
  }
}
</script>

<style scoped>
@import "https://cdn.syncfusion.com/ej2/material.css";
</style>