//export const APP_URL = "http://ahmed2580-001-site1.itempurl.com/";
//export const APP_URL = "http://192.168.1.5:5003/";
export const APP_URL = "https://msamy12000-001-site1.atempurl.com/";
// export const APP_URL = "https://localhost:44389/";
//iis
//export const APP_URL = "http://localhost:59597/";
export const GOOGLE_API_KEY = "AIzaSyBphrDzMQNuq1ygmtbuFyqWMs9GNCvqdMM";
export const GOOGLE_MAP_API_KEY = "AIzaSyAZFkYE41m45zP3Pu-I1Q7nFz-L--zV_U0";
export const IP_GEOLOCATION_API_KEY ="2287914d001134"
//export const GOOGLE_MAP_API_KEY = "AIzaSyBU6YNVxesC2-qRF2yDgCk7be8QaQz56kQ";
export const GOOGLE_MAP_API_Link = "https://maps.googleapis.com/maps/api/geocode/json?latlng=";
export const PAGING_NUMBER = 20;
export const PUBLIC_VABID_KEY = "BGJDptA3Rfc997RHNMgne4KjsHC7LUTdlUDu2hWfRS6T1Iks-HXggolLtiHr4cxJtkiDXAV4FhE3QKj7nqFNrzk";
export const FCM_SERVER_KEY = "AAAAlFFcL5s:APA91bE_K0nqt2QEwR-HHKcANNINq_HXK9ZWsGfFWMiGQ8n0zH_iPTHAXrnnurTrRv92WO9e3YdJ1T6XScB3IzfoT36TdWeegOeQ0LK0BBbTYAcAG1mHJZPPvEmSzO_EZ_1HVaJKtnHd";
