<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
         {{title}}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="Confirm(true)"
          >
            {{$t('Confirm')}}
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              @click="Confirm(false)"
          >
            {{$t('Cancel')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props:{
    activeStat:false,
    title: ""
  },
  data(){
    return {
      dialog : false
    }
  },created() {
    this.dialog = this.activeStat;
  },
  watch:{
    activeStat(val){
      this.dialog= val;
    }
  }
  ,methods:{
    Confirm(isConfirmed){
      this.dialog = isConfirmed;
      this.$emit('Confirmed' , isConfirmed);
    }
  }
}
</script>

<style scoped>

</style>