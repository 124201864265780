
import axios_instance from "@/Services/ApiService";
class ServicesPlacesService {
    controllerName  = "ServicesPlace"

    GetAll(obj){
        return axios_instance.post(this.controllerName + '/GetAllServicePlacesFullData' , obj).then(response =>{
            return Promise.resolve(response.data);
        });
    }
    GetUnApprovedServicePlacesCount(){
        return axios_instance.get(this.controllerName + '/GetUnApprovedServicePlacesCount').then(response =>{
            return Promise.resolve(response.data);
        });
    }
    GetAllUnApproved(obj){
        return axios_instance.post(this.controllerName + '/GetAllUnApproved' , obj).then(response =>{
            return Promise.resolve(response.data);
        });
    }
    GetServicePlace(id) {
        return axios_instance.get(this.controllerName + '/GetServicesPlaceDataById' , {params : {id : id}}).then(response =>{
            return Promise.resolve(response.data);
        });
    }
    GetServicePlaceFullData(id) {
        debugger;
        return axios_instance.get(this.controllerName + '/GetServicePlaceFullData' , {params : {id : id}}).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    SaveServicePlace(payload) {
      let form = new FormData();
        for ( let key in payload ) {
            let it =  payload[key];
            if(typeof it === typeof File) form.append('Image'  , it[0]);
            else form.append(key,it);
        }
       return axios_instance.post(this.controllerName + '/SaveServicePLace' , form , {
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        }).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    RegisterServicePlace(payload) {
        debugger;
        let form = new FormData();
        for ( let key in payload ) {
            if(key === 'Images' || key === 'Identity' || key==='Phones' || key === 'SignupModel' || key === 'CommerialImage') continue
            let it =  payload[key];
             form.append(key,it);
        }
        form.append('Identity' ,payload.Identity);
        form.append('CommerialImage' ,payload.CommerialImage);
        payload.Images.forEach((s,index)=>{
            form.append('Images' ,s );
        });
        payload.Phones.forEach((s,index)=>{
            form.append('PhoneNumbers',s);
        });
        if(payload.SignupModel !== undefined && payload.SignupModel !== null ){
            for ( let key in payload.SignupModel ) {
                let it =  payload.SignupModel[key];
                form.append('SignupModel['+key+']',it);
            }
        }
        if(payload.ServicePlaceLocation !== undefined && payload.ServicePlaceLocation !== null ){
            for ( let key in payload.ServicePlaceLocation ) {
                let it =  payload.ServicePlaceLocation[key];
                form.append('ServicePlaceLocation['+key+']',it);
            }
        }
        if(payload.OldImages !== undefined && payload.OldImages !== null ){
            payload.OldImages.forEach((s,index)=>{
                form.append('OldImages',s);
            });
        }
        console.log(form);
        delete axios_instance.defaults.headers.common['content-type'];
        return axios_instance.post(this.controllerName + '/RegisterServicePLace' , form , {
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        }).then(response =>{
            return Promise.resolve(response.data);
        });
     }

    GetServicePlaceServices(payload) {
        return axios_instance.get(this.controllerName + '/AllServicesPlaceServicesByServicePlaceId' , {params : {id : payload}}).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    ApproveServicePlace(payload) {
        return axios_instance.post(this.controllerName + '/ApproveServicePlace' ,null,
            {params:{ServicePlaceId : payload.id , status: payload.status?1:0}}).then(response =>{
            return Promise.resolve(response.data);
        });
    }
    GetBranchesByServicePlace(id){
        return axios_instance.get(this.controllerName + '/AdminGetAllServicesPlaceLocationsByServicePlaceId' , {params : {ServicePlaceId: id}}).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    SaveServicePlaceLocation(payload) {

        return axios_instance.post(this.controllerName + '/SaveServicesPlaceAddress' , payload ).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    DeleteBranch(id) {
        return axios_instance.delete(this.controllerName + '/DeleteBranch' , {params : {Id: id}}).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    GetBranchById(id) {
        return axios_instance.get(this.controllerName + '/GetServicesPlaceLocationDataById' , {params : {Id: id}}).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    GetBranchImagesByBranchId(id) {
        return axios_instance.get(this.controllerName + '/GetBranchImagesByBranchId' , {params : {BranchId: id}}).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    SaveBranchImage(payload) {
        let form = new FormData();
        form.append('TargetId' , payload.TargetId);
        form.append('Image' , payload.Image);
        form.append('isBranch' , payload.isBranch);
        delete axios_instance.defaults.headers.common['content-type'];
        return axios_instance.post(this.controllerName + '/SaveBranchImages' , form , {
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        }).then(response =>{
            return Promise.resolve(response.data);
        });
    }

    DeleteBranchImages(payload) {
        return axios_instance.delete(this.controllerName + '/DeleteBranchImages' , {params : payload}).then(response =>{
            return Promise.resolve(response.data);
        });
    }
}
export default new ServicesPlacesService();