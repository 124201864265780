import OffersService from "@/Services/OffersService";
export const offersModule = {
    namespaced : true,
    state:{
        offers : {
            data : [],
        }
    }
    , actions:{
        SaveOffer({commit} , payload){
            return OffersService.SaveOffer(payload).then(res=>{
                //commit('SetOffer' , res);
                return Promise.resolve(res);
            });
        },
        GetAllOffersByServicePlaceId({commit} , ServicePlaceId){
            return OffersService.GetAllServicePlaceOffers(ServicePlaceId).then(res=>{
                commit('SetOffers' ,res);
                console.log(res);
                return Promise.resolve()
            })
        },
        GetOfferById({commit} , id){
            return OffersService.GetOfferById(id).then(res=> {return Promise.resolve(res)});
        },
        DeleteOffer({commit} , id){
            return OffersService.DeleteOffer(id).then(res=>{
                commit('DeleteOffer' ,id);
               return Promise.resolve(res );
            });
        },
        OfferUserSearch({commit} , payload){
            return OffersService.OfferUserSearch(payload).then(res=>{
                //commit('SetOffer' , res);
                return Promise.resolve(res);
            });
        }

    }
    ,mutations: {
        SetOffer(state ,res){
            let index = state.offers.findIndex(s=>{return s.id===res.id});
            if(index > -1){
                state.offers.$set(index , res);
            }else{
                state.offers.push(res);
            }
        },
        SetOffers(state , res){
            state.offers = res;
        }
        ,DeleteOffer(state , id){
            state.offers.data.splice(state.offers.data.findIndex(s=>{return s.id === id}) , 1);
        },
    },getters:{
        GetAllOffers:(state) =>{
            return state.offers.data;
        }
    }

}