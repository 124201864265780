<template>
<v-container class="dashboard-container">
  {{country}}
  Hello
</v-container>
</template>

<script>
import {eg_govs} from "@/Helpers/Regions/governments";
import {eg_cities} from "@/Helpers/Regions/regions";
export default {
  name: "EgyptSetter",
  data(){
    return {
      code : 'eg',
      country : null
    }
  },
  created() {
    debugger;
    let countryDetails = {
      NameAr : "مصر",
      NameEn : "Egypt"
      ,code  : "eg"
      ,Childs :eg_govs.map(s=>{return {
          NameAr : s.nameAr,
          NameEn : s.nameEn,
          Childs  : eg_cities.filter(ss=> {return ss.governmentNumber === s.number}).map(ss=>{return {
            NameAr: ss.NameAr ,
            NameEn : ss.NameEn
          }})
      }})
    }
    this.country = countryDetails;
    this.$store.dispatch('regionsModule/SaveCountryDetails' , countryDetails).then(res=>{});
    console.log(countryDetails);
  }
}
</script>

<style scoped>

</style>