import ServicesPlacesService from "@/Services/ServicesPlacesService";

export const servicesPlacesModule = {
    namespaced : true,
    state:{
        servicesPlaces :{},
        ServicePlace:{},
        branches:[],
        ServicePlaceServices : []
        ,UnApprovedCount:0

    },actions:{
        GetAll({commit} , obj){
            return ServicesPlacesService.GetAll(obj).then(res =>{
                commit('SetServicesPlaces' , res);
                return Promise.resolve(res);
            });
        },
        GetServicePlaceBranches({commit} , id){
            return ServicesPlacesService.GetBranchesByServicePlace(id).then(res =>{
                commit('setBranches' , res);
                return Promise.resolve(res);
            });
        },
        GetUnApprovedServicePlacesCount({commit}){
            return ServicesPlacesService.GetUnApprovedServicePlacesCount().then(s=>{
                commit('SetUnApprovedCount' , s);
            });
        },
        GetServicePlace({commit} , id ){
            return ServicesPlacesService.GetServicePlace(id).then(res=>{
                commit('SetServicePlace' , res);
                return Promise.resolve(res);
            })
        },
        GetServicePlaceFullData({commit} , id){

            return ServicesPlacesService.GetServicePlaceFullData(id).then(res=>{
                commit("SetServicePlace" , res);
                return Promise.resolve(res);
            })
        },
        SaveServicePlace({commit} , payload){
            return ServicesPlacesService.SaveServicePlace(payload).then(res=>{
                return Promise.resolve(res);
            });
        },
        SaveServicePlaceLocation({commit} , payload){
            return ServicesPlacesService.SaveServicePlaceLocation(payload).then(res=>{
                commit('setBranch' , res);
                return Promise.resolve(res);
            });
        },
        DeleteBranch({commit} , id){
            return ServicesPlacesService.DeleteBranch(id).then(res=>{
                commit('deleteBranch' , id);
                return Promise.resolve(res)
            });
        },
        RegisterServicePlace({commit} , payload){
            return ServicesPlacesService.RegisterServicePlace(payload).then(res=>{
                if(res.isError === true)return Promise.reject(res.errorMessage);
                return Promise.resolve(res);
            });
        },
        GetServicePlaceServices({commit} , payload){
            return ServicesPlacesService.GetServicePlaceServices(payload).then(res=>{
                commit("SetServicesPlaceServices" , res);
                return Promise.resolve(res);
            });
        },
        ApproveServicePlace({commit} , payload){
            return ServicesPlacesService.ApproveServicePlace(payload).then(res=>{
                commit("ChangeServicePlaceStatus" , payload);
                return Promise.resolve(res);
            });
        },
        GetBranchById({commit} , id){
            return ServicesPlacesService.GetBranchById(id).then(res=>{
                return Promise.resolve(res)
            });
        },

        //GetBranchImagesByBranchId
        GetBranchImagesByBranchId({commit} , id){
            return ServicesPlacesService.GetBranchImagesByBranchId(id).then(res=>{
                return Promise.resolve(res)
            });
        },
        SaveBranchImage({commit} , payload){
            return ServicesPlacesService.SaveBranchImage(payload).then(res=>{
                return Promise.resolve(res);
            })
        },
        deleteBranchImage({commit} , payload){
            return ServicesPlacesService.DeleteBranchImages(payload).then(res=>{
                return Promise.resolve(res);
            })
        }
    }
    ,mutations: {
        setCurrentPage(state , page){
            state.servicesPlaces.CurrentPage = page;
        },
        SetServicesPlaces(state , servicesPlaces){
            state.servicesPlaces = servicesPlaces;
        },
        ChangeServicePlaceStatus(state , servicePlace){
            debugger;
            let res = state.servicesPlaces.data.filter(s=>{return s.id === servicePlace.id})[0];
            res.isApproved=false;
            state.UnApprovedCount--;
        }
        ,SetServicePlace(state , ser){
            state.ServicePlace = ser;
        },SetUnApprovedCount(state , count){
            state.UnApprovedCount = count;
        }
        ,SetServicesPlaceServices(state , services){
            state.ServicePlaceServices = services;
        },
        setBranches(state , res){
            state.branches = res;
        }
        ,setBranch( state ,  res ){

            // let obj =state.branches.findIndex(s=>s.id === res.id);
            // if(obj !== -1){
            //     state.branches.push(res);
            // }else{
            //     state.branches[obj] = res;
            // }
        }
        ,deleteBranch( state ,  id ){
            state.branches.splice(state.branches.findIndex(s=>s.id === id) , 1);
        }
    },getters:{
        GetAll : (state)=>{
            return state.servicesPlaces;
        },
        GetUnApprovedCount:(state)=>{
            return state.UnApprovedCount;
        },
        GetServicePlace : (state)=>{
            return state.ServicePlace;
        },
        GetServicePlaceServices : (state)=>{
            return state.ServicePlaceServices;
        },
        GetAllServicesPlaceBranches:(state)=> {
            return state.branches;
        }
    }
}